import * as React from 'react';
import { useMutation } from 'react-apollo';
import { ReadFuelType } from '../../../../__generated__/globalTypes';
import { PendingSpinner } from '../../../../components/pending-spinner';
import {
  triggerMeterModeReadMutation as Results,
  triggerMeterModeReadMutationVariables as Variables,
} from './__generated__/triggerMeterModeReadMutation';
import { useContext } from 'react';
import { ClientStateContext } from '../../../../client/client-state-context';
import { triggerMeterModeReadMutation } from './read-mode.form.gql';
import './read-mode.form.less';
import { PrimaryCTAButton } from '@ovotech/nebula';

interface Props {
  type: ReadFuelType;
  accountId: string;
  msn: string;
  mpxn: string;
  pendingRead: boolean;
}

export const ReadMeterModeButton: React.SFC<Props> = ({
  accountId,
  msn,
  mpxn,
  type,
  pendingRead,
}) => {
  const [readMode, { loading }] = useMutation<Results, Variables>(triggerMeterModeReadMutation, {
    refetchQueries: ['accountMetersQuery'],
    awaitRefetchQueries: true,
  });

  const operationInAction = pendingRead || loading;
  const { session } = useContext(ClientStateContext);

  return (
    <PrimaryCTAButton
      className="button"
      onClick={() =>
        readMode({
          variables: {
            input: {
              accountId,
              msn,
              mpxn,
              type,
              agentName: session?.user.email || '',
            },
          },
        })
      }
      disabled={operationInAction}
    >
      {operationInAction && <PendingSpinner contentText="Pending Request" disablePadding={true} />}
      Read Meter Mode
    </PrimaryCTAButton>
  );
};
