import * as React from 'react';
import { Icon, Message } from 'semantic-ui-react';

interface Props {
  loading: boolean;
}

export const LoadingComponent: React.SFC<Props> = ({ loading }) => {
  if (!loading) {
    return null;
  }
  return (
    <Message icon info data-test="loading">
      <Icon name="circle notched" loading />
      <Message.Content>
        <Message.Header>Please Wait</Message.Header>
        Loading...
      </Message.Content>
    </Message>
  );
};
