/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum BalanceType {
  ELECTRICITY = "ELECTRICITY",
  GAS = "GAS",
}

export enum CommChannel {
  Phone = "Phone",
  WebMessaging = "WebMessaging",
}

export enum ConsumerStatus {
  CURRENT = "CURRENT",
  DELETED = "DELETED",
  FINAL = "FINAL",
  FUTURE = "FUTURE",
  PENDING = "PENDING",
}

export enum Currency {
  GBP = "GBP",
}

export enum CurrentMeterMode {
  CREDIT = "CREDIT",
  PREPAYMENT = "PREPAYMENT",
  UNKNOWN = "UNKNOWN",
}

export enum CurrentMeterSupplyState {
  ARMED = "ARMED",
  DISABLED = "DISABLED",
  ENABLED = "ENABLED",
  UNKNOWN = "UNKNOWN",
}

export enum DebtType {
  ELECTRICITY = "ELECTRICITY",
  GAS = "GAS",
}

export enum EmergencyCreditBalanceType {
  ELECTRICITY = "ELECTRICITY",
  GAS = "GAS",
}

export enum EventFuelType {
  ELECTRICITY = "ELECTRICITY",
  GAS = "GAS",
}

export enum EventReadingUnit {
  KWH = "KWH",
  M3 = "M3",
}

export enum EventSources {
  S2 = "S2",
  UNKNOWN = "UNKNOWN",
  UTILIGROUP = "UTILIGROUP",
}

export enum EventType {
  BALANCE_ADJUSTMENT = "BALANCE_ADJUSTMENT",
  BALANCE_READ = "BALANCE_READ",
  CHANGE_MODE = "CHANGE_MODE",
  DEBT_ADJUSTMENT = "DEBT_ADJUSTMENT",
  DEBT_READ = "DEBT_READ",
  DEBT_REPAYMENT = "DEBT_REPAYMENT",
  EMAIL = "EMAIL",
  METER_READING = "METER_READING",
  METER_TOP_UP = "METER_TOP_UP",
  PAN_RECONCILIATION_STATUS = "PAN_RECONCILIATION_STATUS",
  PRINT = "PRINT",
  SMS = "SMS",
  STANDING_CHARGE = "STANDING_CHARGE",
  TOP_UP = "TOP_UP",
  UNKNOWN = "UNKNOWN",
  USAGE_CHARGE = "USAGE_CHARGE",
}

export enum InstallationType {
  L_AND_G = "L_AND_G",
  OTHER = "OTHER",
}

export enum MeterAdjustmentStatus {
  FAILURE = "FAILURE",
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
}

export enum MeterAdjustmentType {
  BALANCE_ADJUSTMENT = "BALANCE_ADJUSTMENT",
  BALANCE_READ = "BALANCE_READ",
  CHANGE_MODE_CREDIT = "CHANGE_MODE_CREDIT",
  CHANGE_MODE_PREPAYMENT = "CHANGE_MODE_PREPAYMENT",
  CREATE_PAN = "CREATE_PAN",
  DEBT_ADJUSTMENT = "DEBT_ADJUSTMENT",
  DEBT_READ = "DEBT_READ",
  DELETE_PAN = "DELETE_PAN",
  DISABLE_PIN = "DISABLE_PIN",
  METER_TOP_UP = "METER_TOP_UP",
  READ_MODE = "READ_MODE",
  READ_PREPAYMENT_DATA = "READ_PREPAYMENT_DATA",
  READ_SUPPLY_STATE = "READ_SUPPLY_STATE",
  UNKNOWN = "UNKNOWN",
}

export enum MeterFuelType {
  ELECTRICITY = "ELECTRICITY",
  GAS = "GAS",
}

export enum MeterModeType {
  Credit = "Credit",
  Prepayment = "Prepayment",
}

export enum MeterTariffType {
  PC1 = "PC1",
  PC2 = "PC2",
}

export enum MeterType {
  S1 = "S1",
  S1E = "S1E",
  S2 = "S2",
  TRAD = "TRAD",
}

export enum PANMeterVersion {
  S1 = "S1",
  S1E = "S1E",
  S2 = "S2",
}

export enum PANType {
  PAYGPLUS_REUSED_FOR_PAYG_S1_ELEC = "PAYGPLUS_REUSED_FOR_PAYG_S1_ELEC",
  S1_ELECTRICITY = "S1_ELECTRICITY",
  S1_GAS = "S1_GAS",
  S1_VIRTUAL_ELECTRICITY = "S1_VIRTUAL_ELECTRICITY",
  S1_VIRTUAL_GAS = "S1_VIRTUAL_GAS",
  S2_ELECTRICITY = "S2_ELECTRICITY",
  S2_GAS = "S2_GAS",
  S2_VIRTUAL_ELECTRICITY = "S2_VIRTUAL_ELECTRICITY",
  S2_VIRTUAL_GAS = "S2_VIRTUAL_GAS",
  UNKNOWN = "UNKNOWN",
}

export enum PanStatus {
  Match = "Match",
  Mismatch = "Mismatch",
}

export enum ReadFuelType {
  ELECTRICITY = "ELECTRICITY",
  GAS = "GAS",
}

export enum ReconciliationStatus {
  Matched = "Matched",
  MatchedWithExtras = "MatchedWithExtras",
  Mismatched = "Mismatched",
}

export enum ReconciliationType {
  Electricity = "Electricity",
  Gas = "Gas",
}

export enum Scope {
  ACCOUNT = "ACCOUNT",
  BATCH_OPS = "BATCH_OPS",
  OVO_PAN_OPS = "OVO_PAN_OPS",
  PROCESSES = "PROCESSES",
}

export enum SmetsPANMeterVersion {
  Smets1 = "Smets1",
  Smets2 = "Smets2",
}

export interface AccountEventsInput {
  accountId: string;
  fromDate: string;
  toDate: string;
}

export interface AddDiscretionaryCreditInput {
  accountId: string;
  amount: MoneyInput;
  type: BalanceType;
  msn?: string | null;
  mpxn?: string | null;
  repaymentRate: MoneyInput;
  agentName?: string | null;
  usualRepaymentRate?: MoneyInput | null;
  externalReference?: string | null;
}

export interface AddDiscretionaryCreditV2Input {
  meterRequests: RequestMeterInfo[];
  discretionaryCreditInfo: DiscretionaryCreditInfo;
  agentName: string;
  externalReference?: string | null;
}

export interface Address {
  lines?: AddressLines | null;
  city: string;
  postCode: string;
}

export interface AddressLines {
  line1: string;
  line2?: string | null;
  line3?: string | null;
  line4?: string | null;
}

export interface AdjustBalanceInput {
  accountId: string;
  msn?: string | null;
  mpxn?: string | null;
  topUp?: boolean | null;
  type: BalanceType;
  transactionId: string;
  amount: MoneyInput;
  cause: string;
  agentName?: string | null;
  externalReference?: string | null;
}

export interface AdjustDebtInput {
  accountId?: string | null;
  msn?: string | null;
  mpxn?: string | null;
  type: DebtType;
  transactionId: string;
  amount: MoneyInput;
  debtRepaymentRate: MoneyInput;
  cause: string;
  agentName?: string | null;
  usualDebtRepaymentRate?: MoneyInput | null;
}

export interface AdjustMeterModeInput {
  accountId: string;
  msn: string;
  mpxn: string;
  mode: MeterModeType;
  cause?: string | null;
  agentName?: string | null;
}

export interface BatchAdjustmentDetailsInput {
  batchId: number;
}

export interface BatchAdjustmentInput {
  csvName: string;
  csvBody: string;
  batchType: string;
  context: string;
}

export interface BatchAdjustmentSummariesInput {
  page: number;
  perPage: number;
}

export interface DeliveryOptions {
  sendByEmail: boolean;
  sendByPost: boolean;
}

export interface DiscretionaryCreditInfo {
  disclaimer: string;
  daysRequired: number;
  accountNo: string;
  mobileNumber?: string | null;
  clientId: string;
  channel: CommChannel;
  meterRecommendations?: MeterRecommendationsInfo | null;
}

export interface EligibleForDiscretionaryCreditInput {
  accountId: string;
  type: BalanceType;
}

export interface MeterRecommendationsInfo {
  GAS?: RecommendationsInfo | null;
  ELECTRICITY?: RecommendationsInfo | null;
}

export interface MoneyInput {
  value: number;
  currency: Currency;
}

export interface Name {
  full?: string | null;
  firstName?: string | null;
}

export interface RecommendationsInfo {
  total: MoneyInput;
  dailyChargeCover: MoneyInput;
  repaymentRate: MoneyInput;
  repaymentRateTotal: MoneyInput;
  bringBalanceAboveZero: MoneyInput;
  totalDaysCovered: number;
}

export interface RecommendedDiscretionaryCreditInput {
  accountId: string;
  type: BalanceType;
  toDate: string;
  currentRepaymentRate: MoneyInput;
  mpxn?: string | null;
}

export interface RequestBoostIssuePansInput {
  name: Name;
  address: Address;
  supplyPointDetails: SupplyPointDetails;
  email?: string | null;
  deliveryOptions: DeliveryOptions;
}

export interface RequestMeterInfo {
  fuel: BalanceType;
  amount: MoneyInput;
  repaymentRate: MoneyInput;
  usualRepaymentRate?: MoneyInput | null;
  mpxn: string;
  msn: string;
}

export interface RequestNewPanPaymOvoMigrationInput {
  name: Name;
  address: Address;
  supplyPointDetails: SupplyPointDetailsMigration;
  email?: string | null;
  deliveryOptions: DeliveryOptions;
}

export interface RequestPANCardsReissueInput {
  accountId: string;
  deliveryOptions: DeliveryOptions;
  pans: string[];
}

export interface SupplyPointDetails {
  electricity?: SupplyPointDetailsElectricity | null;
  gas?: SupplyPointDetailsGas | null;
}

export interface SupplyPointDetailsElectricity {
  mpan: string;
  meterType: SmetsPANMeterVersion;
  pan?: string | null;
}

export interface SupplyPointDetailsGas {
  mprn: string;
  meterType: SmetsPANMeterVersion;
  pan?: string | null;
}

export interface SupplyPointDetailsMigration {
  mpan?: string | null;
  mprn?: string | null;
}

export interface TriggerDisablePinInput {
  accountId: string;
  msn: string;
  mpxn: string;
  fuelType: ReadFuelType;
  agentName?: string | null;
}

export interface TriggerMeterModeReadInput {
  accountId: string;
  msn: string;
  mpxn: string;
  type: ReadFuelType;
  agentName?: string | null;
}

export interface TriggerReadPrepaymentDataInput {
  accountId: string;
  msn: string;
  mpxn: string;
  type: ReadFuelType;
  agentName?: string | null;
}

export interface TriggerSupplyStateReadInput {
  accountId: string;
  msn: string;
  mpxn: string;
  type: ReadFuelType;
  agentName?: string | null;
}

export interface UpdateMeterPANInput {
  newPan: string;
  currentPan: string;
  msn: string;
  mpxn: string;
  type: ReadFuelType;
  agentName?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
