import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/browser';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { App } from './App';

if (process.env.DATADOG_APP_ID && process.env.DATADOG_CLIENT_TOKEN) {
  datadogRum.init({
    applicationId: process.env.DATADOG_APP_ID,
    clientToken: process.env.DATADOG_CLIENT_TOKEN,
    datacenter: 'us',
    sampleRate: 100,
  });
}

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.SENTRY_ENVIRONMENT,
  release: process.env.SENTRY_RELEASE,
});
ReactDOM.render(<App />, document.getElementById('root'));
