import * as Sentry from '@sentry/browser';
import * as React from 'react';
import { ClientStateContext, State } from './client-state-context';

const sessionKey = 'portal.session';

const loadSession = () => {
  const result = window.localStorage.getItem(sessionKey);
  return result ? JSON.parse(result) : null;
};

const setSessionToSentry = (session: State['session']) => {
  if (session) {
    const { user } = session;
    Sentry.configureScope((scope) =>
      scope
        .setUser({
          email: user.email,
          username: user.name || undefined,
        })
        .setTags({
          userScopes: user.scopes ? user.scopes.join(', ') : '',
        }),
    );
  }
};

const storeSession = (session: State['session']) => {
  window.localStorage.setItem(sessionKey, JSON.stringify(session));
};

export class ClientState extends React.PureComponent<Record<string, unknown>, State> {
  constructor(props: Record<string, unknown>) {
    super(props);
    const loadedSession = loadSession();
    setSessionToSentry(loadedSession);

    this.state = {
      errors: [],
      session: loadedSession,
      createSession: (session) => {
        storeSession(session);
        setSessionToSentry(session);
        this.setState({ session });
      },
      clear: () => {
        const session = null;
        storeSession(session);
        this.setState({ session, errors: [] });
      },
      setErrors: (errors) => this.setState({ errors }),
      dismissErrorCode: (code) =>
        this.setState({ errors: this.state.errors.filter((error) => error.code !== code) }),
    };
  }

  render() {
    return (
      <ClientStateContext.Provider value={this.state}>
        {this.props.children}
      </ClientStateContext.Provider>
    );
  }
}
