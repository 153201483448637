import {
  MAX_LENGTH_GAS,
  MIN_LENGTH_GAS,
  METER_FUEL_TYPES,
  MAX_LENGTH_ELECTRICITY,
  MIN_LENGTH_ELECTRICITY,
} from '../../helpers';
import { DeliveryOptions, SupplyPointDetails } from '../../__generated__/globalTypes';
import { get } from '@ovotech/typesafe-get';
// import { ValidationErrors } from 'final-form';
import * as validate from 'validate.js';

export interface Mpxn {
  name: string;
  meterType: METER_FUEL_TYPES;
  minLength?: number;
  maxLength?: number;
}

export const constraints = {
  'supplyPointDetails.electricity.mpan': {
    numericality: {
      onlyInteger: true,
      strict: true,
    },
    length: {
      maximum: MAX_LENGTH_ELECTRICITY,
      minimum: MIN_LENGTH_ELECTRICITY,
    },
  },
  'supplyPointDetails.electricity.meterType': {},
  'supplyPointDetails.gas.mprn': {
    numericality: {
      onlyInteger: true,
      strict: true,
      message: 'must be a valid number and must not begin with 0',
    },
    length: {
      maximum: MAX_LENGTH_GAS,
      minimum: MIN_LENGTH_GAS,
    },
  },
  'supplyPointDetails.gas.meterType': {},
  accountId: {},
  supplyStartDate: {
    presence: { message: 'Move in date should not be blank' },
  },
  supplyPointDetails: {
    atLeastOneMeterRequired: true,
    presence: true,
  },
  deliveryOptions: {
    validateSendingInformation: true,
    presence: true,
  },
};

export const oppositeType = (meterType: METER_FUEL_TYPES): METER_FUEL_TYPES => {
  switch (meterType) {
    case METER_FUEL_TYPES.ELECTRICITY:
      return METER_FUEL_TYPES.GAS;
    case METER_FUEL_TYPES.GAS:
      return METER_FUEL_TYPES.ELECTRICITY;
    default:
      return METER_FUEL_TYPES.UNKNOWN;
  }
};

export const calculateParameters = (meterType: METER_FUEL_TYPES): Mpxn => {
  switch (meterType) {
    case METER_FUEL_TYPES.ELECTRICITY:
      return {
        maxLength: MAX_LENGTH_ELECTRICITY,
        minLength: MIN_LENGTH_ELECTRICITY,
        name: 'mpan',
        meterType,
      };
    case METER_FUEL_TYPES.GAS:
      return {
        maxLength: MAX_LENGTH_GAS,
        minLength: MIN_LENGTH_GAS,
        name: 'mprn',
        meterType,
      };
    default:
      return { name: 'mpxn', meterType };
  }
};

export const someMeters = ({
  meter1Number,
  meter2Number,
  meter1Type,
  meter2Type,
}: {
  meter1Number?: string;
  meter2Number?: string;
  meter1Type: METER_FUEL_TYPES;
  meter2Type: METER_FUEL_TYPES;
}): string | undefined => {
  const meters = [meter1Number && meter1Type, meter2Number && meter2Type]
    .filter((i) => !!i && i !== METER_FUEL_TYPES.UNKNOWN)
    .sort();

  if (meters.length === 0) {
    return;
  }

  const phrase = meters.join(' and ');
  const prefix = meters.length > 1 ? 'both' : 'one';
  const suffix = meters.length > 1 ? 'meters' : 'meter';

  return [prefix, phrase, suffix].join(' ');
};

export const preventNonNumericalKeys = (event: KeyboardEvent): void => {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
};

export const atLeastOneMeterRequired = (
  supplyPointDetails: SupplyPointDetails,
): string | undefined => {
  const gasMeter = get(supplyPointDetails, 'gas');
  const electricityMeter = get(supplyPointDetails, 'electricity');

  if (gasMeter || electricityMeter) {
    return;
  }
  return 'requires at least one meter';
};
validate.validators.atLeastOneMeterRequired = atLeastOneMeterRequired;

export const validateSendingInformation = (
  value: any,
  options: any,
  key: any,
  attributes: { deliveryOptions: any; accountId: string },
): string | undefined => {
  const { sendByEmail, sendByPost } = attributes.deliveryOptions as DeliveryOptions;
  const { accountId } = attributes;
  if (sendByPost) {
    return;
  } else if (sendByEmail && accountId) {
    return;
  } else if (sendByEmail && !accountId) {
    return 'Sending via email requires an account ID';
  }
  return 'requires at least one sending method';
};
validate.validators.validateSendingInformation = validateSendingInformation;

// export const validateForm = (values: Variables): ValidationErrors | undefined => {
//   const errors: ValidationErrors | undefined = validate(values, constraints);
//   return errors;
// };
