import {
  getIssueIdentificationNumber,
  getPanType,
  PAN_LENGTH,
  panHasValidChecksum,
  PANType,
} from '@ovotech/pan-utils';
import * as React from 'react';
import { Mutation } from 'react-apollo';
import * as Final from 'react-final-form';
import { Form, Message, Modal, Popup } from 'semantic-ui-react';
import * as validate from 'validate.js';
import {
  CurrentMeterMode,
  MeterFuelType,
  ReadFuelType,
} from '../../../../__generated__/globalTypes';
import { FieldComponent } from '../../../../components';
import { ElectricityIcon } from '../../../../components/electricity-icon';
import { GasIcon } from '../../../../components/gas-icon';
import { accountMetersQuery_account_meters as Meter } from '../../account-meters/__generated__/accountMetersQuery';
import {
  updateMeterPANMutation as Results,
  updateMeterPANMutationVariables as Variables,
} from './__generated__/updateMeterPANMutation';
import { useContext } from 'react';
import { ClientStateContext } from '../../../../client/client-state-context';
import { updateMeterPANMutation } from './update-meter-pan.form.gql';
import './update-meter-pan.form.less';
import { PrimaryCTAButton, SecondaryCTAButton } from '@ovotech/nebula';

type MeterSubset = Pick<
  Meter,
  'capabilities' | 'currentMode' | 'fuelType' | 'msn' | 'pan' | 'mpxn'
>;

interface Props {
  meter: MeterSubset;
  onSuccess: () => void;
  onClose: () => void;
}

interface UpdateMeterPANForm {
  pan: string;
}

const formatPANType = (panType: PANType) => {
  switch (panType) {
    case PANType.PAYG_STANDARD_S1_ELEC:
      return 'SMETS1 Electricity';
    case PANType.PAYG_STANDARD_S1_GAS:
      return 'SMETS1 Gas';
    case PANType.PAYG_STANDARD_S2_ELEC:
      return 'SMETS2 Electricity';
    case PANType.PAYG_STANDARD_S2_GAS:
      return 'SMETS2 Gas';
    case PANType.PAYGPLUS_REUSED_FOR_PAYG_STANDARD_S1_ELEC:
      return 'PAYG+';
    case PANType.PAYG_STANDARD_S1_VIRTUAL_ELEC:
      return 'SMETS1 Virtual Electricity';
    case PANType.PAYG_STANDARD_S2_VIRTUAL_ELEC:
      return 'SMETS2 Virtual Electricity';
    case PANType.PAYG_STANDARD_S1_VIRTUAL_GAS:
      return 'SMETS1 Virtual Gas';
    case PANType.PAYG_STANDARD_S2_VIRTUAL_GAS:
      return 'SMETS2 Virtual Gas';
    default:
      return 'Unknown';
  }
};

const formatPANTypes = (panTypes: PANType[]): string =>
  panTypes
    .filter((panType) => panType !== PANType.UNKNOWN)
    .map((panType) => `${formatPANType(panType)} (${getIssueIdentificationNumber(panType)})`)
    .join(', ');

export const getValidPanTypes = (meter: MeterSubset): PANType[] => {
  const validPANTypes = [];

  if (meter.capabilities.isSmets2 && meter.currentMode.value === CurrentMeterMode.PREPAYMENT) {
    if (meter.fuelType === MeterFuelType.ELECTRICITY) {
      validPANTypes.push(PANType.PAYG_STANDARD_S2_ELEC, PANType.PAYG_STANDARD_S2_VIRTUAL_ELEC);
    } else if (meter.fuelType === MeterFuelType.GAS) {
      validPANTypes.push(PANType.PAYG_STANDARD_S2_GAS, PANType.PAYG_STANDARD_S2_VIRTUAL_GAS);
    }
  }

  if (
    !meter.capabilities.isSmets2 &&
    meter.capabilities.isDcc &&
    meter.currentMode.value === CurrentMeterMode.PREPAYMENT
  ) {
    if (meter.fuelType === MeterFuelType.ELECTRICITY) {
      validPANTypes.push(
        PANType.PAYG_STANDARD_S2_ELEC,
        PANType.PAYG_STANDARD_S2_VIRTUAL_ELEC,
        PANType.PAYG_STANDARD_S1_ELEC,
        PANType.PAYG_STANDARD_S1_VIRTUAL_ELEC,
        PANType.PAYGPLUS_REUSED_FOR_PAYG_STANDARD_S1_ELEC,
      );
    } else if (meter.fuelType === MeterFuelType.GAS) {
      validPANTypes.push(
        PANType.PAYG_STANDARD_S2_GAS,
        PANType.PAYG_STANDARD_S2_VIRTUAL_GAS,
        PANType.PAYG_STANDARD_S1_GAS,
        PANType.PAYG_STANDARD_S1_VIRTUAL_GAS,
      );
    }
  }

  return validPANTypes;
};

interface CorrectIINOptions {
  permittedPANTypes: PANType[];
}

validate.validators.correctIIN = (
  pan: string | undefined,
  options: CorrectIINOptions,
): string | undefined => {
  if (!pan) {
    return;
  }

  const panType = getPanType(pan);
  const isValid = options.permittedPANTypes.includes(panType);
  if (!isValid) {
    return `is a ${formatPANType(panType)} PAN, but expected one of the types listed above`;
  }
  return;
};

validate.validators.panChecksum = (pan: string | undefined): string | undefined => {
  // only show the checksum error if the length is correct
  if (!pan || pan.length !== PAN_LENGTH) {
    return;
  }
  const { isValid } = panHasValidChecksum(pan);
  if (!isValid) {
    return "has an incorrect checksum - this indicates there's a typo";
  }
  return;
};

const panConstraints = {
  presence: true,
  format: {
    pattern: '\\d+',
    message: 'must only contain numbers',
  },
  length: {
    is: PAN_LENGTH,
    message: `must be ${PAN_LENGTH} digits long`,
  },
  panChecksum: true,
};

export const UpdateMeterPANForm: React.SFC<Props> = ({ meter, onSuccess, onClose }) => {
  const { session } = useContext(ClientStateContext);

  return (
    <Mutation<Results, Variables> mutation={updateMeterPANMutation}>
      {(updateMeterPAN, { data, error, loading }) => (
        <Final.Form
          onSubmit={({ pan }) =>
            updateMeterPAN({
              variables: {
                input: {
                  newPan: pan.trim(),
                  currentPan: meter.pan,
                  msn: meter.msn,
                  mpxn: meter.mpxn,
                  type: meter.fuelType === 'GAS' ? ReadFuelType.GAS : ReadFuelType.ELECTRICITY,
                  agentName: session?.user.email || '',
                },
              },
            }).then(() => onSuccess())
          }
          initialValues={{
            pan:
              getValidPanTypes(meter).length > 0
                ? getIssueIdentificationNumber(getValidPanTypes(meter)[0])
                : getIssueIdentificationNumber(PANType.UNKNOWN),
          }}
          validate={(values: UpdateMeterPANForm) =>
            validate(
              { pan: values.pan ? values.pan.trim() : values.pan },
              {
                pan: {
                  ...panConstraints,
                  correctIIN: { permittedPANTypes: getValidPanTypes(meter) },
                },
              },
            )
          }
        >
          {({ handleSubmit, invalid }) => (
            <>
              <Modal.Header className="update-pan-header">
                {meter.fuelType === 'GAS' ? <GasIcon /> : <ElectricityIcon />}
                Update{' '}
                {meter.capabilities.isSmets2
                  ? 'SMETS2'
                  : meter.capabilities.isDcc
                  ? 'Enrolled SMETS1'
                  : 'Not DCC'}{' '}
                PAN: {meter.msn}
              </Modal.Header>
              <Modal.Content>
                {meter.capabilities.isDcc &&
                  meter.currentMode.value === CurrentMeterMode.PREPAYMENT && (
                    <p data-test="update-pan-iin-info">
                      Expected{' '}
                      {
                        <Popup
                          trigger={<span>IIN</span>}
                          content={
                            <div>
                              <strong>Issue Identification Number</strong>
                              <p>The first few digits of the PAN</p>
                            </div>
                          }
                          position="top center"
                        />
                      }
                      : {formatPANTypes(getValidPanTypes(meter))}
                    </p>
                  )}
                {(!meter.capabilities.isDcc ||
                  meter.currentMode.value !== CurrentMeterMode.PREPAYMENT) && (
                  <p data-test="update-pan-bad-meter-info">
                    Meter must be DCC (SMETS2 or Enrolled SMETS1) in PAYG Standard mode to update
                    the PAN
                  </p>
                )}

                <Form
                  id="update-pan-form"
                  onSubmit={handleSubmit}
                  loading={loading}
                  success={!!data}
                  error={!!error}
                  data-test="update-pan-form"
                >
                  <Message error header="Error Updating PAN" content={error?.message} />
                  <FieldComponent name="pan">
                    <label>New PAN</label>
                    <div className="ui left input">
                      <Final.Field
                        name="pan"
                        component="input"
                        data-test="update-pan"
                        autoComplete="off"
                      />
                    </div>
                  </FieldComponent>
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <SecondaryCTAButton
                  className="button"
                  data-test="update-pan-close"
                  onClick={() => onClose()}
                >
                  Close
                </SecondaryCTAButton>
                <PrimaryCTAButton
                  className="button"
                  type="submit"
                  form="update-pan-form"
                  data-test="update-pan-submit"
                  disabled={invalid || !!data}
                >
                  Update PAN
                </PrimaryCTAButton>
              </Modal.Actions>
            </>
          )}
        </Final.Form>
      )}
    </Mutation>
  );
};
