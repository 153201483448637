import * as React from 'react';

export const ElectricityIcon = ({ width = 24, height = 24 }: React.SVGProps<SVGElement>) => (
  <svg
    width={width}
    height={height}
    style={{
      color: '#ffd404',
      stroke: 'black',
    }}
    viewBox="0 0 24 24"
    fill="currentcolor"
  >
    <defs>
      <path
        id="electricity_svg__a"
        d="M12.457 9.811c-1.154-.481-2.03-.859-3.185-1.195.46-1.354.693-1.495 1.028-2.841.305-1.223.88-3.333.974-4.444C11.368.219 11.498 0 11.038 0c-.592 0-5.213 3.32-6.608 4.51C3.058 5.68.683 8.318.29 8.952c-.472.766-.334 1.16.157 1.709.49.549 2.27 1.815 4.176 2.666-.681 2.216-.929 2.605-1.327 4.704-.072.381-.453 3.555-.095 3.861.015.013.212.098.227.108 1.836-.604 5.58-3.77 7.484-5.525 1.904-1.756 3.136-3.467 3.418-3.914.282-.447.826-.975.626-1.351-.201-.376-1.346-.917-2.5-1.399z"
      />
    </defs>
    <use fillRule="evenodd" transform="translate(.5)" xlinkHref="#electricity_svg__a" />
  </svg>
);
