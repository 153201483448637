import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { ApolloClient, ApolloClientOptions } from 'apollo-client';
import { from, ApolloLink } from 'apollo-link';
import * as React from 'react';
import { ApolloProvider } from 'react-apollo';
import { AuthLink } from './auth.link';
import { ClientStateContext, defaultState } from './client-state-context';
import { ErrorsLink } from './errors.link';
import introspectionQueryResultData from './fragmentTypes.json';
import { httpLink } from './http.link';

const fragmentMatcher = new IntrospectionFragmentMatcher({ introspectionQueryResultData });
const cache = new InMemoryCache({ fragmentMatcher });

const authLink = new AuthLink(defaultState);
const errorsLink = new ErrorsLink(defaultState);
const link = from([authLink, errorsLink, httpLink as ApolloLink]);

const client = new ApolloClient({
  cache,
  connectToDevTools: process.env.NODE_ENV === 'development',
  link: link as ApolloClientOptions<InMemoryCache>['link'],
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    mutate: {
      fetchPolicy: 'no-cache',
    },
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});

export const ApolloClientWithState: React.SFC = ({ children }) => (
  <ClientStateContext.Consumer>
    {(context) => {
      authLink.setClientState(context);
      errorsLink.setClientState(context);

      return <ApolloProvider client={client}>{children}</ApolloProvider>;
    }}
  </ClientStateContext.Consumer>
);
