import { get } from '@ovotech/typesafe-get';
import { ApolloLink } from 'apollo-link';
import { State } from './client-state-context';

export class AuthLink extends ApolloLink {
  constructor(public clientState: State) {
    super((operation, forward) => {
      const jwt = get(this.clientState, 'session', 'jwt');
      operation.setContext({ headers: { authorization: jwt ? `Bearer ${jwt}` : '' } });

      return forward(operation).map((response) => {
        if (response.data && response.data.createSession && response.data.createSession.jwt) {
          this.clientState.createSession(response.data.createSession);
        }

        return response;
      });
    });
  }

  setClientState(clientState: State): void {
    this.clientState = clientState;
  }
}
