import * as React from 'react';
import * as Final from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { FieldComponent } from '..';
import './dropdown-field.less';

interface OptionValue<T> {
  key: string;
  value: T;
  text: string;
}

interface Props<T> {
  headerText: string;
  fieldName: string;
  options: Array<OptionValue<T>>;
  required?: boolean;
  dataTest?: string;
  onSelect?: (value: T) => void;
  disabled?: boolean;
}

export const DropdownField: React.FC<Props<string>> = ({
  headerText,
  fieldName,
  options,
  onSelect,
  required,
  children,
  dataTest = 'dropdown-field',
  disabled,
}) => (
  <FieldComponent name={fieldName} required={required}>
    <label>{headerText}</label>
    <Final.Field
      required={required}
      name={fieldName}
      className="ui selection dropdown"
      data-test={dataTest}
      component="select"
      disabled={disabled}
    >
      <option value={''} />
      {options.map((option) => (
        <option key={option.key} value={option.value}>
          {option.text}
        </option>
      ))}
    </Final.Field>
    <OnChange name={fieldName}>{(value) => onSelect && onSelect(value)}</OnChange>
    {children}
  </FieldComponent>
);

export const MeterTypeField: React.FC<Props<string>> = ({
  headerText,
  fieldName,
  required,
  dataTest = 'meter-type-Smets2',
  disabled,
}) => (
  <FieldComponent name={fieldName} required={required}>
    <label>{headerText}</label>
    <Final.Field
      required={required}
      name={fieldName}
      className="ui selection dropdown"
      data-test={dataTest}
      component="input"
      disabled={disabled}
      defaultValue="SMETS2"
    ></Final.Field>
  </FieldComponent>
);
