import * as React from 'react';
import { paypointAccountQuery_paypointAccountDetails as Details } from './__generated__/paypointAccountQuery';
import { accountDetailsQuery } from '../account-details/account-details.view.gql';
import {
  accountDetailsQueryVariables as AccountVariables,
  accountDetailsQuery as AccountResults,
} from '../account-details/__generated__/accountDetailsQuery';
import { RouteComponentProps } from 'react-router';
import { FieldComponent, LoadingComponent } from '../../../components';
import * as Final from 'react-final-form';
import './paypoint.less';
import { Message, Table } from 'semantic-ui-react';
import { Query } from 'react-apollo';
import { handleAwait, isEmailValid } from '../../../helpers';
import { fetchPaypointAccountDetails } from './helpers';
import { get } from '@ovotech/typesafe-get';
import { ClientStateContext } from '../../../client/client-state-context';
import { ElectricityIcon } from '../../../components/electricity-icon';
import { GasIcon } from '../../../components/gas-icon';
import { format } from 'date-fns';
import { dateFormat, formatMoney } from '../../../helpers';
import { Currency } from '../../../../src/__generated__/globalTypes';
import { PrimaryCTAButton } from '@ovotech/nebula';

type Props = RouteComponentProps<{ accountNumber: string }>;

export const PaypointDetailsTable = (paypointAccountDetails: Details) => (
  <Table size="large" definition striped>
    <Table.Body>
      <Table.Row>
        <Table.Cell width={5}>
          {paypointAccountDetails.fuel === 'GAS' ? <GasIcon /> : <ElectricityIcon />}
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell width={4} content="Pan" data-test="paypoint-table-pan" />
        <Table.Cell content={paypointAccountDetails.pan} />
      </Table.Row>
      <Table.Row>
        <Table.Cell width={4} content="Balance" data-test="paypoint-table-balance" />
        <Table.Cell
          content={formatMoney(paypointAccountDetails.balance)}
          data-test="paypoint-table-balance-value"
        />
      </Table.Row>
      <Table.Row>
        <Table.Cell width={4} content="Debt" data-test="paypoint-table-debt" />
        <Table.Cell
          content={
            paypointAccountDetails.debt_balance
              ? formatMoney(paypointAccountDetails.debt_balance)
              : formatMoney({ value: 0, currency: Currency.GBP })
          }
          data-test="paypoint-table-debt-value"
        />
      </Table.Row>
      <Table.Row>
        <Table.Cell width={4} content="Last Updated" data-test="paypoint-table-last-updated" />
        <Table.Cell content={format(paypointAccountDetails.last_update, dateFormat)} />
      </Table.Row>
      <Table.Row>
        <Table.Cell width={4} content="Fuel" data-test="paypoint-table-fuel" />
        <Table.Cell content={paypointAccountDetails.fuel} />
      </Table.Row>
    </Table.Body>
  </Table>
);

export const PayPointView: React.FunctionComponent<Props> = ({ match }) => (
  <Query<AccountResults, AccountVariables>
    query={accountDetailsQuery}
    variables={{ accountId: match.params.accountNumber }}
  >
    {({ data, loading }) => {
      const customerEmail = get(data, 'account', 'customerDetails', 'email');
      const [email, setEmail] = React.useState('');
      const { session } = React.useContext(ClientStateContext);
      const [isSearching, setIsSearching] = React.useState(false);
      const [emailWarning, setEmailWarning] = React.useState(false);
      const [payPointDetails, setPaypointDetails] = React.useState<Details[] | undefined>(
        undefined,
      );

      const handleSearch = async (email: string) => {
        if (!isEmailValid(email)) {
          setEmailWarning(true);
          return;
        }
        if (session && session.jwt) {
          setEmailWarning(false);
          setIsSearching(true);
          const [paypointData, accErr] = await handleAwait(
            fetchPaypointAccountDetails(email, session.jwt),
          );
          const paypointAccountDetails = !accErr
            ? (get(paypointData.data.data, 'paypointAccountDetails') as Details[])
            : undefined;

          setPaypointDetails(paypointAccountDetails);
          setIsSearching(false);
        }
      };

      React.useEffect(() => {
        if (!customerEmail) return;
        isEmailValid(customerEmail) ? setEmail(customerEmail) : setEmailWarning(true);
      }, [customerEmail]);

      React.useEffect(() => {
        isEmailValid(email) ? handleSearch(email) : setEmailWarning(true);
      }, [email]);

      return (
        <>
          <Final.Form onSubmit={() => handleSearch(email)}>
            {(form) => {
              return (
                <div>
                  <LoadingComponent loading={loading || isSearching} />
                  <Message data-test="safe-to-write-message">
                    <p>
                      This page shows information held in the customer&apos;s Paypoint app account.
                    </p>
                    <p>
                      If no account is found, search for their PayPoint account using a different
                      email address.
                    </p>
                  </Message>
                  <div className="formBox">
                    <FieldComponent name="paypoint-email">
                      <label htmlFor="paypointEmail"></label>
                      <div className="paypointEmail">
                        <Final.Field
                          name="paypointEmail"
                          component="input"
                          data-test="paypoint-email"
                          autoComplete="off"
                          id="paypointEmail"
                          initialValue={email}
                          className="paypointEmail"
                        />
                      </div>
                    </FieldComponent>
                    <div className="searchAccountEmailButton">
                      <PrimaryCTAButton
                        className="button"
                        data-test="form-submit-button"
                        fluid
                        onClick={() => setEmail(form.values.paypointEmail)}
                      >
                        Find account details
                      </PrimaryCTAButton>
                    </div>
                  </div>
                  {payPointDetails ? (
                    payPointDetails.map((detail: Details, index: number) => (
                      <PaypointDetailsTable {...detail} key={index} />
                    ))
                  ) : emailWarning ? (
                    <Message warning={true} data-test="invalid-email-warning">
                      Invalid email
                    </Message>
                  ) : (
                    <Message warning={true} data-test="no-details-available-warning">
                      There are no PayPoint account details available for this email address
                    </Message>
                  )}
                </div>
              );
            }}
          </Final.Form>
        </>
      );
    }}
  </Query>
);
