import * as React from 'react';
import { useMutation } from 'react-apollo';
import { ReadFuelType } from '../../../../__generated__/globalTypes';
import { PendingSpinner } from '../../../../components/pending-spinner';
import {
  TriggerDisablePinMutation as Results,
  TriggerDisablePinMutationVariables as Variables,
} from './__generated__/TriggerDisablePinMutation';
import { useContext } from 'react';
import { ClientStateContext } from '../../../../client/client-state-context';
import { DisablePinMutation } from './disable-pin.form.gql';
import './disable-pin.form.less';
import { PrimaryCTAButton } from '@ovotech/nebula';

interface Props {
  fuelType: ReadFuelType;
  msn: string;
  mpxn: string;
  accountId: string;
  pendingDisable: boolean;
  disabled?: boolean;
}

export const DisablePinButton: React.SFC<Props> = ({
  accountId,
  msn,
  mpxn,
  fuelType,
  pendingDisable,
  disabled,
}) => {
  const [disablePin, { loading }] = useMutation<Results, Variables>(DisablePinMutation, {
    refetchQueries: ['accountMetersQuery'],
    awaitRefetchQueries: true,
  });
  const operationInAction = pendingDisable || loading;
  const { session } = useContext(ClientStateContext);

  return (
    <PrimaryCTAButton
      className="button"
      onClick={() =>
        disablePin({
          variables: {
            input: {
              accountId,
              msn,
              mpxn,
              fuelType,
              agentName: session?.user.email || '',
            },
          },
        })
      }
      disabled={operationInAction || disabled}
    >
      {operationInAction && <PendingSpinner contentText="Pending Request" disablePadding={true} />}
      Disable PIN
    </PrimaryCTAButton>
  );
};
