import * as React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import './pending-spinner.less';

interface Props {
  contentText: string;
  disablePadding?: boolean;
}

export const PendingSpinner: React.FunctionComponent<Props> = (props) => (
  <Popup
    trigger={
      <div className={props.disablePadding ? '' : 'PendingSpinner'}>
        <Icon className="spinner loading circle notch" />
      </div>
    }
    content={props.contentText}
    position="top center"
  />
);
