import React from 'react';

export const ErrorWarningIcon: React.FC = () => (
  <div className="warning-icon">
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_111_397)">
        <path
          d="M9.00005 1.625C9.49926 1.625 9.95981 1.88867 10.2129 2.32109L17.8067 15.2586C18.0633 15.6945 18.0633 16.2324 17.8137 16.6684C17.5641 17.1043 17.0965 17.375 16.5938 17.375H1.4063C0.903561 17.375 0.435983 17.1043 0.186373 16.6684C-0.0632361 16.2324 -0.0597205 15.691 0.193405 15.2586L7.78715 2.32109C8.04028 1.88867 8.50083 1.625 9.00005 1.625ZM9.00005 6.125C8.53247 6.125 8.1563 6.50117 8.1563 6.96875V10.9062C8.1563 11.3738 8.53247 11.75 9.00005 11.75C9.46762 11.75 9.8438 11.3738 9.8438 10.9062V6.96875C9.8438 6.50117 9.46762 6.125 9.00005 6.125ZM10.125 14C10.125 13.3777 9.62231 12.875 9.00005 12.875C8.37778 12.875 7.87505 13.3777 7.87505 14C7.87505 14.6223 8.37778 15.125 9.00005 15.125C9.62231 15.125 10.125 14.6223 10.125 14Z"
          fill="#D2341B"
        />
      </g>
      <defs>
        <clipPath id="clip0_111_397">
          <rect width="18" height="18" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  </div>
);
