import * as React from 'react';
import { Segment, Table } from 'semantic-ui-react';
import { LGMeterWarning } from '../../LGMeterWarning';
import { accountMetersQuery_account_meters as Meter } from '../../../screens/account/account-meters/__generated__/accountMetersQuery';
import { ElectricityIcon } from '../../electricity-icon';
import { GasIcon } from '../../gas-icon';
import './trad-meter.less';

interface Props {
  meter: Meter;
}

export const TradMeter: React.FunctionComponent<Props> = (props) => (
  <Segment key={props.meter.msn} className="TradMeter">
    <div className="TradMeterBalanceIcon">
      {props.meter.fuelType === 'GAS' ? <GasIcon /> : <ElectricityIcon />}
    </div>
    <div className="TradMeterHeader">
      <Table size="small" definition>
        <Table.Body>
          <Table.Row>
            <Table.Cell colSpan={3} content="Type" />
            <Table.Cell colSpan={3} content="Trad" />
          </Table.Row>
          <Table.Row>
            <Table.Cell colSpan={3} content="MSN" />
            <Table.Cell colSpan={3} content={props.meter.msn} />
          </Table.Row>
          <Table.Row>
            <Table.Cell colSpan={3} content={props.meter.fuelType === 'GAS' ? 'MPRN' : 'MPAN'} />
            <Table.Cell colSpan={3} content={props.meter.mpxn} />
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
    <LGMeterWarning meter={props.meter} key={props.meter.msn} />
  </Segment>
);
