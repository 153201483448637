import { groupBy } from 'lodash/fp';
import * as React from 'react';
import { Message } from 'semantic-ui-react';
import { ClientStateContext } from '../../client/client-state-context';
import { ClientError, ErrorCode } from '../../types';

export const ErrorCount: React.SFC<{ count: number }> = ({ count }) =>
  count > 1 ? <strong>({count})</strong> : null;

interface Props {
  dismissErrorCode: (code: string) => void;
  errors: ClientError[];
}

export const ClientErrorView: React.SFC<Props> = ({ errors, dismissErrorCode }) => {
  const groupedErrors = Object.entries(groupBy('code', errors));

  return (
    <>
      {groupedErrors.map(([code, errorsGroup]) => {
        switch (code) {
          case ErrorCode.NETWORK_ERROR:
            return (
              <Message error data-test={code} key={code} onDismiss={() => dismissErrorCode(code)}>
                <Message.Header>
                  Could not contact server <ErrorCount count={errorsGroup.length} />
                </Message.Header>
                <Message.List>
                  <Message.Item>Check you’re connected to the internal network</Message.Item>
                  <Message.Item>Try again in a couple of minutes</Message.Item>
                  <Message.Item>
                    If this message is still appearing after a few minutes, email{' '}
                    <a href="mailto:boost-bit-tech@ovoenergy.com">boost-bit-tech@ovoenergy.com</a>{' '}
                    with information about what you&apos;re trying to do and we’ll do some checks to
                    make sure all is well.
                  </Message.Item>
                </Message.List>
              </Message>
            );

          case ErrorCode.UNAUTHORIZED:
            return (
              <Message error data-test={code} key={code} onDismiss={() => dismissErrorCode(code)}>
                <Message.Header>
                  Permissions Error <ErrorCount count={errorsGroup.length} />
                </Message.Header>
                <Message.Content>
                  You don’t have enough permissions to access this feature. Please contact{' '}
                  <a href="mailto:retail-payg-agent-experience-team@ovoenergy.com">
                    retail-payg-agent-experience-team@ovoenergy.com
                  </a>{' '}
                  to request access!
                </Message.Content>
              </Message>
            );

          case ErrorCode.UNAUTHENTICATED:
            return (
              <Message error data-test={code} key={code} onDismiss={() => dismissErrorCode(code)}>
                <Message.Header>
                  Authentication Error <ErrorCount count={errorsGroup.length} />
                </Message.Header>
                <Message.Content>
                  Sign in unsuccessful. Please check your e-mail and password and try again,
                  otherwise contact{' '}
                  <a href="mailto:retail-payg-agent-experience-team@ovoenergy.com">
                    retail-payg-agent-experience-team@ovoenergy.com
                  </a>{' '}
                  to request access!
                </Message.Content>
              </Message>
            );

          case ErrorCode.INTERNAL_SERVER_ERROR:
            return (
              <Message error data-test={code} key={code} onDismiss={() => dismissErrorCode(code)}>
                Server Error <ErrorCount count={errorsGroup.length} />
                <Message.List>
                  {errorsGroup
                    .filter((error) => error.message)
                    .map((error, index) => (
                      <Message.Item key={index}>{error.message}</Message.Item>
                    ))}
                </Message.List>
              </Message>
            );
          case ErrorCode.BAD_USER_INPUT:
            return (
              <Message error data-test={code} key={code} onDismiss={() => dismissErrorCode(code)}>
                <Message.Header>Error</Message.Header>
                <Message.List>
                  {errorsGroup
                    .filter((error) => error.message)
                    .map((error, index) => (
                      <Message.Item key={index}>{error.message}</Message.Item>
                    ))}
                </Message.List>
              </Message>
            );
          default:
            return (
              <Message error data-test={code} key={code} onDismiss={() => dismissErrorCode(code)}>
                <Message.Header>
                  Server Error <ErrorCount count={errorsGroup.length} />
                </Message.Header>
                <Message.Content>
                  An error occurred, please try again later. If the problem keeps happening then
                  contact{' '}
                  <a href="mailto:boost-bit-tech@ovoenergy.com">boost-bit-tech@ovoenergy.com</a>
                </Message.Content>
              </Message>
            );
        }
      })}
    </>
  );
};

export const ClientErrorComponent: React.SFC<Record<string, unknown>> = () => (
  <ClientStateContext.Consumer>
    {({ errors, dismissErrorCode }) => (
      <ClientErrorView dismissErrorCode={dismissErrorCode} errors={errors} />
    )}
  </ClientStateContext.Consumer>
);

ClientErrorComponent.displayName = 'ClientErrorComponent';
