import { get } from '@ovotech/typesafe-get';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import { accountDetails, getURI } from '../../routes';
import { accountSearchQuery_account as Account } from './__generated__/accountSearchQuery';

interface Props {
  accounts?: Account[] | null;
}

export const AccountsTable: React.SFC<Props> = ({ accounts }) => {
  if (!accounts || !accounts.length) {
    return null;
  }

  return (
    <Table size="large" celled data-test="accounts-table">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell content="First Name" />
          <Table.HeaderCell content="Last Name" />
          <Table.HeaderCell content="City" />
          <Table.HeaderCell content="Postcode" />
          <Table.HeaderCell content="Details" />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {accounts.map((account) => (
          <Table.Row key={account.id}>
            <Table.Cell content={get(account, 'customerDetails', 'firstName')} />
            <Table.Cell content={get(account, 'customerDetails', 'lastName')} />
            <Table.Cell content={get(account, 'customerDetails', 'city')} />
            <Table.Cell content={get(account, 'customerDetails', 'postcode')} />
            <Table.Cell>
              <Link
                to={getURI(accountDetails, account.id, false)}
                className="button"
                data-test="account-search-details"
              >
                Details
              </Link>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
