import * as React from 'react';
import { PrimaryCTAButton } from '@ovotech/nebula';
import { createTheme } from '@ovotech/nebula';
import { AdditionalConfig, themeConfig } from '@ovotech/ovoenergy-theme';
import { PendingSpinner } from '../pending-spinner';

const theme = createTheme<AdditionalConfig>(themeConfig);

const { primary } = theme.colors;

const primaryMuted = '#9EC79F';

interface Props<T> {
  buttonText: string;
  disabled?: boolean;
  dataTestId?: string;
  form?: string;
  onClick?: () => void;
  loading?: boolean;
}

export const PrimaryDisableButton: React.FC<Props<string>> = ({
  buttonText,
  disabled,
  dataTestId,
  form,
  onClick,
  loading,
}) => (
  <PrimaryCTAButton
    className="button"
    style={{
      backgroundColor: disabled ? primaryMuted : primary,
      borderColor: disabled ? primaryMuted : primary,
    }}
    disabled={disabled}
    data-test={dataTestId}
    type="submit"
    form={form}
    onClick={onClick}
  >
    {!loading ? (
      <>{buttonText}</>
    ) : (
      <span style={{ display: 'flex' }}>
        {buttonText}&nbsp;
        <PendingSpinner contentText="Pending Request" disablePadding={true} />
      </span>
    )}
  </PrimaryCTAButton>
);
