import { get } from '@ovotech/typesafe-get';
import * as React from 'react';
import { Query } from 'react-apollo';
import { RouteComponentProps } from 'react-router';
import { Dimmer, Loader, Table } from 'semantic-ui-react';
import {
  accountDetailsQuery as Results,
  accountDetailsQueryVariables as Variables,
} from './__generated__/accountDetailsQuery';
import { accountDetailsQuery } from './account-details.view.gql';
import './account-details.less';
import { ErrorWarningIcon } from '../../../components/error-warning-icon';

interface Props extends Pick<RouteComponentProps<{ accountNumber: string }>, 'match'> {
  status: string | undefined;
}

export const formatVulnerabilities = (
  vulnerabilities: string[] | null | undefined,
): string | React.ReactNode => {
  let items: React.ReactNode[] = [];

  if (!vulnerabilities) {
    return 'No recorded vulnerabilities';
  }

  switch (vulnerabilities?.length) {
    case 0:
      return 'No recorded vulnerabilities';
    case 1:
      return vulnerabilities.toString();
    default:
      items = vulnerabilities?.map((vulnerability, index) => (
        <li key={index}>{vulnerability}</li>
      )) as Array<React.ReactNode>;
      return <ul>{items}</ul>;
  }
};

export const AccountDetailsView: React.FC<Props> = ({ match, status }) => (
  <Query<Results, Variables>
    query={accountDetailsQuery}
    variables={{ accountId: match.params.accountNumber }}
    notifyOnNetworkStatusChange
  >
    {({ data, loading }) => {
      const customerDetails = get(data, 'account', 'customerDetails');
      const panContent = status === 'Mismatched' ? 'PAN mismatch' : 'PAN match';
      const isAccountInactive = data?.account?.accountStatus?.isAccountClosed;
      return (
        <Dimmer.Dimmable data-test="account-details-view">
          <Dimmer inverted active={loading}>
            <Loader />
          </Dimmer>
          {customerDetails ? (
            <div className="AccountDetails">
              <Table size="large" definition striped>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell
                      width={4}
                      content="First Name"
                      data-test="cell-with-explicit-width"
                    />
                    <Table.Cell
                      content={get(customerDetails, 'firstName')}
                      data-test="first-name"
                    />
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell content="Last Name" />
                    <Table.Cell content={get(customerDetails, 'lastName')} data-test="last-name" />
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell content="Address" />
                    <Table.Cell content={get(customerDetails, 'address')} data-test="address" />
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell content="City" />
                    <Table.Cell content={get(customerDetails, 'city')} data-test="city" />
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell content="Postcode" />
                    <Table.Cell content={get(customerDetails, 'postcode')} data-test="postcode" />
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell content="Email" />
                    <Table.Cell content={get(customerDetails, 'email')} data-test="email" />
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell content="Vulnerabilities" />
                    <Table.Cell
                      content={formatVulnerabilities(get(customerDetails, 'vulnerabilities'))}
                      data-test="vulnerabilities"
                    />
                  </Table.Row>
                  {!!data?.account?.accountStatus && (
                    <Table.Row>
                      <Table.Cell content="Account Status" />
                      <Table.Cell data-test="account-status">
                        {!!isAccountInactive && (
                          <div style={{ display: 'flex' }}>
                            <ErrorWarningIcon />
                            <span
                              style={{
                                paddingLeft: '8px',
                                textDecoration: 'underline',
                                color: '#D2341B',
                              }}
                            >
                              {'Inactive'}
                            </span>
                          </div>
                        )}
                        {!isAccountInactive && 'Active'}
                      </Table.Cell>
                    </Table.Row>
                  )}
                  {status === 'Mismatched' && (
                    <Table.Row>
                      <Table.Cell content="Alerts" />
                      <Table.Cell data-test="alerts" style={{ display: 'flex' }}>
                        <ErrorWarningIcon />
                        <span
                          style={{
                            paddingLeft: '8px',
                            textDecoration: 'underline',
                            color: '#D2341B',
                          }}
                        >
                          {panContent}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>
            </div>
          ) : null}
        </Dimmer.Dimmable>
      );
    }}
  </Query>
);
