import { noop } from 'lodash/fp';
import * as React from 'react';
import { loginMutation_createSession as Session } from '../components/screen/__generated__/loginMutation';
import { ClientError } from '../types';

export interface State {
  errors: ClientError[];
  session: Session | null;
  createSession: (session: Session) => void;
  setErrors: (errors: ClientError[]) => void;
  dismissErrorCode: (code: string) => void;
  clear: () => void;
}

export const defaultState: State = {
  session: null,
  errors: [],
  createSession: noop,
  setErrors: noop,
  dismissErrorCode: noop,
  clear: noop,
};

export const ClientStateContext = React.createContext<State>(defaultState);
