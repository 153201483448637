import * as moment from 'moment';
import * as React from 'react';
import * as Final from 'react-final-form';
import { Dropdown, Form, Icon, Responsive, Segment } from 'semantic-ui-react';
import { DateFilter } from '../../components/date-pickers/date-filter';
import { DateRange } from '../../components/date-pickers/types';
import * as validate from 'validate.js';
import { dateFormatIso, formatEventType, toSemanticOptions } from '../../helpers';
import './event-history-filter.less';
import { QueryStringFilters } from './types';
import { PrimaryCTAButton } from '@ovotech/nebula';

interface Props extends Pick<Final.FormProps, 'onSubmit'> {
  initialValues: QueryStringFilters;
  loading: boolean;
}

export const startEndDateValidator = (value: [string, string]): string | undefined => {
  if (value[0].length === 0) {
    return 'Must select a start date';
  }
  if (value[1].length === 0) {
    return 'Must select an end date';
  }
  return;
};
validate.validators.eventFiltersStartEndDate = startEndDateValidator;

const formValidation = {
  date: {
    eventFiltersStartEndDate: true,
  },
};

type FieldValue = string | number | boolean | (string | number | boolean)[] | undefined;
type Input = Omit<Final.FieldInputProps<FieldValue, HTMLElement>, 'onBlur' | 'onFocus'>;

export const EventFilterForm: React.FC<Props> = ({ initialValues, loading, onSubmit }) => (
  <Final.Form
    onSubmit={onSubmit}
    initialValues={initialValues}
    validate={(values) => validate(values, formValidation)}
  >
    {({ form, invalid }) => (
      <Form>
        <Segment>
          <div className="EventHistoryFilter">
            <div className="EventHistoryFilterTypeFilter">
              <Final.Field name="type">
                {({ input }) => (
                  <Form.Field>
                    <label>Event Types</label>
                    <Dropdown
                      data-test="event-type-dropdown"
                      placeholder="All event types"
                      multiple
                      search
                      selection
                      options={toSemanticOptions(formatEventType)}
                      {...(input as Input)}
                      onChange={(_, { value }) => input.onChange({ target: { value } })}
                    />
                  </Form.Field>
                )}
              </Final.Field>
            </div>
            <div>
              <Final.Field name="date">
                {({ input }) => (
                  <Form.Field>
                    <label>Date Range</label>
                    <DateFilter
                      startDate={input.value[0] ? moment(input.value[0]) : undefined}
                      endDate={input.value[1] ? moment(input.value[1]) : undefined}
                      onDatesChange={({ startDate, endDate }: DateRange) => {
                        const value = [
                          startDate ? startDate.format(dateFormatIso) : '',
                          endDate ? endDate.format(dateFormatIso) : '',
                        ];
                        input.onChange({ target: { value } });
                      }}
                    />
                  </Form.Field>
                )}
              </Final.Field>
            </div>
            <div className="EventHistoryFilterSubmit">
              <PrimaryCTAButton
                className="button"
                disabled={loading || invalid}
                data-test="event-history-filter-submit-button"
                onClick={() => form.submit()}
              >
                <Responsive as="span" {...Responsive.onlyLargeScreen}>
                  <Icon name="filter" />
                </Responsive>
                Submit
              </PrimaryCTAButton>
            </div>
          </div>
        </Segment>
      </Form>
    )}
  </Final.Form>
);
