import * as React from 'react';

export const GasIcon = ({ width = 24, height = 24 }: React.SVGProps<SVGElement>) => (
  <svg
    width={width}
    height={height}
    style={{
      color: '#1e98bc',
      stroke: 'black',
    }}
    viewBox="0 0 24 24"
    fill="currentcolor"
  >
    <defs>
      <path
        id="sml_10_svg__a"
        d="M10.951 5.256c1.235 2.617 5.642 6.186 4.296 11.19-1.636 6.088-6.983 6.271-10.517 4.766C1.398 19.726.26 15.313.54 12.045.82 8.791 2.506 6.22 3.63 5.497c.713-.458.218.507.414 2.52.195 2.014 1.076 3.27 1.299 3.534.528.627-.713-1.037.958-5.962C7.278 2.666 8.757.741 9.848.084c1.08-.652-.219 2.64 1.103 5.172zm2.632 9.756c-.709-3.169-4.068-4.772-3.673-4.396.904.83-1.108 2.238-1.613 3.846C7.792 16.071 8 16.566 8 17.782c-1.618 0-2.416-.343-3.154-.867s-1.405-1.937-1.621-2.698c-.217-.76-.847 1.986 0 3.737.7 1.449 1.57 2.274 3.05 2.828 1.478.554 3.653.537 5.188-.392 1.534-.93 2.507-3.647 2.12-5.378z"
      />
    </defs>
    <g fillRule="evenodd" transform="translate(4.5 1)">
      <mask id="sml_10_svg__b">
        <use xlinkHref="#sml_10_svg__a" />
      </mask>
      <use xlinkHref="#sml_10_svg__a" />
      <g mask="url(#sml_10_svg__b)">
        <path d="M-4.5-1h24v24h-24z" />
      </g>
    </g>
  </svg>
);
