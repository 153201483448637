import * as React from 'react';
import { accountMetersQuery_account_meters as Meter } from '../../screens/account/account-meters/__generated__/accountMetersQuery';
import { Message } from 'semantic-ui-react';
import { InstallationType } from '../../__generated__/globalTypes';

interface Props {
  meter: Meter;
}

export const LGMeterWarning: React.FunctionComponent<Props> = (props) => {
  return props.meter.installationType === InstallationType.L_AND_G ? (
    <Message data-test="LG-meter-warning-test">
      This is an L+G meter. The reconnection process is different for this meter type. Please visit
      the&nbsp;
      <a
        href={
          'https://ovoenergy.my.salesforce.com/articles/Knowledge/SMETS2-Disconnection-Reconnection?navBack=H4sIAAAAAAAAAIuuVipWslLyzssvz0lNSU_1yM9NVdJRygaKFSSmp4ZkluSA-KVAvn58aaZ-NkyhPpCDqqs2FgCHRwp0TQAAAA&popup=false&id=kA04I000000XlgE'
        }
      >
        Knowledge Base page
      </a>
      &nbsp;for more information.
    </Message>
  ) : (
    <></>
  );
};
