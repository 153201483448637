import * as React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './single-date-picker.less';
import moment from 'moment';
import { Input, Icon } from 'semantic-ui-react';
import { calculateDaysDifference } from '../../helpers';

interface SelectedDateShape {
  selectedDate: Date | null;
  setSelectedDate: (date: Date | [Date, Date] | null) => void;
  testId?: string;
}

export const SingleDatePicker: React.FC<SelectedDateShape> = (props) => {
  const { selectedDate, setSelectedDate, testId } = props;

  const today = moment();
  const maxSelectableDate = today.clone().add(31, 'days');

  const isSelectable = (date: Date) => {
    if (moment(date).isSame(today, 'day')) {
      return false; // Today should not be selectable
    }
    if (moment(date).isSameOrBefore(maxSelectableDate, 'day')) {
      return true;
    }
    // Additional logic for the 31st of January to March
    if (
      today.isSame(moment(date).date(31).month(0), 'day') &&
      moment(date).isSameOrBefore(moment(date).month(2).endOf('month'), 'day')
    ) {
      return true;
    }
    return false;
  };

  const formatDate = (date: Date | null) => {
    if (date) {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear().toString();
      return `${day}/${month}/${year}   (${calculateDaysDifference(date)} days)`;
    }
    return '';
  };

  // Calculate the date range between today and the selected date
  const calculateDateRange = (): Date[] => {
    const today = new Date();
    if (selectedDate) {
      // Create an array of dates between today and the selected date
      const dateRange = [];
      for (
        let date = new Date(today.setDate(today.getDate() + 1));
        date <= selectedDate;
        date.setDate(date.getDate() + 1)
      ) {
        dateRange.push(new Date(date));
      }
      return dateRange;
    }
    return [];
  };

  return (
    <DatePicker
      showPopperArrow={false}
      selected={selectedDate}
      onChange={(date) => setSelectedDate(date)}
      minDate={moment().toDate()}
      maxDate={moment().add(31, 'days').toDate()}
      filterDate={isSelectable}
      highlightDates={calculateDateRange()}
      customInput={
        <Input type="text" data-test={testId} icon={<Icon name="chevron down" />} size="big" cursor>
          <input value={formatDate(selectedDate)} />
          <Icon name="chevron down" />
        </Input>
      }
    />
  );
};
