import * as React from 'react';
import { Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { ApolloClientWithState } from './client';
import { ClientState } from './client/client-state';
import { SHOW_BATCH_ADJUSTMENT } from './feature-flags';

import {
  accountDetails,
  accountSearch,
  home,
  batchAdjustment,
  batchAdjustmentDetails,
} from './routes';
import {
  AccountScreen,
  AccountSearchScreen,
  HomeScreen,
  BatchAdjustmentScreen,
  BatchAdjustmentDetailsScreen,
} from './screens';

import './utils.less';
import { createTheme } from '@ovotech/nebula';
import { AdditionalConfig, themeConfig } from '@ovotech/ovoenergy-theme';
import { ThemeProvider } from 'styled-components';

export const theme = createTheme<AdditionalConfig>(themeConfig);

export const App: React.FC = () => (
  <ThemeProvider theme={theme}>
    <ClientState>
      <ApolloClientWithState>
        <BrowserRouter basename="/">
          <Switch>
            <Route exact path={home.path} component={HomeScreen} />
            <Route exact path={accountSearch.path} component={AccountSearchScreen} />
            <Route path={accountDetails.path} component={AccountScreen} />
            <Route path={batchAdjustmentDetails.path} component={BatchAdjustmentDetailsScreen} />
            {SHOW_BATCH_ADJUSTMENT && (
              <Route path={batchAdjustment.path} component={BatchAdjustmentScreen} />
            )}
          </Switch>
        </BrowserRouter>
      </ApolloClientWithState>
    </ClientState>
  </ThemeProvider>
);
