import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import googleLogo from '../../assets/google-logo.svg';
import * as React from 'react';
import { Image } from 'semantic-ui-react';

const styles = {
  button: {
    backgroundColor: '#fff',
    boxShadow: 'rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px',
    borderRadius: '2px',
    border: '1px solid transparent',
  },
  disabledButton: {
    cursor: 'progress',
    backgroundColor: '#fff',
    border: '1px solid transparent',
    opacity: 0.45,
  },
  buttonContent: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  logo: {
    maxWidth: '18px',
    maxHeight: '18px',
    marginRight: '10px',
  },
};
interface GoogleLoginButtonProps {
  text: 'Logging in ...' | 'Login';
  disabled: boolean;
  onSuccess: (user: any) => Promise<any>;
}
export const GoogleLoginButton = ({ onSuccess, text, disabled }: GoogleLoginButtonProps) => {
  const login = useGoogleLogin({
    onSuccess: onSuccess,
    flow: 'auth-code',
  });
  return (
    <button
      style={disabled ? styles.disabledButton : styles.button}
      className="ui button boost-login center"
      disabled={disabled}
      onClick={() => {
        login();
      }}
    >
      <div style={styles.buttonContent}>
        <Image src={googleLogo} alt="Google Logo" style={styles.logo} />
        {text}
      </div>
    </button>
  );
};

const GoogleProvider = (props: GoogleLoginButtonProps) => (
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_ID as string}>
    <GoogleLoginButton {...props} />
  </GoogleOAuthProvider>
);

export default GoogleProvider;
