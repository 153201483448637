import * as React from 'react';
import { Scope } from '../../__generated__/globalTypes';
import { ClientStateContext } from '../../client/client-state-context';
import { LoginView } from './login.view';
import { ScreenView } from './screen.view';
import { userHasRequiredScope } from '../../../src/helpers';

interface Props {
  title: string;
  requiredScope: Scope;
  showGlobalError?: boolean;
}

export const ScreenComponent: React.SFC<Props> = ({
  title,
  requiredScope,
  children,
  showGlobalError,
}) => (
  <ClientStateContext.Consumer>
    {({ session }) => {
      if (session && userHasRequiredScope(session, requiredScope)) {
        return (
          <ScreenView session={session} title={title} showGlobalError={showGlobalError}>
            {children}
          </ScreenView>
        );
      }
      return <LoginView title={title} />;
    }}
  </ClientStateContext.Consumer>
);
