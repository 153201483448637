import * as React from 'react';
import { Label, Popup, Segment } from 'semantic-ui-react';
import { BalanceType, ReadFuelType } from '../../__generated__/globalTypes';
import { accountBalanceQuery_account_balances_UnknownMeterBalance } from '../../screens/account/account-balance/__generated__/accountBalanceQuery';
import { ReadBalanceButton } from '../../screens/account/forms/read-balance';
import { ElectricityIcon } from '../electricity-icon';
import { GasIcon } from '../gas-icon';
import { Transactions, Adjustment } from '../transactions';
import './unknown-balance-amount.less';

interface Props {
  balance: accountBalanceQuery_account_balances_UnknownMeterBalance;
  accountId: string;
  transactionId?: string;
}

const balanceToReadFuelTypeMap: {
  [P in BalanceType]?: ReadFuelType;
} = {
  [BalanceType.ELECTRICITY]: ReadFuelType.ELECTRICITY,
  [BalanceType.GAS]: ReadFuelType.GAS,
};

export const UnknownBalanceAmount: React.FunctionComponent<Props> = (props) => {
  return (
    <Segment className="UnknownBalance">
      <div className="UnknownBalanceIcon">
        {props.balance.type === 'GAS' ? <GasIcon /> : <ElectricityIcon />}
        <div className="UnknownBalanceIconMSN">{props.balance.msn}</div>
      </div>
      <div className="UnknownBalanceAmountContainer">
        <Popup
          trigger={
            <div className="UnknownBalanceMessage">
              <Label className="UnknownBalanceLabel" size="large">
                Balance Unknown
              </Label>
            </div>
          }
          content={
            props.balance.adjustable ? (
              <p>Trigger an on demand meter read if you think this meter exists</p>
            ) : (
              'Balance Unknown'
            )
          }
          position="top center"
        />
        {props.balance.adjustable && (
          <div className="UnknownBalanceReadBalanceButton">
            <ReadBalanceButton
              accountId={props.accountId}
              msn={props.balance.msn}
              mpxn={props.balance.mpxn as string}
              type={balanceToReadFuelTypeMap[props.balance.type] as ReadFuelType}
              pendingRead={props.balance.performingPrepaymentDataRead}
            />
          </div>
        )}
      </div>
      <div className="MeterAdjustments">
        <Transactions
          adjustments={props.balance.adjustments as Adjustment[]}
          heading="Balance transactions"
        />
      </div>
    </Segment>
  );
};
