import * as React from 'react';
import { FieldMetaState } from 'react-final-form';
import { Message } from 'semantic-ui-react';

interface Props {
  meta: FieldMetaState<Record<string, unknown>>;
}

export const FormFieldMessage: React.SFC<Props> = ({ meta }) =>
  meta.touched && meta.error
    ? meta.error.map((error: Error, index: number) => (
        <Message key={index} color="yellow" size="tiny">
          {error}
        </Message>
      ))
    : null;
