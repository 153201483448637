import * as React from 'react';
import { Field } from 'react-final-form';
import { Form, Label } from 'semantic-ui-react';

interface Props {
  name: string;
  alwaysShowError?: boolean;
  [key: string]: unknown;
}

export const FieldComponent: React.SFC<Props> = ({ name, children, alwaysShowError, ...rest }) => (
  <Field
    name={name}
    subscription={{ touched: true, error: true }}
    render={({ meta: { touched, error } }) => (
      <Form.Field error={touched && !!error} {...rest}>
        {children}
        {(touched || alwaysShowError) && error ? (
          <Label color="red" pointing>
            {error.map((message: string, key: number) => (
              <div key={key} data-test="error-message">
                {message}
              </div>
            ))}
          </Label>
        ) : null}
      </Form.Field>
    )}
  />
);
