import * as React from 'react';

export const formatVulnerabilities = (
  vulnerabilities: string[] | null | undefined,
): React.ReactNode | null => {
  let list: React.ReactNode[] = [];
  let item: React.ReactNode = null;

  if (!vulnerabilities) {
    return null;
  }

  switch (vulnerabilities?.length) {
    case 0:
      return null;
    case 1:
      item = <li>{vulnerabilities.toString()}</li>;
      return <ul>{item}</ul>;
    default:
      list = vulnerabilities?.map((vulnerability, index) => (
        <li key={index}>{vulnerability}</li>
      )) as Array<React.ReactNode>;

      return <ul>{list}</ul>;
  }
};
