import { distanceInWords, format, isBefore, subDays } from 'date-fns';
import * as React from 'react';
import { Popup } from 'semantic-ui-react';
import { dateFormat } from '../../helpers';
import './last-read-message.less';

interface Props {
  effectiveAt: string;
  label: string;
  now?: Date;
}

export const LastReadMessage: React.FunctionComponent<Props> = (props) => {
  const now = props.now || new Date();
  const twoDaysAgo = subDays(now, 2);

  if (props.effectiveAt && isBefore(props.effectiveAt, now)) {
    return (
      <span
        className={`text ${
          isBefore(props.effectiveAt, twoDaysAgo) ? 'OutOfDateBalance' : 'muted InDateBalance'
        }`}
        data-test={props.label}
      >
        <Popup
          trigger={<span>{distanceInWords(props.effectiveAt, now)} ago</span>}
          content={format(props.effectiveAt, dateFormat)}
          data-test={props.label}
        />
      </span>
    );
  }

  return (
    <small className="text muted">
      <Popup
        trigger={<span>just now</span>}
        content={format(props.effectiveAt || now, dateFormat)}
      />
    </small>
  );
};
