import { get } from '@ovotech/typesafe-get';
import { isFuture } from 'date-fns';
import * as React from 'react';
import { useQuery } from 'react-apollo';
import { Route, Switch, RouteComponentProps } from 'react-router';
import { Dimmer, Header, Icon, Loader, Menu } from 'semantic-ui-react';
import { Scope } from '../../__generated__/globalTypes';
import { ClientStateContext } from '../../client/client-state-context';
import { ScreenComponent } from '../../components';
import { PathNavLink } from '../../components/path-nav-link';
import {
  accountBalance,
  accountDetails,
  accountEvents,
  accountMeters,
  getURI,
  paypointDetails,
} from '../../routes';
import {
  accountScreenQuery as Results,
  accountScreenQueryVariables as Variables,
} from './__generated__/accountScreenQuery';
import { AccountBalanceView } from './account-balance/account-balance.view';
import { AccountDetailsView } from './account-details/account-details.view';
import { AccountEventsView } from './account-events/account-events.view';
import { AccountMeterView } from './account-meters/account-meter.view';
import { accountScreenQuery } from './account.screen.gql';
import './account.screen.less';
import { PayPointView } from './paypoint/paypoint';

type Props = Pick<RouteComponentProps<{ accountNumber: string }>, 'match'>;

export const AccountScreen: React.SFC<Props> = ({
  match: {
    params: { accountNumber },
  },
}) => {
  const { session } = React.useContext(ClientStateContext);
  const isSessionValid = isFuture(session?.expiresAt) ?? false;
  const { data, loading } = useQuery<Results, Variables>(accountScreenQuery, {
    variables: {
      accountId: accountNumber,
    },
    skip: !isSessionValid,
  });

  const customerDetails = get(data, 'account', 'customerDetails');
  const balances = get(data, 'account', 'balances') || [];
  const meters = get(data, 'account', 'meters') || [];
  const panReconciliationStatus = get(data, 'panReconciliationStatus', 'status');
  const panReconciliations = get(data, 'panReconciliationStatus', 'reconciliations');

  const isDcc = meters.filter((m) => m.capabilities.isDcc).length > 0;

  return (
    <ScreenComponent title="Account Information" requiredScope={Scope.ACCOUNT}>
      <div data-test="account-header" className="account-header-style">
        <Header as="h2">
          <Icon name="user circle" />
          <Dimmer.Dimmable as={Header.Content}>
            <Dimmer inverted active={loading}>
              <Loader />
            </Dimmer>
            {get(customerDetails, 'firstName')} {get(customerDetails, 'lastName')}
            <Header.Subheader title="Account Number">{accountNumber}</Header.Subheader>
          </Dimmer.Dimmable>
        </Header>
      </div>
      <Menu pointing secondary>
        <PathNavLink
          exact={true}
          to={getURI(accountDetails, accountNumber)}
          data-test="account-details-tab"
        >
          Details
        </PathNavLink>
        {!loading && meters.length > 0 && (
          <PathNavLink to={getURI(accountMeters, accountNumber)} data-test="account-meters-tab">
            Meters
          </PathNavLink>
        )}
        {!loading && (balances.length || isDcc) && (
          <PathNavLink to={getURI(accountBalance, accountNumber)} data-test="account-balance-tab">
            Balance
          </PathNavLink>
        )}
        {!loading && (
          <PathNavLink to={getURI(accountEvents, accountNumber)} data-test="account-events-tab">
            Event History
          </PathNavLink>
        )}
        {!loading && (
          <PathNavLink to={getURI(paypointDetails, accountNumber)} data-test="paypoint-tab">
            Paypoint
          </PathNavLink>
        )}
      </Menu>
      <Switch>
        <Route
          exact
          path={accountDetails.path}
          render={(props) => <AccountDetailsView {...props} status={panReconciliationStatus} />}
        />
        {meters.length && (
          <Route
            exact
            path={accountMeters.path}
            render={(props) => (
              <AccountMeterView {...props} panReconciliations={panReconciliations} />
            )}
          />
        )}
        <Route
          exact
          path={accountBalance.path}
          render={(props) => <AccountBalanceView {...props} />}
        />
        <Route exact path={accountEvents.path} component={AccountEventsView} />
        <Route exact path={paypointDetails.path} component={PayPointView} />
      </Switch>
    </ScreenComponent>
  );
};
