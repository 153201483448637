import { Route } from './types';

export const home: Route = {
  path: '/',
};

export const login: Route = {
  path: '/login',
};

export const accountBalance: Route = {
  path: '/account/:accountNumber/balance',
};

export const accountDetails: Route = {
  path: '/account/:accountNumber',
};

export const accountSearch: Route = {
  path: '/account',
};

export const accountEvents: Route = {
  path: '/account/:accountNumber/events',
};

export const accountMeters: Route = {
  path: '/account/:accountNumber/meters',
};

export const paypointDetails: Route = {
  path: '/account/:accountNumber/paypoint',
};

export const batchAdjustment: Route = {
  path: '/batch-adjustment',
};

export const batchAdjustmentDetails: Route = {
  path: '/batch-adjustment-details/:batchId',
};

export const batchAdjustmentSummaries: Route = {
  path: '/batch-adjustment/summaries',
};

export const panRequest: Route = {
  path: '/new-pan-request',
};

export const paymOVOMigrationRequest: Route = {
  path: '/new-pan-request/migration',
};

export const boostIssuePansRequest: Route = {
  path: '/new-pan-request/boost-issue-pans',
};

export const getURI = (route: Route, param = '', withSearch = true): string =>
  `${route.path.replace(':accountNumber', param)}${withSearch ? location.search : ''}`;
