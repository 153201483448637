import * as React from 'react';
import { Container, Icon, Table } from 'semantic-ui-react';

import './batch-adjustment-details.screen.less';

import { Scope } from '../../__generated__/globalTypes';
import { ScreenComponent } from '../../components';
import { BoostPageHeader } from '../../components/page-header/page-header';
import { RouteComponentProps } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import { format } from 'date-fns';
import { dateFormat } from '../../helpers';

import { batchAdjustmentDetailsQuery } from './batch-adjustment-details.screen.gql';

import {
  batchAdjustmentDetailsQuery as Results,
  batchAdjustmentDetailsQueryVariables as Variables,
} from './__generated__/batchAdjustmentDetailsQuery';

type Props = Pick<RouteComponentProps<{ batchId: string }>, 'match'>;

interface StateCellProps {
  state: string;
}

const StateCell: React.FC<StateCellProps> = ({ state }) => {
  switch (state) {
    case 'COMPLETED':
      return (
        <>
          <Icon name="checkmark" />
          {state}
        </>
      );
    case 'PENDING':
    case 'VALIDATING':
    case 'VALIDATED':
    case 'PROCESSING':
    case 'WAITING':
      return (
        <>
          <Icon name="sync" />
          {state}
        </>
      );
    case 'VALIDATION_EXCEPTION':
    case 'PROCESSING_EXCEPTION':
      return (
        <>
          <Icon name="close" />
          {state}
        </>
      );
    default:
      return <>{state}</>;
  }
};

export const BatchAdjustmentDetailsScreen: React.FC<Props> = ({
  match: {
    params: { batchId },
  },
}) => {
  const { data } = useQuery<Results, Variables>(batchAdjustmentDetailsQuery, {
    variables: {
      input: {
        batchId: parseInt(batchId),
      },
    },
  });
  const details = data?.batchAdjustmentDetails;
  const rows = details?.rows;

  return (
    <ScreenComponent title="Batch Adjustment Details" requiredScope={Scope.PROCESSES}>
      <Container>
        <BoostPageHeader>Batch Adjustment Details</BoostPageHeader>
        {data?.batchAdjustmentDetails?.downloadOriginalCsvUrl && (
          <section className="details__section">
            <a
              href={data?.batchAdjustmentDetails?.downloadOriginalCsvUrl}
              target="_blank"
              rel="noreferrer"
              data-test="batch-details-download"
            >
              Download Original CSV File
            </a>
          </section>
        )}
        <section className="details__section">
          <h2>Details</h2>
          <Table className="details__table" data-test="batch-details-table">
            {details && (
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Uploading Agent Name</Table.Cell>
                  <Table.Cell>{details.agentName}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Batch Type</Table.Cell>
                  <Table.Cell>{details.batchType}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Reason for adjustment</Table.Cell>
                  <Table.Cell>{details.context}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Date uploaded</Table.Cell>
                  <Table.Cell>{format(details.createdAt, dateFormat)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Current state</Table.Cell>
                  <Table.Cell>
                    <StateCell state={details.state} />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            )}
          </Table>
        </section>

        <section className="details__section">
          <h2>Jobs Summary</h2>
          <Table className="details__table" data-test="batch-details-jobs">
            {rows && (
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Processed</Table.Cell>
                  <Table.Cell>{rows.processed}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Pending</Table.Cell>
                  <Table.Cell>{rows.pending}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Failed</Table.Cell>
                  <Table.Cell>{rows.failed}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell className="details--bold">Total</Table.Cell>
                  <Table.Cell className="details--bold">{rows.total}</Table.Cell>
                </Table.Row>
              </Table.Body>
            )}
          </Table>
        </section>
      </Container>
    </ScreenComponent>
  );
};
