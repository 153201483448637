import * as moment from 'moment';
import * as React from 'react';
import { DateRangePicker, DateRangePickerShape } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './date-filter.less';

import { DateRange, MaybeDateRange } from './types';

type FocusedInput = string | null;

const coreDatePickerProps: DateRangePickerShape = {
  startDate: moment().subtract(3, 'months'),
  startDateId: 'eventFilterStartDate',
  endDate: moment(),
  endDateId: 'eventFilterEndDate',
  focusedInput: null,
  onDatesChange: (input) => input,
  onFocusChange: (input) => input,
};

const datePickerProps: DateRangePickerShape = {
  ...coreDatePickerProps,
  showClearDates: true,
  showDefaultInputIcon: true,
  initialVisibleMonth: () => moment().subtract(1, 'months'),
  isOutsideRange: () => false,
  displayFormat: 'DD/MM/YYYY',
};

interface Props extends MaybeDateRange {
  onDatesChange: (range: DateRange) => void;
}

export class DateFilter extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    const startDate = props.startDate || (props.endDate ? null : coreDatePickerProps.startDate);
    const endDate = props.endDate || (props.startDate ? null : coreDatePickerProps.endDate);

    this.state = {
      focusedInput: null,
      startDate,
      endDate,
    };

    props.onDatesChange({
      startDate: startDate as moment.Moment,
      endDate: endDate as moment.Moment,
    });
  }

  initialVisibleMonth() {
    const startDate = (this.state as Props).startDate;
    const endDate = (this.state as Props).endDate;

    if (startDate) {
      return startDate;
    } else if (endDate) {
      return moment(endDate).subtract(1, 'months');
    } else {
      return moment().subtract(1, 'months');
    }
  }

  onDatesChange(range: DateRange) {
    this.setState({
      startDate: range.startDate,
      endDate: range.endDate,
    });

    this.props.onDatesChange(range);
  }

  onFocusChange(focusedInput: FocusedInput) {
    this.setState({ focusedInput });
  }

  render() {
    const { focusedInput, startDate, endDate } = this.state as DateRangePickerShape;

    return (
      <DateRangePicker
        {...datePickerProps}
        initialVisibleMonth={this.initialVisibleMonth.bind(this)}
        onDatesChange={this.onDatesChange.bind(this)}
        onFocusChange={this.onFocusChange.bind(this)}
        focusedInput={focusedInput}
        startDate={startDate}
        endDate={endDate}
      />
    );
  }
}
