import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { formatMoney } from '../../helpers';
import { recommendedDiscretionaryCreditQuery as recommendedDCQueryResults } from '../../screens/account/forms/add-discretionary-credit/__generated__/recommendedDiscretionaryCreditQuery';
import { PendingSpinner } from '../pending-spinner';
import './recommended-discretionary-credit.less';

interface Props {
  loading: boolean;
  data?: recommendedDCQueryResults;
}

export const RecommendedDiscretionaryCredit: React.FunctionComponent<Props> = ({
  loading,
  data,
}) => {
  if (loading) {
    return (
      <div className="RecommendedDiscretionaryCreditPendingSpinner">
        <PendingSpinner contentText="Calculating Recommend Discretionary Credit" />
      </div>
    );
  }

  if (!data || !data.recommendedDiscretionaryCredit) {
    return <></>;
  }

  const recommendedDetails = data.recommendedDiscretionaryCredit;

  return (
    <Table data-test="recommended-dc-details">
      <Table.Body>
        <Table.Row>
          <Table.Cell>Amount to cover average Daily Cost</Table.Cell>
          <Table.Cell>{formatMoney(recommendedDetails.dailyChargeCover)}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Amount to bring balance above £0</Table.Cell>
          <Table.Cell>{formatMoney(recommendedDetails.bringBalanceAboveZero)}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Days Covered</Table.Cell>
          <Table.Cell>{recommendedDetails.totalDaysCovered}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell positive>Total Recommended</Table.Cell>
          <Table.Cell positive>{formatMoney(recommendedDetails.total)}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};
