import * as React from 'react';
import { ExecutionResult, useMutation } from 'react-apollo';
import { ReadFuelType } from '../../../../__generated__/globalTypes';
import { PendingSpinner } from '../../../../components/pending-spinner';
import {
  triggerPrepaymentDataReadMutation as Results,
  triggerPrepaymentDataReadMutationVariables as Variables,
} from './__generated__/triggerPrepaymentDataReadMutation';
import { ClientStateContext } from '../../../../client/client-state-context';
import { useContext } from 'react';
import './read-balance.form.less';
import { triggerPrepaymentDataReadMutation } from './trigger-read-prepayment-data.gql';
import { PrimaryCTAButton } from '@ovotech/nebula';

interface Props {
  accountId: string;
  type: ReadFuelType;
  msn: string;
  mpxn: string;
  pendingRead: boolean;
}

export const ReadBalanceButton: React.SFC<Props> = ({
  accountId,
  msn,
  mpxn,
  type,
  pendingRead,
}) => {
  const [triggerPrepaymentDataRead, { loading }] = useMutation<Results, Variables>(
    triggerPrepaymentDataReadMutation,
    {
      refetchQueries: ['accountBalanceQuery'],
      awaitRefetchQueries: true,
    },
  );
  const operationInAction = pendingRead || loading;

  const { session } = useContext(ClientStateContext);

  return (
    <PrimaryCTAButton
      className="button"
      type="submit"
      data-test="read-balance-submit"
      onClick={(): Promise<ExecutionResult> =>
        triggerPrepaymentDataRead({
          variables: {
            input: {
              accountId,
              msn,
              mpxn,
              type,
              agentName: session?.user.email || '',
            },
          },
        })
      }
      disabled={operationInAction}
    >
      {operationInAction && <PendingSpinner contentText="Pending Request" disablePadding={true} />}
      Read All Balances
    </PrimaryCTAButton>
  );
};
