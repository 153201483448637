import { Currency } from './__generated__/globalTypes';

export interface Route {
  path: string;
}

export enum ErrorCode {
  NETWORK_ERROR = 'NETWORK_ERROR',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  UNAUTHORIZED = 'UNAUTHORIZED',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  BAD_USER_INPUT = 'BAD_USER_INPUT',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export interface ClientError {
  id: string;
  message: string;
  code: ErrorCode | string;
}

export interface FailedBatchRow {
  rowNumber: number;
  originalRowContents: string;
  errorDescriptions: string[];
}

export class BatchServiceError extends Error {
  data: FailedBatchRow[];

  constructor(message: string, data: FailedBatchRow[]) {
    super(message);
    this.data = data;
  }
}

export interface Money {
  value: number;
  currency: Currency;
}
