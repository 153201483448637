import { format } from 'date-fns';
import * as React from 'react';
import { Accordion, Form, Icon, Popup, Table } from 'semantic-ui-react';
import { accountBalanceQuery_account_balances_MeterBalance_adjustments } from '../../screens/account/account-balance/__generated__/accountBalanceQuery';
import { dateFormat, formatMeterAdjustmentStatus, formatMeterAdjustmentType } from '../../helpers';
import { accountMetersQuery_account_meters_adjustments } from '../../screens/account/account-meters/__generated__/accountMetersQuery';
import './transactions.less';

export type Adjustment =
  | accountMetersQuery_account_meters_adjustments
  | accountBalanceQuery_account_balances_MeterBalance_adjustments;

interface Props {
  adjustments: Adjustment[];
  heading: string;
}

export const Transactions: React.FunctionComponent<Props> = (props) => {
  const [showTransactions, setShowTransactions] = React.useState<boolean>(false);
  if (!props.adjustments || props.adjustments.length === 0) {
    return <div></div>;
  }

  return (
    <Form.Field>
      <div className="Transactions">
        <Accordion fluid>
          <Accordion.Title
            active={showTransactions}
            index={0}
            onClick={() => setShowTransactions(!showTransactions)}
          >
            <Icon name="dropdown" />
            {props.heading}
            <Popup
              trigger={<Icon className={'question circle icon PopupLeftMargin'} />}
              content={'Latest transactions for the meter'}
              position="top center"
            />
          </Accordion.Title>
          <Accordion.Content active={showTransactions}>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell content="Operation" />
                  <Table.HeaderCell className="NoWrap" content="Status" />
                  <Table.HeaderCell className="NoWrap" content="Triggered At" />
                  <Table.HeaderCell className="NoWrap" content="Completed At" />
                  <Table.HeaderCell className="NoWrap" content="Requesting Agent" />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {props.adjustments.map((adjustment, index) => {
                  const status = formatMeterAdjustmentStatus[adjustment.completionStatus];

                  const { errors } = adjustment;
                  const hasErrors = errors !== null && errors.length > 0;

                  return (
                    <React.Fragment key={index}>
                      <Table.Row>
                        <Table.Cell>
                          {formatMeterAdjustmentType[adjustment.type || ''] || 'Unknown Operation'}
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            color: status === 'Failure' ? 'red' : undefined,
                            minWidth: 100,
                          }}
                        >
                          {status}
                          {errors && hasErrors && (
                            <Popup
                              trigger={<Icon className={'question circle icon PopupLeftMargin'} />}
                              content={
                                <div>
                                  {errors.map((e, i) => (
                                    <div key={`${i}`} className="PopupDiv">
                                      <h5 className="ErrorMessageName">
                                        #{i + 1} {e.name}{' '}
                                      </h5>
                                      <div className="ErrorMessageDescription">
                                        {' '}
                                        {e.description}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              }
                              position="top center"
                            />
                          )}
                        </Table.Cell>
                        <Table.Cell>{format(adjustment.triggeredAt, dateFormat)}</Table.Cell>

                        <Table.Cell>
                          {adjustment.completedAt && format(adjustment.completedAt, dateFormat)}
                        </Table.Cell>
                        <Table.Cell>{adjustment.agentName ?? ''}</Table.Cell>
                      </Table.Row>
                    </React.Fragment>
                  );
                })}
              </Table.Body>
            </Table>
          </Accordion.Content>
        </Accordion>
      </div>
    </Form.Field>
  );
};
