import * as React from 'react';
import { Scope } from '../../__generated__/globalTypes';
import { Switch, Route } from 'react-router';
import { Container, Menu } from 'semantic-ui-react';
import { ScreenComponent } from '../../../src/components';
import { BoostPageHeader } from '../../../src/components/page-header/page-header';
import { PathNavLink } from '../../../src/components/path-nav-link';
import { batchAdjustment, batchAdjustmentSummaries } from '../../../src/routes';
import { pageTitle, BatchAdjustmentForm } from './batch-adjustment-form.view';
import { BatchAdjustmentList } from './batch-adjustment.view';

export const BatchAdjustmentScreen: React.FC = () => (
  <ScreenComponent title={pageTitle} requiredScope={Scope.BATCH_OPS}>
    <Container>
      <BoostPageHeader>{pageTitle}</BoostPageHeader>
      <Menu pointing secondary>
        <PathNavLink exact={true} to={batchAdjustment.path} data-test="batch-adjustment-form-tab">
          Form
        </PathNavLink>
        <PathNavLink
          exact={true}
          to={batchAdjustmentSummaries.path}
          data-test="batch-adjustment-summaries-tab"
        >
          Summaries
        </PathNavLink>
      </Menu>
      <Switch>
        <Route exact path={batchAdjustment.path} component={BatchAdjustmentForm} />
        <Route exact path={batchAdjustmentSummaries.path} component={BatchAdjustmentList} />
      </Switch>
    </Container>
  </ScreenComponent>
);
