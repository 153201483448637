import * as React from 'react';
import { useMutation } from 'react-apollo';
import * as Final from 'react-final-form';
import { Form, Modal } from 'semantic-ui-react';
import { validate } from 'validate.js';
import { CurrentMeterMode, MeterModeType } from '../../../../__generated__/globalTypes';
import { FieldComponent, PrimaryDisableButton } from '../../../../components';
import {
  adjustModeMutation as Results,
  adjustModeMutationVariables as Variables,
} from './__generated__/adjustModeMutation';
import { useContext } from 'react';
import { ClientStateContext } from '../../../../client/client-state-context';
import { adjustModeMutation } from './adjust-mode.form.gql';
import './index.less';
import { SecondaryCTAButton } from '@ovotech/nebula';

interface Props {
  accountId: string;
  currentMeterMode: CurrentMeterMode;
  msn: string;
  mpxn: string;
  onSuccess: () => void;
  onClose: () => void;
}

interface AdjustModeForm {
  cause: string;
  customCause: string;
}

const predefinedCauses = [
  'PAYG - correcting mode to Prepayment',
  'Cos Gained to wrong meter mode',
  'Erroneous transfer',
  'PAYM - PAYG',
  'PAYG - PAYM',
  'Other',
];

const causeConstraints = (): {
  presence: boolean;
  inclusion: string[];
} => ({
  presence: true,
  inclusion: predefinedCauses,
});

const customCauseConstraints = (): {
  format: {
    pattern: string;
    message: string;
  };
  presence: boolean;
} => ({
  format: {
    pattern: `(?!^ +$)^.{3,54}$`,
    message: 'should be between 3 and 54 characters',
  },
  presence: true,
});

export const AdjustModeForm: React.SFC<Props> = ({
  accountId,
  mpxn,
  currentMeterMode,
  msn,
  onSuccess,
  onClose,
}) => {
  const [adjustMode, { data, error, loading }] = useMutation<Results, Variables>(
    adjustModeMutation,
  );
  const [newMeterMode, setNewMeterMode] = React.useState<MeterModeType>();

  const isMeterModeCredit = currentMeterMode === CurrentMeterMode.CREDIT;
  const isMeterModePrepayment = currentMeterMode === CurrentMeterMode.PREPAYMENT;
  const isMeterModeUnknown = currentMeterMode === CurrentMeterMode.UNKNOWN;

  const { session } = useContext(ClientStateContext);

  return (
    <Final.Form
      onSubmit={async ({ cause, customCause }: AdjustModeForm) => {
        await adjustMode({
          variables: {
            input: {
              accountId,
              msn,
              mpxn,
              mode: newMeterMode as MeterModeType,
              cause: cause === 'Other' ? customCause : cause,
              agentName: session?.user.email || '',
            },
          },
        }).then(() => onSuccess());
      }}
      initialValues={{
        cause: '',
        customCause: '',
      }}
      validate={(values: AdjustModeForm) => {
        if (values.cause !== 'Other') {
          return validate(values, {
            cause: causeConstraints(),
          });
        }
        return validate(values, {
          cause: causeConstraints(),
          customCause: customCauseConstraints(),
        });
      }}
    >
      {({ form, handleSubmit, invalid }) => (
        <>
          <Modal.Header>Set Meter Payment Mode</Modal.Header>
          <Modal.Content>
            <Form
              id="adjust-mode-form"
              onSubmit={handleSubmit}
              loading={loading}
              success={!!data}
              error={!!error}
              data-test="adjust-mode-form"
            >
              <FieldComponent name="cause">
                <label>Reason for changing the mode</label>
                <Final.Field
                  name="cause"
                  className="ui selection dropdown CauseInputDropdown"
                  component="select"
                  data-test="change-mode-cause"
                >
                  <option value={''} />
                  {predefinedCauses.map((cause) => (
                    <option key={cause} value={cause}>
                      {cause}
                    </option>
                  ))}
                </Final.Field>
              </FieldComponent>
              {form.getState().values.cause === 'Other' && (
                <FieldComponent name="customCause">
                  <Final.Field
                    name="customCause"
                    className="CauseInputTextArea"
                    component="input"
                    placeholder="Please provide a reason"
                    data-test="change-mode-custom-cause"
                    autoComplete="off"
                  />
                </FieldComponent>
              )}
              <div id="change-mode-form" data-test="change-mode-form">
                <p>You should only change the mode to the opposite mode the meter is currently</p>
                <p>
                  <strong>This will zero any credit, so make sure its recorded.</strong>
                </p>
              </div>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <SecondaryCTAButton
              className="button"
              data-test="change-mode-close"
              onClick={() => onClose()}
            >
              Close
            </SecondaryCTAButton>
            {(isMeterModePrepayment || isMeterModeUnknown) && (
              <PrimaryDisableButton
                dataTestId="change-mode-to-credit"
                form="adjust-mode-form"
                onClick={() => setNewMeterMode(MeterModeType.Credit)}
                disabled={invalid}
                buttonText="Change Mode PAYM (Credit)"
              />
            )}
            {(isMeterModeCredit || isMeterModeUnknown) && (
              <PrimaryDisableButton
                dataTestId="change-mode-to-prepayment"
                form="adjust-mode-form"
                onClick={() => setNewMeterMode(MeterModeType.Prepayment)}
                disabled={invalid}
                buttonText="Change Mode PAYG Standard (Prepayment)"
              />
            )}
          </Modal.Actions>
        </>
      )}
    </Final.Form>
  );
};
