import * as React from 'react';
import { Icon, Message } from 'semantic-ui-react';
import './ModalForm.less';
import { RequestMeterInfo } from 'src/__generated__/globalTypes';
import { jobDetailsQuery_job as JobDetails } from '../../forms/add-discretionary-credit/__generated__/jobDetailsQuery';
import { ElectricityIcon } from '../../../../../src/components/electricity-icon';
import { GasIcon } from '../../../../../src/components/gas-icon';
import { addDiscretionaryCreditV3Mutation_addDiscretionaryCreditV3 as DCResults } from '../../forms/add-discretionary-credit/__generated__/addDiscretionaryCreditV3Mutation';
import { PendingSpinner } from '../../../../components/pending-spinner';
interface Props {
  meterRequests?: RequestMeterInfo[];
  jobDetails?: JobDetails[];
  utrnSelection?: string;
}

export const MeterRequestStatus: React.FunctionComponent<Props> = ({
  meterRequests,
  jobDetails,
  utrnSelection,
}) => {
  return (
    <div className="BasicCard">
      <Message positive visible={true} warning={false} attached="top">
        <Icon className="check circle" />
        BOL is processing the request
      </Message>
      <div className="BottomBorder">
        <table>
          {meterRequests && (
            <>
              {meterRequests.map((meterRequest, index) => {
                const detailsJob = jobDetails?.find((job) => job.msn === meterRequest.msn);
                return (
                  <tr key={index}>
                    <td>
                      {meterRequest.fuel === 'ELECTRICITY' && <ElectricityIcon />}
                      {meterRequest.fuel === 'GAS' && <GasIcon />}
                    </td>
                    <td>{meterRequest.fuel}</td>
                    <td>MSN:</td>
                    <td>{meterRequest.msn}</td>
                    <td> | </td>
                    <td>UTRN:</td>
                    {detailsJob?.state === 'PENDING' && !detailsJob?.utrn && (
                      <>
                        <td>
                          <div className="PendingWraper">
                            Pending
                            <PendingSpinner contentText="Pending Request" disablePadding={true} />
                          </div>
                        </td>
                        <td></td>
                      </>
                    )}
                    {!!detailsJob?.errors?.length && detailsJob?.state === 'FAILURE' && (
                      <>
                        <td>
                          {detailsJob?.utrn} {detailsJob?.errors[0]?.message}
                        </td>
                        <td></td>
                      </>
                    )}

                    {(detailsJob?.state === 'SUCCESS' || detailsJob?.state === 'PENDING') &&
                      !!detailsJob?.utrn && (
                        <>
                          <td>{detailsJob?.utrn}</td>
                          <td>
                            <button
                              className="Copy"
                              onClick={(event) => {
                                event.preventDefault();
                                void navigator?.clipboard?.writeText(detailsJob?.utrn || '');
                              }}
                            >
                              Copy
                            </button>
                          </td>
                        </>
                      )}
                  </tr>
                );
              })}
            </>
          )}
        </table>
        <ul style={{ margin: '2rem' }}>
          <li>The request has been successfully sent to BOL.</li>
          <li>The request may show as pending to the meter.</li>
          <li>
            A wrap up note will be created in Salesforce and comms will be sent to the customer.
          </li>
        </ul>
      </div>
      <div style={{ padding: '1rem 2rem' }}>
        <header>
          {' '}
          <b>Advice for the customer </b>
        </header>
        <ul>
          {utrnSelection === 'yes' && (
            <li>
              A text message will be sent to confirm the status of the additional support credit.
            </li>
          )}
          <li>In future the customer can use webchat to apply for additional support credit.</li>
        </ul>
        If the additional support credit is successful:
        <ul>
          <li>It can take up to 40 minutes for credit to be seen on the meter.</li>
          {utrnSelection === 'yes' && <li>A UTRN code will be provided in the text message.</li>}
          <li>A debt repayment agreement letter or email will be sent.</li>
        </ul>
      </div>
    </div>
  );
};

export const MeterFailedRequestStatus: React.FunctionComponent<Props> = ({ meterRequests }) => {
  return (
    <div className="BasicCard">
      <Message negative visible={true} warning={false} attached="top">
        <Icon className="attention" /> BOL has failed to process the request
      </Message>
      <div className="BottomBorder">
        <table>
          {meterRequests && (
            <>
              {meterRequests.map((meterRequest, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {meterRequest.fuel === 'ELECTRICITY' && <ElectricityIcon />}
                      {meterRequest.fuel === 'GAS' && <GasIcon />}
                    </td>
                    <td>{meterRequest.fuel}</td>
                    <td>MSN:</td>
                    <td>{meterRequest.msn}</td>
                  </tr>
                );
              })}
            </>
          )}
        </table>
        <div style={{ margin: '0 2rem' }}>
          <p>
            We have attempted to send your application to BOL twice. The application could not be
            processed. One of our systems may not be working correctly, this may be resolved within
            a few hours.
          </p>
          <br />
          <b> Next steps: </b>
        </div>
        <ul style={{ margin: '2rem 0rem' }}>
          <li>
            Please check BOL is working correctly before attempting to apply additional support
            credit manually.{' '}
          </li>
          <li>
            Advice the customer that they can use webchat to request additional support credit in a
            few hours / in the future.
          </li>
        </ul>
      </div>
    </div>
  );
};

interface PageProps {
  meterRequests?: RequestMeterInfo[];
  jobDetails?: JobDetails[];
  dcResults: DCResults[];
  /* only to be used when the balance service returns a blanket success or failure and not when it starts returning individual status per meter   */
  allPassed?: boolean;
  utrnSelection: string;
}

const ResultsPage: React.FunctionComponent<PageProps> = ({
  meterRequests,
  jobDetails,
  allPassed,
  dcResults,
  utrnSelection,
}) => {
  const successfulMeterRequest = meterRequests?.filter((meter) =>
    dcResults?.find((dc) => dc.msn === meter.msn && !dc.error),
  );

  const failedMeterRequest = meterRequests?.filter((meter) =>
    dcResults?.find((dc) => dc.msn === meter.msn && !!dc.error),
  );

  return (
    <div>
      <p>
        <b>Status:</b>&nbsp;request has been sent to BOL
      </p>

      {allPassed && !!successfulMeterRequest?.length && (
        <MeterRequestStatus
          meterRequests={successfulMeterRequest}
          jobDetails={jobDetails}
          utrnSelection={utrnSelection}
        />
      )}
      {(!allPassed || !!failedMeterRequest?.length) && (
        <MeterFailedRequestStatus meterRequests={failedMeterRequest} />
      )}
    </div>
  );
};

export default ResultsPage;
