import * as React from 'react';
import { useQuery } from 'react-apollo';
import { format } from 'date-fns';
import { Popup, Table } from 'semantic-ui-react';
import { truncate } from '../../helpers';
import { batchAdjustmentSummariesQuery } from './batch-adjustment.view.gql';
import './batch-adjustment.view.less';
import {
  batchAdjustmentSummariesQuery as Results,
  batchAdjustmentSummariesQueryVariables as Variables,
} from './__generated__/batchAdjustmentSummariesQuery';
import { Link } from 'react-router-dom';

const dateFormat = 'YYYY-MM-DD';

interface BatchAdjustmentSummary {
  __typename: 'BatchAdjustmentSummary';
  batchId: number;
  submissionDate: any;
  agentName: string;
  context: string;
}

export const BatchAdjustmentList: React.FC = () => {
  const { data } = useQuery<Results, Variables>(batchAdjustmentSummariesQuery, {
    variables: {
      input: {
        page: 1,
        perPage: 1000,
      },
    },
  });

  if (!data || !data.batchAdjustmentSummaries) {
    return <></>;
  }
  return (
    <Table data-test="batch-adjustment-summaries-table">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Batch ID</Table.HeaderCell>
          <Table.HeaderCell>Agent Name</Table.HeaderCell>
          <Table.HeaderCell>Submission Date</Table.HeaderCell>
          <Table.HeaderCell>Reason</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data.batchAdjustmentSummaries.batchAdjustmentSummaries.map(
          (summary: BatchAdjustmentSummary) => (
            <Table.Row key={summary.batchId}>
              <Table.Cell>{summary.batchId}</Table.Cell>
              <Table.Cell>{summary.agentName}</Table.Cell>
              <Table.Cell>{format(summary.submissionDate, dateFormat)}</Table.Cell>
              <Table.Cell>
                {summary.context && (
                  <Popup
                    content={summary.context}
                    trigger={<span> {truncate(summary.context)} </span>}
                  />
                )}
              </Table.Cell>
              <Table.Cell>
                <Link
                  to={`/batch-adjustment-details/${summary.batchId}`}
                  data-test="batch-adjustment-summaries-info"
                >
                  More Info
                </Link>
              </Table.Cell>
            </Table.Row>
          ),
        )}
      </Table.Body>
    </Table>
  );
};
