import { get } from '@ovotech/typesafe-get';
import * as React from 'react';
import { Query } from 'react-apollo';
import { RouteComponentProps } from 'react-router';
import { Dimmer, Loader, List } from 'semantic-ui-react';
import { MeterBalance } from '../../../../src/components/meter-balance';
import { UnknownBalanceAmount } from '../../../../src/components/unknown-balance-amount';
import { BalanceType, DebtType } from '../../../__generated__/globalTypes';
import { sortBalances } from '../../../helpers';
import { PrimaryCTAButton } from '@ovotech/nebula';
import { ElectricityIcon } from '../../../../src/components/electricity-icon';
import { GasIcon } from '../../../../src/components/gas-icon';
import { Modal } from 'semantic-ui-react';
import {
  accountBalanceQuery as Results,
  accountBalanceQueryVariables as Variables,
  accountBalanceQuery_account_emergencyCreditBalances,
  accountBalanceQuery_account_customerDetails,
} from './__generated__/accountBalanceQuery';
import { useState } from 'react';
import { accountBalanceQuery } from './account-balance.view.gql';
import './account-balance.view.less';
import { ModalForm } from './DC-Wizard/ModalForm';
interface Props extends Pick<RouteComponentProps<{ accountNumber: string }>, 'match'> {
  clockTimestamp?: number;
}

export interface AdjustAmountModal {
  type: BalanceType | DebtType;
  msn?: string;
  mpxn?: string;
}

interface UnknownEvent {
  __typename: string;
}
export const AccountBalanceView: React.FunctionComponent<Props> = ({ match }) => (
  <Query<Results, Variables>
    query={accountBalanceQuery}
    variables={{ accountId: match.params.accountNumber }}
    pollInterval={30000}
  >
    {({ data, loading }) => {
      const balances = (get(data, 'account', 'balances') || []).slice();
      const debts = get(data, 'account', 'debts') || [];
      const emergencyCreditBalances = get(data, 'account', 'emergencyCreditBalances') || [];

      const getMeterDebt = (msn: string) =>
        debts.find((debt) => debt.__typename === 'MeterDebt' && debt.msn === msn);

      const getMeterEmergencyCredit = (msn: string) =>
        emergencyCreditBalances.find(
          (emergencyCreditBalance) =>
            emergencyCreditBalance.__typename === 'MeterEmergencyCreditBalance' &&
            emergencyCreditBalance.msn === msn,
        );

      const customerDetails = get(data, 'account', 'customerDetails') || null;
      const [getStarted, setgetStarted] = useState<boolean>(false);
      return (
        <Dimmer.Dimmable data-test="account-balance-view" className="AccountBalanceView">
          <Dimmer inverted active={loading}>
            <Loader />
          </Dimmer>
          <div className="AccountBalanceViewBalancesContainer">
            {balances.sort(sortBalances).map((balance, index) => {
              const meterDebt = getMeterDebt(balance.msn);
              const meterEmergencyCredit = getMeterEmergencyCredit(balance.msn);
              if (balance.__typename === 'UnknownMeterBalance') {
                return (
                  <UnknownBalanceAmount
                    key={index}
                    accountId={match.params.accountNumber}
                    balance={balance}
                  />
                );
              }
              if (balance.__typename === 'MeterBalance') {
                return (
                  <MeterBalance
                    key={index}
                    accountId={match.params.accountNumber}
                    balance={balance}
                    debt={meterDebt}
                    emergencyCredit={
                      meterEmergencyCredit as accountBalanceQuery_account_emergencyCreditBalances
                    }
                    customerDetails={customerDetails as accountBalanceQuery_account_customerDetails}
                  />
                );
              }
              throw new Error(`Unknown Balance Type ${(balance as UnknownEvent).__typename}`);
            })}
          </div>
          {!loading && (
            <div className="Card">
              <ElectricityIcon />
              <GasIcon />
              &nbsp;
              <span className="CardHeader">Additional Support Credit Wizard</span>
              <div className="CardBody">
                <p>Remember to confirm and record:</p>
                <List bulleted className="Lists">
                  <List.Item>Electrical Dependencies</List.Item>
                  <List.Item>Supply critical</List.Item>
                </List>
              </div>
              <br />
              <PrimaryCTAButton
                className="button"
                data-test="get-started"
                onClick={() => {
                  setgetStarted(true);
                }}
              >
                Get Started
              </PrimaryCTAButton>
            </div>
          )}
          {getStarted ? (
            <Modal
              open={true}
              onClose={() => setgetStarted(false)}
              size="small"
              data-test="add-discretionary-credit-modal"
            >
              <ModalForm
                balances={balances}
                debts={debts}
                accountId={match.params.accountNumber}
                onClose={() => setgetStarted(false)}
                customerVulnerabilities={customerDetails?.vulnerabilities}
              />
            </Modal>
          ) : null}
        </Dimmer.Dimmable>
      );
    }}
  </Query>
);
