import { get } from '@ovotech/typesafe-get';
import * as queryString from 'query-string';
import * as React from 'react';
import { Query } from 'react-apollo';
import { RouteComponentProps } from 'react-router';
import { Container, Message, Segment } from 'semantic-ui-react';
import { Scope } from '../../__generated__/globalTypes';
import { ScreenComponent } from '../../components';
import { LoadingComponent } from '../../components';
import { BoostPageHeader } from '../../components/page-header/page-header';
import {
  accountSearchQuery_account as Account,
  accountSearchQuery as Results,
  accountSearchQueryVariables as Variables,
} from './__generated__/accountSearchQuery';
import { accountSearchQuery } from './account-search.gql';
import { AccountsTable } from './accounts-table';
import { SearchForm } from './search-form';
import { SearchQuery } from './types';

type Props = Pick<RouteComponentProps<any>, 'location' | 'history'>;

export const AccountSearchScreen: React.SFC<Props> = ({ location, history }) => {
  const query = (queryString.parse(location.search) as unknown) as SearchQuery;
  const hasQuery = !!query.accountId;

  return (
    <ScreenComponent title="Account Search" requiredScope={Scope.ACCOUNT}>
      <Container>
        <BoostPageHeader>Account Search</BoostPageHeader>

        <Query<Results, Variables> query={accountSearchQuery} variables={query} skip={!hasQuery}>
          {({ data, loading: queryLoading }) => {
            const accounts = hasQuery
              ? get(data, 'account')
                ? [get(data, 'account') as Account]
                : []
              : undefined;
            const loading = hasQuery && queryLoading;

            return (
              <>
                <Segment>
                  <SearchForm
                    initialValues={query}
                    required={true}
                    onSubmit={(values: SearchQuery) =>
                      history.push('?' + queryString.stringify(values))
                    }
                    loading={loading}
                  />
                </Segment>

                <LoadingComponent loading={loading} />
                {!loading ? (
                  <div data-test="search-results">
                    {accounts && !accounts.length ? (
                      <Message negative>
                        <Message.Header>Account not found</Message.Header>
                        <p>
                          Could not find account. Please make sure that the account ID that you
                          provided is correct.
                        </p>
                      </Message>
                    ) : null}

                    <AccountsTable accounts={accounts} />
                  </div>
                ) : null}
              </>
            );
          }}
        </Query>
      </Container>
    </ScreenComponent>
  );
};
