import * as React from 'react';
import { Message } from 'semantic-ui-react';

interface Props {
  isServiceAvailable: boolean;
  isBatchServiceAvailable: boolean;
}

const MessageWrapper: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => (
  <Message warning>
    <Message.Header>Temporarily unavailable</Message.Header>
    <Message.Content>{children}</Message.Content>
  </Message>
);

export const UnavailableServiceContents: React.FunctionComponent = () => (
  <MessageWrapper>
    <p>All requests sent via the Boost Portal are currently failing / timing out.</p>
    <p>This affects all types of customer accounts.</p>
    <ul>
      <li>
        For S2 queries - first line agents should escalate to Second Line so they use the BOL UI
        instead.
      </li>
      <li>For PAYG standard S1 - please use SFE instead</li>
    </ul>
  </MessageWrapper>
);

export const UnavailableBatchServiceContents: React.FunctionComponent = () => (
  <MessageWrapper>
    <p>
      All requests sent via the Boost Portal to the Batch Service are currently failing / timing
      out.
    </p>
    <p>This affects all types of customer accounts.</p>
    <p>Please upload single account requests until service is restored</p>
  </MessageWrapper>
);

export const PortalUnavailableBanner: React.FunctionComponent<Props> = ({
  isServiceAvailable,
  isBatchServiceAvailable,
}) => {
  if (isServiceAvailable === false) {
    return <UnavailableServiceContents />;
  } else if (isBatchServiceAvailable === false) {
    return <UnavailableBatchServiceContents />;
  }

  return <></>;
};

PortalUnavailableBanner.displayName = 'PortalUnavailableBanner';
