import * as React from 'react';
import { Scope } from '../../__generated__/globalTypes';
import { ScreenComponent } from '../../components';

export const HomeScreen: React.SFC = () => (
  <ScreenComponent title="Home" requiredScope={Scope.ACCOUNT}>
    <h1 className="h2 my-5 hidden-xs">Home</h1>
    <p>Welcome to the PAYG Portal</p>
  </ScreenComponent>
);
