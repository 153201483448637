import { PrimaryCTAButton, SecondaryCTAButton } from '@ovotech/nebula';
import * as React from 'react';
import { Modal } from 'semantic-ui-react';
import './index.less';

interface Props {
  headerTitle: string;
  submitButtonTitle: string;
  submitDisabled: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const PortalModal: React.FunctionComponent<Props> = ({
  submitDisabled,
  headerTitle,
  submitButtonTitle,
  onClose,
  onSubmit,
  children,
}) => (
  <>
    <Modal.Header>{headerTitle}</Modal.Header>
    <Modal.Content>{children}</Modal.Content>
    <Modal.Actions>
      <SecondaryCTAButton
        className="button"
        data-test="portal-modal-close"
        onClick={() => onClose()}
      >
        Close
      </SecondaryCTAButton>
      <PrimaryCTAButton
        className="button"
        data-test="portal-modal-submit"
        onClick={() => onSubmit()}
        disabled={submitDisabled}
      >
        {submitButtonTitle}
      </PrimaryCTAButton>
    </Modal.Actions>
  </>
);
