import * as React from 'react';
import { useMutation } from 'react-apollo';
import { ReadFuelType } from '../../../../__generated__/globalTypes';
import { PendingSpinner } from '../../../../components/pending-spinner';
import {
  triggerSupplyStateReadMutation as Results,
  triggerSupplyStateReadMutationVariables as Variables,
} from './__generated__/triggerSupplyStateReadMutation';
import { triggerSupplyStateReadMutation } from './read-supply-state.form.gql';
import './read-supply-state.form.less';
import { useContext } from 'react';
import { ClientStateContext } from '../../../../client/client-state-context';
import { PrimaryCTAButton } from '@ovotech/nebula';

interface Props {
  type: ReadFuelType;
  accountId: string;
  msn: string;
  mpxn: string;
  pendingRead: boolean;
}

export const ReadSupplyStateButton: React.SFC<Props> = ({
  accountId,
  msn,
  mpxn,
  type,
  pendingRead,
}) => {
  const [readSupplyState, { loading }] = useMutation<Results, Variables>(
    triggerSupplyStateReadMutation,
    {
      refetchQueries: ['accountMetersQuery'],
      awaitRefetchQueries: true,
    },
  );
  const operationInAction = pendingRead || loading;
  const { session } = useContext(ClientStateContext);

  return (
    <PrimaryCTAButton
      className="button"
      onClick={() =>
        readSupplyState({
          variables: {
            input: {
              accountId,
              msn,
              mpxn,
              type,
              agentName: session?.user.email || '',
            },
          },
        })
      }
      disabled={operationInAction}
    >
      {operationInAction && <PendingSpinner contentText="Pending Request" disablePadding={true} />}
      Read Supply State
    </PrimaryCTAButton>
  );
};
