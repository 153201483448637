import { get } from '@ovotech/typesafe-get';
import * as Sentry from '@sentry/browser';
import { ApolloLink, Observable } from 'apollo-link';
import { GraphQLError } from 'graphql';
import { print } from 'graphql/language/printer';
import { ClientError, ErrorCode } from '../types';
import { State } from './client-state-context';

export const formatGraphQLError = (id: string, error: GraphQLError): ClientError => ({
  id,
  message: error.message,
  code: get(error, 'extensions', 'code') || ErrorCode.UNKNOWN_ERROR,
});

export const formatNetworkError = (id: string, error: Error): ClientError => ({
  id,
  message: error.message,
  code: ErrorCode.NETWORK_ERROR,
});

export class ErrorsLink extends ApolloLink {
  constructor(public clientState: State) {
    super((operation, forward) => {
      const { errors, setErrors } = this.clientState;
      const id = print(operation.query);
      const cleanedErrors = errors
        .filter((error) => error.id !== id)
        .filter((error) => error.code !== ErrorCode.NETWORK_ERROR);

      return new Observable((observer) => {
        forward(operation).subscribe({
          next: (result) => {
            observer.next(result);
            const gqlErrors = result.errors || [];
            gqlErrors.forEach((error: GraphQLError) => Sentry.captureException(error));
            const newErrors = gqlErrors.map((error: GraphQLError) => formatGraphQLError(id, error));
            setErrors([...cleanedErrors, ...newErrors]);
          },
          error: (error) => {
            Sentry.captureException(error);
            setErrors([...cleanedErrors, formatNetworkError(id, error)]);
            observer.error(error);
          },
          complete: () => observer.complete(),
        });
      });
    });
  }

  setClientState(clientState: State): void {
    this.clientState = clientState;
  }
}
