import { get } from '@ovotech/typesafe-get';
import { groupBy } from 'lodash/fp';
import * as React from 'react';
import { Query } from 'react-apollo';
import { RouteComponentProps } from 'react-router';
import { Dimmer, Loader } from 'semantic-ui-react';
import { NonTradMeter } from '../../../components/meters/non-trad-meter';
import { TradMeter } from '../../../components/meters/trad-meter';

import {
  accountMetersQuery as Results,
  accountMetersQuery_account_meters as Meter,
  accountMetersQueryVariables as Variables,
} from './__generated__/accountMetersQuery';
import { accountScreenQuery_panReconciliationStatus_reconciliations as Reconciliation } from '../__generated__/accountScreenQuery';
import { ConsumerStatus, MeterType } from '../../../__generated__/globalTypes';
import './account-meter.view.less';
import { accountMetersQuery } from './account-meters.view.gql';

export const orderMetersForDisplay = (meters: Meter[]) => {
  const groupedMeters = groupBy((item) => item.consumerStatus, meters);
  return [
    ...(groupedMeters[ConsumerStatus.CURRENT] || []),
    ...(groupedMeters[ConsumerStatus.PENDING] || []),
    ...(groupedMeters[ConsumerStatus.FUTURE] || []),
    ...(groupedMeters[ConsumerStatus.FINAL] || []),
  ];
};

interface Props extends Pick<RouteComponentProps<{ accountNumber: string }>, 'match'> {
  clockTimestamp?: Date;
  panReconciliations: Reconciliation[] | null | undefined;
}

export const AccountMeterView: React.FunctionComponent<Props> = ({
  match,
  clockTimestamp,
  panReconciliations,
}) => (
  <Query<Results, Variables>
    query={accountMetersQuery}
    variables={{ accountId: match.params.accountNumber }}
    pollInterval={30000}
  >
    {({ data, loading, refetch }) => {
      const meters = get(data, 'account', 'meters') || [];
      const orderedMeters = orderMetersForDisplay(meters);

      return (
        <Dimmer.Dimmable data-test="account-meters-view">
          <Dimmer inverted active={loading}>
            <Loader />
          </Dimmer>
          <div className="AccountMeters">
            {orderedMeters.map((meter: Meter) => {
              const panInfo = panReconciliations?.reduce(
                (result, { panDetails, type }) => [
                  ...result,
                  ...(type.toUpperCase() == meter.fuelType.toUpperCase() ? [panDetails] : []),
                ],
                [],
              );
              if ([MeterType.S2, MeterType.S1E].includes(meter.meterType)) {
                return (
                  <NonTradMeter
                    key={meter.msn}
                    meter={meter}
                    accountId={match.params.accountNumber}
                    onSuccessfulOperation={refetch}
                    now={clockTimestamp}
                    panReconciliations={panInfo?.flat()}
                  />
                );
              } else {
                return <TradMeter key={meter.msn} meter={meter} />;
              }
            })}
          </div>
        </Dimmer.Dimmable>
      );
    }}
  </Query>
);
