import {
  determineMeterType,
  determineValidMeterFuelType,
  METER_FUEL_TYPES,
  validateSupplyPointOne,
  validateSupplyPointOneMeter,
  validateSupplyPointTwo,
} from '../../helpers';
import {
  ConsumerStatus,
  MeterFuelType,
  SmetsPANMeterVersion,
} from '../../__generated__/globalTypes';
import { requestBoostIssuePansMutationVariables as Variables } from './__generated__/requestBoostIssuePansMutation';
import axios from 'axios';
import accountDetailsQuery from '../account/account-details/account-details.view.gql';
import { print } from 'graphql/language/printer';
import accountMetersQuery from '../account/account-meters/account-meters.view.gql';
import { accountMetersQuery_account_meters as Meter } from '../../screens/account/account-meters/__generated__/accountMetersQuery';
import { accountDetailsQuery_account_customerDetails as CustomerDetails } from '../account/account-details/__generated__/accountDetailsQuery';
import { FormApi } from 'final-form';
import { AxiosResponse } from 'axios';
import { SearchQuery } from '../account-search/types';

export interface BoostIssuePanFormValues {
  firstName: string;
  lastName: string;
  city: string;
  postcode: string;
  addressLineOne: string;
  addressLineTwo?: string;
  addressLineThree?: string;
  addressLineFour?: string;
  supplyPointOne?: string;
  supplyPointTwo?: string;
  supplyPointOneType?: string;
  supplyPointTwoType?: string;
  supplyPointOnePan?: string;
  supplyPointTwoPan?: string;
  requestSupplyPointOnePan?: boolean;
  requestSupplyPointTwoPan?: boolean;
  requestSupplyPointOne?: boolean;
  requestSupplyPointTwo?: boolean;
  sendByEmail: boolean;
  sendByPost: boolean;
  email?: string;
}

export type BoostIssuePanFormCompareValues = Partial<BoostIssuePanFormValues>;

export const shapeForMpxnDefinition = (
  input: BoostIssuePanFormValues,
  meterType: METER_FUEL_TYPES,
): {
  number: string | undefined;
  meterType: string | undefined;
  pan: string | undefined | null;
}[] => {
  if (determineMeterType(input.supplyPointOne) === meterType) {
    return [
      {
        number: input.requestSupplyPointOne ? input.supplyPointOne : undefined,
        meterType: input.requestSupplyPointOne ? input.supplyPointOneType : undefined,
        pan: input.requestSupplyPointOne
          ? input.requestSupplyPointOnePan
            ? null
            : input.supplyPointOnePan
          : null,
      },
      {
        number: input.requestSupplyPointTwo ? input.supplyPointTwo : undefined,
        meterType: input.requestSupplyPointTwo ? input.supplyPointTwoType : undefined,
        pan: input.requestSupplyPointTwo
          ? input.requestSupplyPointTwoPan
            ? null
            : input.supplyPointTwoPan
          : null,
      },
    ];
  }

  return [
    {
      number: input.requestSupplyPointTwo ? input.supplyPointTwo : undefined,
      meterType: input.requestSupplyPointTwo ? input.supplyPointTwoType : undefined,
      pan: input.requestSupplyPointTwo
        ? input.requestSupplyPointTwoPan
          ? null
          : input.supplyPointTwoPan
        : null,
    },
    {
      number: input.requestSupplyPointOne ? input.supplyPointOne : undefined,
      meterType: input.requestSupplyPointOne ? input.supplyPointOneType : undefined,
      pan: input.requestSupplyPointOne
        ? input.requestSupplyPointOnePan
          ? null
          : input.supplyPointOnePan
        : null,
    },
  ];
};

export const shapeForSubmission = (values: BoostIssuePanFormValues): Variables => {
  const [mpan, mprn] = shapeForMpxnDefinition(values, METER_FUEL_TYPES.ELECTRICITY);

  return {
    input: {
      name: {
        full: `${values.firstName} ${values.lastName}`,
      },
      address: {
        lines: {
          line1: values.addressLineOne,
          line2: values.addressLineTwo,
          line3: values.addressLineThree,
          line4: values.addressLineFour,
        },
        city: values.city,
        postCode: values.postcode,
      },
      ...(values.email && {
        email: values.email,
      }),
      deliveryOptions: {
        sendByEmail: values.sendByEmail ? true : false,
        sendByPost: values.sendByPost ? true : false,
      },
      supplyPointDetails: {
        electricity: mpan.number
          ? {
              mpan: mpan.number,
              meterType:
                mpan.meterType == 'SMETS1'
                  ? SmetsPANMeterVersion.Smets1
                  : SmetsPANMeterVersion.Smets2,
              pan: mpan.pan,
            }
          : undefined,
        gas: mprn.number
          ? {
              mprn: mprn.number,
              meterType:
                mprn.meterType == 'SMETS1'
                  ? SmetsPANMeterVersion.Smets1
                  : SmetsPANMeterVersion.Smets2,
              pan: mprn.pan,
            }
          : undefined,
      },
    },
  };
};

const statusPriority = ['FUTURE', 'FINAL', 'PENDING', 'CURRENT'];

export const getMeterWithMostRelavantStatus = (meters: Meter[]) => {
  let relevantMeter: Meter | undefined;
  for (const meter of meters) {
    if (meter.consumerStatus === ConsumerStatus.DELETED) {
      continue;
    } else if (
      !relevantMeter ||
      statusPriority.indexOf(meter.consumerStatus) >
        statusPriority.indexOf(relevantMeter.consumerStatus)
    ) {
      relevantMeter = meter;
    }
  }
  return relevantMeter;
};

export const getValidMeters = (meters: Meter[]): Meter[] => {
  if (!meters || meters.length === 0) return meters;
  const gasMeters = meters.filter(({ fuelType }) => fuelType === MeterFuelType.GAS);
  const gasMeter = getMeterWithMostRelavantStatus(gasMeters);

  const electricMeters = meters.filter(({ fuelType }) => fuelType === MeterFuelType.ELECTRICITY);
  const electricMeter = getMeterWithMostRelavantStatus(electricMeters);

  const result = [];
  if (electricMeter) result.push(electricMeter);
  if (gasMeter) result.push(gasMeter);
  return result;
};

export const shapeForFormComparison = (
  customerDetails: CustomerDetails,
  meters: Meter[],
): BoostIssuePanFormCompareValues => {
  let meterDetails;
  const { firstName, lastName, postcode, city, address, email } = customerDetails;
  const addressLines = address.split(', ');
  const accountDetails = {
    firstName,
    lastName,
    postcode,
    city,
    addressLineOne: addressLines[0],
    addressLineTwo: addressLines[1],
    addressLineThree: addressLines[2],
    addressLineFour: addressLines[3],
    email,
  };
  if (meters && meters.length > 0) {
    meterDetails = {
      supplyPointOne: meters[0]?.mpxn,
      supplyPointOneType: meters[0]?.capabilities
        ? meters[0]?.capabilities?.isSmets2
          ? 'Smets2'
          : 'Smets1'
        : undefined,
      supplyPointOnePan: meters[0]?.pan,
      supplyPointTwo: meters[1]?.mpxn,
      supplyPointTwoType: meters[1]?.capabilities
        ? meters[1]?.capabilities?.isSmets2
          ? 'Smets2'
          : 'Smets1'
        : undefined,
      supplyPointTwoPan: meters[1]?.pan,
    };
  }

  return { ...accountDetails, ...meterDetails };
};

export const getAuthorizationHeaders = (jwt: string): Record<string, string> => {
  return { Authorization: `Bearer ${jwt}` };
};

export const fetchAccountDetails = (
  searchQuery: SearchQuery,
  jwt: string,
): Promise<AxiosResponse> => {
  const authHeader = getAuthorizationHeaders(jwt);
  return axios.post(
    process.env.REACT_APP_BIT_PORTAL_API || '',
    {
      operationName: 'accountDetailsQuery',
      variables: searchQuery,
      query: print(accountDetailsQuery),
    },
    { headers: authHeader },
  );
};

export const fetchMeterDetails = (
  searchQuery: SearchQuery,
  jwt: string,
): Promise<AxiosResponse> => {
  const authHeader = getAuthorizationHeaders(jwt);
  return axios.post(
    process.env.REACT_APP_BIT_PORTAL_API || '',
    {
      operationName: 'accountMetersQuery',
      variables: searchQuery,
      query: print(accountMetersQuery),
    },
    { headers: authHeader },
  );
};

export const boostIssuePansConstraints = {
  firstName: { presence: { message: 'should not be blank' } },
  lastName: { presence: { message: 'should not be blank' } },
  addressLineOne: { presence: { message: 'should not be blank' } },
  city: { presence: { message: 'should not be blank' } },
};

export const fillFormDetails = (
  shapedAccountDetails: BoostIssuePanFormCompareValues,
  form: FormApi<any>,
): void => {
  for (const [key, value] of Object.entries(shapedAccountDetails)) {
    if (value && value !== form.getState().values[key]) {
      form.mutators.setValue(key, value);
    }
  }
};

export const validateSingleSupplyPointPan = (
  supplyPointPan?: string,
  requestSupplyPointPan?: boolean,
): string => {
  if (!requestSupplyPointPan) {
    if (!supplyPointPan) return 'Please enter a Pan number';
    if (supplyPointPan.length < 19) return 'Pan is invalid';
  }
  return '';
};

export const validateSupplyPointRequestBoxes = (
  requestPanSupplyPointOne?: boolean,
  requestPanSupplyPointTwo?: boolean,
): string => {
  if (!requestPanSupplyPointOne && !requestPanSupplyPointTwo)
    return 'Please select at least one meter';
  return '';
};

export const validateSingleMeterReissue = (
  name: string,
  supplyPoint?: string,
  supplyPointType?: string,
  supplyPointPan?: string,
  requestSupplyPointPan?: boolean,
): Record<string, string[]> => {
  const supplyOneMeterFuelType = determineValidMeterFuelType(supplyPoint);
  const invalidSupplyPointMeterValidationMessage = validateSupplyPointOneMeter(supplyPointType);
  const supplyOneValidationMessage = validateSupplyPointOne(supplyOneMeterFuelType);
  const invalidSupplyPointPansValidationMessage = validateSingleSupplyPointPan(
    supplyPointPan,
    requestSupplyPointPan,
  );

  return {
    ...(supplyOneValidationMessage && {
      [name]: [supplyOneValidationMessage],
    }),
    ...(invalidSupplyPointMeterValidationMessage && {
      [`${name}Type`]: [invalidSupplyPointMeterValidationMessage],
    }),
    ...(invalidSupplyPointPansValidationMessage && {
      [`${name}Pan`]: [invalidSupplyPointPansValidationMessage],
    }),
  };
};

export const validateDoubleMeterReissue = (
  values: BoostIssuePanFormValues,
): Record<string, (string | boolean)[]> => {
  const {
    supplyPointOne,
    supplyPointTwo,
    supplyPointOneType,
    supplyPointTwoType,
    supplyPointOnePan,
    supplyPointTwoPan,
    requestSupplyPointOnePan,
    requestSupplyPointTwoPan,
  } = values;

  const supplyOneMeterFuelType = determineValidMeterFuelType(supplyPointOne);
  const supplyTwoMeterFuelType = determineValidMeterFuelType(supplyPointTwo);

  const invalidSupplyPointMeterOneValidationMessage = validateSupplyPointOneMeter(
    supplyPointOneType,
  );
  const invalidSupplyPointMeterTwoValidationMessage = validateSupplyPointOneMeter(
    supplyPointTwoType,
  );

  const supplyOneValidationMessage = validateSupplyPointOne(supplyOneMeterFuelType);
  const supplyTwoFilledValidationMessage = validateSupplyPointOne(supplyTwoMeterFuelType);
  const supplyTwoValidationMessage =
    !supplyTwoFilledValidationMessage &&
    validateSupplyPointTwo(supplyOneMeterFuelType, supplyTwoMeterFuelType);

  const invalidSupplyPointPanOneValidationMessage = validateSingleSupplyPointPan(
    supplyPointOnePan,
    requestSupplyPointOnePan,
  );

  const invalidSupplyPointPanTwoValidationMessage = validateSingleSupplyPointPan(
    supplyPointTwoPan,
    requestSupplyPointTwoPan,
  );

  return {
    ...(supplyOneValidationMessage && {
      supplyPointOne: [supplyOneValidationMessage],
    }),
    ...((supplyTwoValidationMessage || supplyTwoFilledValidationMessage) && {
      supplyPointTwo: [supplyTwoValidationMessage, supplyTwoFilledValidationMessage],
    }),
    ...(invalidSupplyPointMeterOneValidationMessage && {
      supplyPointOneType: [invalidSupplyPointMeterOneValidationMessage],
    }),
    ...(invalidSupplyPointMeterTwoValidationMessage && {
      supplyPointTwoType: [invalidSupplyPointMeterTwoValidationMessage],
    }),
    ...(invalidSupplyPointPanOneValidationMessage && {
      supplyPointOnePan: [invalidSupplyPointPanOneValidationMessage],
    }),
    ...(invalidSupplyPointPanTwoValidationMessage && {
      supplyPointTwoPan: [invalidSupplyPointPanTwoValidationMessage],
    }),
  };
};

export const validateSupplyPointsOnReissue = (
  values: BoostIssuePanFormValues,
): Record<string, (string | boolean)[]> => {
  const {
    supplyPointOne,
    supplyPointTwo,
    supplyPointOneType,
    supplyPointTwoType,
    supplyPointOnePan,
    supplyPointTwoPan,
    requestSupplyPointOne,
    requestSupplyPointTwo,
    requestSupplyPointOnePan,
    requestSupplyPointTwoPan,
  } = values;
  const supplyPointRequestBoxValidationMessage = validateSupplyPointRequestBoxes(
    requestSupplyPointOne,
    requestSupplyPointTwo,
  );

  if (requestSupplyPointOne && !requestSupplyPointTwo) {
    return validateSingleMeterReissue(
      'supplyPointOne',
      supplyPointOne,
      supplyPointOneType,
      supplyPointOnePan,
      requestSupplyPointOnePan,
    );
  }
  if (requestSupplyPointTwo && !requestSupplyPointOne) {
    return validateSingleMeterReissue(
      'supplyPointTwo',
      supplyPointTwo,
      supplyPointTwoType,
      supplyPointTwoPan,
      requestSupplyPointTwoPan,
    );
  }
  if (requestSupplyPointOne && requestSupplyPointTwo) {
    return validateDoubleMeterReissue(values);
  }

  return {
    ...(supplyPointRequestBoxValidationMessage && {
      requestPanSupplyPointOne: [supplyPointRequestBoxValidationMessage],
    }),
  };
};

export const strikePan = (
  form: FormApi<any>,
  setStrike: (arg: boolean) => void,
  setStrikeTwo: (arg: boolean) => void,
): void => {
  if (form.getState().values.supplyPointOnePan) {
    setStrike(form.getState().values.requestSupplyPointOnePan);
  }
  if (form.getState().values.supplyPointTwoPan) {
    setStrikeTwo(form.getState().values.requestSupplyPointTwoPan);
  }
};

export const tickRequestNewPanCheckbox = (
  form: FormApi<any>,
  setTickPanOne: (arg: boolean) => void,
  setTickPanTwo: (arg: boolean) => void,
): void => {
  setTickPanOne(!form.getState().values.supplyPointOnePan);
  setTickPanTwo(!form.getState().values.supplyPointTwoPan);
};

export const disableRequestNewPanCheckbox = (
  meterDetails: Meter[],
  setDisableBoxOne: (arg: boolean) => void,
  setDisableBoxTwo: (arg: boolean) => void,
): void => {
  const meterOneHasPan = meterDetails && meterDetails[0] && !meterDetails[0].pan;
  const meterTwoHasPan = meterDetails && meterDetails[1] && !meterDetails[1].pan;
  setDisableBoxOne(meterOneHasPan);
  setDisableBoxTwo(meterTwoHasPan);
};
