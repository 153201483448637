import { differenceInDays, endOfDay, format, startOfDay } from 'date-fns';
import { accountBalanceQuery_account_balances } from '../screens/account/account-balance/__generated__/accountBalanceQuery';
import { accountEventsQuery_accountEvents_events } from '../screens/account/account-events/__generated__/accountEventsQuery';
import {
  BalanceType,
  Currency,
  EventFuelType,
  EventSources,
  EventType,
  MeterAdjustmentStatus,
  MeterAdjustmentType,
  Scope,
} from '../__generated__/globalTypes';
import { Money } from '../types';
import { get } from '@ovotech/typesafe-get';
import { isFuture } from 'date-fns';
import { loginMutation_createSession as Session } from '../components/screen/__generated__/loginMutation';
import { GraphQLError } from 'graphql';
import { MeterInfo } from 'src/screens/account/account-balance/DC-Wizard/ModalForm';
import { parsePhoneNumber } from 'libphonenumber-js';

export enum METER_FUEL_TYPES {
  ELECTRICITY = 'electricity',
  GAS = 'gas',
  UNKNOWN = 'unknown',
  INVALID = 'invalid',
  EMPTY = 'empty',
}

export const MAX_LENGTH_GAS = 10;
export const MIN_LENGTH_GAS = 6;
export const MAX_LENGTH_ELECTRICITY = 14;
export const MIN_LENGTH_ELECTRICITY = 13;

export const toRoundedPounds = (thousandthsOfAPenny: number): number =>
  Math.ceil(thousandthsOfAPenny / 100000);

export const toRoundedPennies = (thousandthsOfAPenny: number): number =>
  Math.ceil(thousandthsOfAPenny / 1000);

export const penniesToMilliPennies = (pounds: number): number => pounds * 1000;
export const poundsToMilliPennies = (pounds: number): number => pounds * 100000;

export const determineMeterType = (meterNumber: string | undefined): METER_FUEL_TYPES => {
  if (meterNumber && meterNumber.length > MAX_LENGTH_GAS) {
    return METER_FUEL_TYPES.ELECTRICITY;
  }

  if (meterNumber && meterNumber.length >= MIN_LENGTH_GAS) {
    return METER_FUEL_TYPES.GAS;
  }

  return METER_FUEL_TYPES.UNKNOWN;
};

export const determineValidMeterFuelType = (meterNumber: string | undefined): METER_FUEL_TYPES => {
  if (!meterNumber || meterNumber === '') {
    return METER_FUEL_TYPES.EMPTY;
  }
  if (
    meterNumber &&
    meterNumber.length >= MIN_LENGTH_ELECTRICITY &&
    meterNumber.length <= MAX_LENGTH_ELECTRICITY
  ) {
    return METER_FUEL_TYPES.ELECTRICITY;
  }
  if (meterNumber && meterNumber.length >= MIN_LENGTH_GAS && meterNumber.length <= MAX_LENGTH_GAS) {
    return METER_FUEL_TYPES.GAS;
  }
  return METER_FUEL_TYPES.INVALID;
};

export const isGasMeter = (supplyPoint: string): boolean =>
  determineValidMeterFuelType(supplyPoint) === METER_FUEL_TYPES.GAS;

export const isElectricityMeter = (supplyPoint: string): boolean =>
  determineValidMeterFuelType(supplyPoint) === METER_FUEL_TYPES.ELECTRICITY;

export const assignSupplyPointToMeter = (
  supplyPointOne: string,
  supplyPointTwo?: string,
): { mprn?: string; mpan?: string } => {
  const mprn = isGasMeter(supplyPointOne)
    ? supplyPointOne
    : supplyPointTwo && isGasMeter(supplyPointTwo)
    ? supplyPointTwo
    : undefined;

  const mpan = isElectricityMeter(supplyPointOne)
    ? supplyPointOne
    : supplyPointTwo && isElectricityMeter(supplyPointTwo)
    ? supplyPointTwo
    : undefined;

  return { mprn, mpan };
};

const INVALID_FORMAT_MESSAGE = `Please provide a valid MPxN`;

export const validateSupplyPointOne = (supplyOneMeterFuelType: METER_FUEL_TYPES): string => {
  if (
    supplyOneMeterFuelType !== METER_FUEL_TYPES.EMPTY &&
    supplyOneMeterFuelType !== METER_FUEL_TYPES.INVALID &&
    supplyOneMeterFuelType !== METER_FUEL_TYPES.UNKNOWN
  ) {
    return '';
  }
  return INVALID_FORMAT_MESSAGE;
};

export const validateSupplyPointTwo = (
  supplyOneMeterFuelType: METER_FUEL_TYPES,
  supplyTwoMeterFuelType: METER_FUEL_TYPES,
): string => {
  if (supplyTwoMeterFuelType === METER_FUEL_TYPES.EMPTY) return '';

  if (supplyTwoMeterFuelType === METER_FUEL_TYPES.INVALID) {
    return INVALID_FORMAT_MESSAGE;
  }

  if (supplyTwoMeterFuelType === METER_FUEL_TYPES.UNKNOWN) {
    return INVALID_FORMAT_MESSAGE;
  }

  if (supplyOneMeterFuelType === supplyTwoMeterFuelType) {
    return `The meter type ${supplyTwoMeterFuelType} is already set`;
  }
  return '';
};

export const validateSupplyPoints = ({
  supplyPointOne,
  supplyPointTwo,
}: {
  supplyPointOne: string;
  supplyPointTwo: string;
}): Record<string, string[]> => {
  const supplyOneMeterFuelType = determineValidMeterFuelType(supplyPointOne);
  const supplyTwoMeterFuelType = determineValidMeterFuelType(supplyPointTwo);

  const supplyOneValidationMessage = validateSupplyPointOne(supplyOneMeterFuelType);
  const supplyTwoValidationMessage = validateSupplyPointTwo(
    supplyOneMeterFuelType,
    supplyTwoMeterFuelType,
  );
  return {
    ...(supplyOneValidationMessage && { supplyPointOne: [supplyOneValidationMessage] }),
    ...(supplyTwoValidationMessage && { supplyPointTwo: [supplyTwoValidationMessage] }),
  };
};

export const validateSupplyPointOneMeter = (supplyPointOneType?: string): string => {
  if (!supplyPointOneType) return 'Please select a type';
  return '';
};

export const validateSupplyPointTwoMeter = (
  supplyPointTwo: string,
  supplyPointTwoType: string,
): string => {
  if (!supplyPointTwo) return '';
  if (!supplyPointTwoType) {
    return 'Please select a type';
  }
  return '';
};

export const validateSupplyPointMeters = ({
  supplyPointTwo,
  supplyPointOneType,
  supplyPointTwoType,
}: {
  supplyPointTwo: string;
  supplyPointOneType: string;
  supplyPointTwoType: string;
}): Record<string, string[]> => {
  const supplyPointOneTypeValidationMessage = validateSupplyPointOneMeter(supplyPointOneType);
  const supplyPointTwoTypeValidationMessage = validateSupplyPointTwoMeter(
    supplyPointTwo,
    supplyPointTwoType,
  );

  return {
    ...(supplyPointOneTypeValidationMessage && {
      supplyPointOneType: [supplyPointOneTypeValidationMessage],
    }),
    ...(supplyPointTwoTypeValidationMessage && {
      supplyPointTwoType: [supplyPointTwoTypeValidationMessage],
    }),
  };
};

export const dateFormat = 'DD MMM YYYY HH:mm';

export const dateFormatIso = 'YYYY-MM-DD';

export const maxCharacterLength = 30;

export const calculateDaysDifference = (startDate: Date | null): number => {
  if (startDate) {
    const currentDate = new Date();
    const timeDifference = startDate.getTime() - currentDate.getTime();
    const dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    return dayDifference;
  }
  return 0;
};

export function getDoubleQuoted(inputString: string): string[] {
  const regex = /"([^"]*)"/g;
  const matches = [];
  let match;

  while ((match = regex.exec(inputString)) !== null) {
    matches.push(match[1]);
  }

  return matches;
}

/// Function to filter out __typename property for recommendations
export function filterTypename(obj: Record<string, unknown>): Record<string, unknown> {
  const objCopy = JSON.parse(JSON.stringify(obj));

  for (const key in objCopy) {
    if (key === '__typename') {
      delete objCopy[key];
    } else if (typeof objCopy[key] === 'object') {
      objCopy[key] = filterTypename(objCopy[key]);
    }
  }
  return objCopy;
}

interface invalidateMessageType {
  message: string;
}
export function validateAndFormatPhoneNumber(
  value: string,
  options?: invalidateMessageType,
): { isValid: boolean; value: string; message?: string } {
  try {
    const parsedPhoneNumber = parsePhoneNumber(value, 'GB');
    if (parsedPhoneNumber.isValid()) {
      return { isValid: true, value: parsedPhoneNumber.format('E.164') };
    } else {
      return { isValid: false, value, message: options?.message };
    }
  } catch {
    //
  }
  return { isValid: false, value, message: options?.message };
}

export const findBadUserInputErrors = (errors: readonly GraphQLError[] | undefined) => {
  if (!errors)
    return {
      hasBadUserInputErrors: false,
      defaultErrors: [],
      customErrors: [],
      hasMobileNumberError: false,
    };
  const badUserInputErrors = errors.filter((error) => {
    return error.extensions && error.extensions.code && error.extensions.code === 'BAD_USER_INPUT';
  });

  const defaultErrors = badUserInputErrors.filter((error) => {
    return error.message.startsWith('Variable "$input" got invalid value');
  });

  const customErrors = badUserInputErrors.filter((error) => {
    return !error.message.startsWith('Variable "$input" got invalid value');
  });

  const hasMobileNumberError = errors.filter((error) => {
    return (
      error.extensions &&
      error.extensions.code &&
      error.extensions?.[0]?.message === 'Invalid phone number format'
    );
  });

  return {
    hasBadUserInputErrors: badUserInputErrors.length > 0,
    defaultErrors,
    customErrors,
    hasMobileNumberError: hasMobileNumberError.length > 0,
  };
};

export const formatUserError = (error: GraphQLError, meters: MeterInfo[]): string => {
  if (error.message.startsWith('Variable "$input" got invalid value')) {
    // we have a default error
    const vals = getDoubleQuoted(error.message);
    if (vals[1].endsWith('[0].amount.value')) {
      // the first dc amounts is invalid
      return (
        'please enter a valid value for the amount of ' +
        meters[0].type.toLowerCase() +
        'discretionary credit'
      );
    } else if (vals[1].endsWith('[1].amount.value')) {
      // the second dc amounts is invalid
      return (
        'please enter a valid value for the amount of ' +
        meters[1].type.toLowerCase() +
        'discretionary credit'
      );
    } else if (vals[1].endsWith('[0].repaymenRate.value')) {
      // the first repaymenRates is invalid
      return (
        'please enter a valid value for the ' + meters[0].type.toLowerCase() + 'repayment rate'
      );
    } else if (vals[1].endsWith('[1].repaymenRate.value')) {
      // the second repaymenRates is invalid
      return (
        'please enter a valid value for the ' + meters[1].type.toLowerCase() + 'repayment rate'
      );
    } else {
      return 'error submitting form, please make sure all values are valid';
    }
  } else {
    // we got a custom error
    return error.message;
  }
};
interface SemanticOption {
  text: string;
  value: string;
}
export const toSemanticOptions = (object: { [key: string]: string }): SemanticOption[] =>
  Object.keys(object).map((key) => ({
    text: object[key],
    value: key,
  }));

export const formatEventType: { [key in EventType]: string } = {
  [EventType.TOP_UP]: 'Top-up',
  [EventType.USAGE_CHARGE]: 'Usage Charge',
  [EventType.STANDING_CHARGE]: 'Standing Charge',
  [EventType.BALANCE_ADJUSTMENT]: 'Balance Adjustment',
  [EventType.DEBT_ADJUSTMENT]: 'Debt Adjustment',
  [EventType.DEBT_READ]: 'Debt Read',
  [EventType.DEBT_REPAYMENT]: 'Debt Repayment',
  [EventType.METER_TOP_UP]: 'Meter Top-up',
  [EventType.EMAIL]: 'Email Comm',
  [EventType.SMS]: 'SMS Comm',
  [EventType.PRINT]: 'Print Comm',
  [EventType.CHANGE_MODE]: 'Change Mode',
  [EventType.METER_READING]: 'Meter reading',
  [EventType.BALANCE_READ]: 'Balance read',
  [EventType.PAN_RECONCILIATION_STATUS]: 'Pan Reconciliation Status',
  [EventType.UNKNOWN]: 'Unknown',
};

export const formatEventTypeAmount = (event: accountEventsQuery_accountEvents_events): string => {
  switch (event.type) {
    case EventType.TOP_UP:
      if (event.source === EventSources.S2) {
        return 'Balance Adjustment';
      }
      return 'Top-up';

    case EventType.USAGE_CHARGE:
      return 'Usage Charge';
    case EventType.STANDING_CHARGE:
      return 'Standing Charge';
    case EventType.BALANCE_ADJUSTMENT:
      return 'Balance Adjustment';
    case EventType.DEBT_ADJUSTMENT:
      return 'Debt Adjustment';
    case EventType.DEBT_READ:
      return 'Debt Read';
    case EventType.DEBT_REPAYMENT:
      return 'Debt Repayment';
    case EventType.METER_TOP_UP:
      return 'Meter Top-up';
    case EventType.EMAIL:
      return 'Email Comm';
    case EventType.SMS:
      return 'SMS Comm';
    case EventType.PRINT:
      return 'Print Comm';
    case EventType.CHANGE_MODE:
      return 'Change Mode';
    case EventType.METER_READING:
      return 'Meter Reading';
    case EventType.BALANCE_READ:
      return 'Balance Read';
    case EventType.PAN_RECONCILIATION_STATUS:
      return 'Pan Reconciliation Status';
    case EventType.UNKNOWN:
      return 'Unknown';
  }
};

export const formatMeterAdjustmentType: { [key in MeterAdjustmentType]: string } = {
  [MeterAdjustmentType.BALANCE_ADJUSTMENT]: 'Balance Adjustment',
  [MeterAdjustmentType.BALANCE_READ]: 'Balance Read',
  [MeterAdjustmentType.CHANGE_MODE_CREDIT]: 'Change Mode to PAYM',
  [MeterAdjustmentType.CHANGE_MODE_PREPAYMENT]: 'Change Mode to PAYG Standard',
  [MeterAdjustmentType.CREATE_PAN]: 'Creating New PAN',
  [MeterAdjustmentType.DEBT_ADJUSTMENT]: 'Debt Adjustment',
  [MeterAdjustmentType.DEBT_READ]: 'Debt Read',
  [MeterAdjustmentType.DELETE_PAN]: 'Deleting Old PAN',
  [MeterAdjustmentType.DISABLE_PIN]: 'Disable PIN',
  [MeterAdjustmentType.READ_MODE]: 'On Demand Meter Mode Read',
  [MeterAdjustmentType.READ_SUPPLY_STATE]: 'On Demand Supply State Read',
  [MeterAdjustmentType.METER_TOP_UP]: 'Top Up',
  [MeterAdjustmentType.READ_PREPAYMENT_DATA]: 'Balances Read',
  [MeterAdjustmentType.UNKNOWN]: 'Unknown',
};

export const formatMeterAdjustmentStatus: { [key in MeterAdjustmentStatus]: string } = {
  [MeterAdjustmentStatus.PENDING]: 'Pending',
  [MeterAdjustmentStatus.SUCCESS]: 'Success',
  [MeterAdjustmentStatus.FAILURE]: 'Failure',
};

export const formatKwh = (kwh: number | null): string =>
  kwh === null ? '-' : `${kwh.toFixed(2)} kWh`;

export const removeDecimalDigitsWithoutRounding = (value: number): string =>
  (value > 0 ? Math.floor(value) : Math.ceil(value)).toFixed();

export const formatReadingUnits = (
  unitValue: number | null,
  fuelType: EventFuelType | null,
  valueConverter: (value: number) => string = (v): string => v.toFixed(2),
): string => {
  if (unitValue === null) {
    return '-';
  }
  switch (fuelType) {
    case EventFuelType.ELECTRICITY: {
      return `${valueConverter(unitValue)} kWh`;
    }
    case EventFuelType.GAS: {
      return `${valueConverter(unitValue)} m3`;
    }
    default:
      return '-';
  }
};

export const formatMoney = (money?: Money | null): string => {
  if (!money) {
    return '';
  }
  return new Intl.NumberFormat('en-GB', { style: 'currency', currency: money.currency }).format(
    money.value / 100,
  );
};

export const formatStringAsMoney = (amount: string, currency = 'GBP'): string => {
  if (isNaN(parseFloat(amount))) {
    return '';
  }
  return new Intl.NumberFormat('en-GB', { style: 'currency', currency: currency }).format(
    parseFloat(amount),
  );
};

export const parseFloatWithCommas = (value: string): number => {
  const value2 = value?.replace?.(/,/g, '') || value;
  return parseFloat(value2);
};

export const fomatTo2DecimalPlaces = (value: string | number): string => {
  if (!value) return '';
  const formatee = typeof value === 'number' ? value : parseFloat(value);
  if (isNaN(formatee)) return String(value); //validation will tell the user that it's invalid
  return new Intl.NumberFormat('en-GB', { style: 'decimal', minimumFractionDigits: 2 }).format(
    formatee,
  );
};

export const toMoneyObject = (amount: number, currency = Currency.GBP): Money => ({
  currency,
  value: Math.round(amount * 100),
});
export const toMoneyFormat = (amount: number, currency = Currency.GBP): Money => ({
  currency,
  value: amount,
});

const BalanceAdjustmentCauseMappings: { [key: string]: string | undefined } = {
  AdjustmentMainBalanceCreate: 'Balance Created At Account Enrollment',
  AdjustmentMainBalanceDecrease: 'Manual Or Bulk Balance Decrease',
  AdjustmentMainBalanceIncrease: 'Manual Or Bulk Balance Increase',
  AppPayment: 'Top-up Made With Secondary Card',
  AutoTopUp: 'Auto Top-up',
  AutoTopUpCancel: 'Cancelled Auto Top-up',
  DebtDailyRepayment: 'Debt Based Adjustment',
  GovElecRebate: 'Government Rebate Credit',
  PAYGUpgradeIssueDebt: 'PAYG+ Upgrade Issue Debt',
  PAYMtoPAYGDebtTransfer: 'PAYM To PAYG Debt Transfer',
  PeriodicTopUp: 'Rolling Top-up',
  PeriodicTopUpCancel: 'Cancelled Rolling Top-up',
  ReconnectionTroubleshooting: 'Re-Connection Troubleshooting',
  SelfCare: 'App Top-up',
  SelfCareApp: 'Main Card App Top-up',
  SelfCareAppCancel: 'Cancelled Main Card App Top-up',
  SmartIncentivesCredit: 'Incentive Campaign Credit',
  Sms: undefined,
  SmsRech: 'SMS Top-up',
  Supplier: undefined,
  TerminalCancel: 'Cancelled Paypoint Top-up',
  TerminalDelete: undefined,
  TerminalNew: 'Paypoint Top-up',
  TerminalUndoCancel: 'Undo Cancelled Paypoint Top-up',
  WinterWalletSavings: 'Winter Wallet Transfer',
};

// make a label from the cause type e.g. 'AdjustRepaymentRate' > 'Adjust Repayment Rate'
const getCauseLabelFromType = ((): ((code: string) => string) => {
  // match an uppercase letter followed by a lowercase letter that isnt at the start of a string
  const pattern = /(?!^)([A-Z][a-z])/g;
  const map: { [code: string]: string } = {};

  return (code: string): string => {
    if (code in map) {
      return map[code];
    } else {
      const label = code.replace(pattern, ' $1');
      map[code] = label;
      return label;
    }
  };
})();

export const toBalanceAdjustmentCauseView = (
  causeType: string | null,
  amount: Money | null,
): string => {
  // Unlike other caused, the WinterWalletSavings cause can cover both an increase or decrease in balance
  if (causeType === 'DiscretionaryCredit') {
    return 'Additional Support Credit';
  }

  if (causeType === 'WinterWalletSavings' && amount !== null) {
    if (amount.value < 0) {
      return 'Transfer To Winter Wallet';
    } else if (amount.value > 0) {
      return 'Transfer From Winter Wallet';
    }
  }

  if (causeType === 'DebtDailyRepayment' && amount !== null) {
    if (amount.value < 0) {
      return 'Debt Repayment';
    }
  }

  if (!causeType) {
    return 'UNKNOWN';
  }

  if (causeType in BalanceAdjustmentCauseMappings) {
    return BalanceAdjustmentCauseMappings[causeType] || 'UNKNOWN';
  }

  return getCauseLabelFromType(causeType);
};

const BALANCE_ORDER: BalanceType[] = [BalanceType.ELECTRICITY, BalanceType.GAS];

type AccountBalance = accountBalanceQuery_account_balances;
export const sortBalances = (a: AccountBalance, b: AccountBalance): number =>
  BALANCE_ORDER.indexOf(a.type) - BALANCE_ORDER.indexOf(b.type);

export const penceToPound = (pence: number): string => (pence / 100).toFixed(2);

export const currencyConstraintLessThanOrEqual = (
  lessThanOrEqualTo: number,
): {
  numericality: {
    greaterThanOrEqualTo: number;
    lessThanOrEqualTo: number;
  };
  format: {
    pattern: string;
    message: string;
  };
  presence: boolean;
} => ({
  numericality: {
    greaterThanOrEqualTo: 0.0,
    lessThanOrEqualTo,
  },
  format: {
    pattern: '[0-9]+(.[0-9]{2})?',
    message: 'should be in the format 0.00',
  },
  presence: true,
});

export const currencyAsIntegerConstraintLessThanOrEqual = (
  lessThanOrEqualTo: number,
): {
  numericality: {
    greaterThanOrEqualTo: number;
    lessThanOrEqualTo: number;
  };
  format: {
    pattern: string;
    message: string;
  };
  presence: boolean;
} => ({
  numericality: {
    greaterThanOrEqualTo: 0,
    lessThanOrEqualTo,
  },
  format: {
    pattern: '^0*(?:[0-9][0-9]?|100)$',
    message: 'should be whole pounds',
  },
  presence: true,
});

export const formatDateWithDays = (date: Date): string => {
  const daysDifference = differenceInDays(endOfDay(date), startOfDay(new Date())) + 1;

  return `${format(date, 'dddd Do MMM')} (${daysDifference} Day${daysDifference === 1 ? '' : 's'})`;
};

export const truncate = (str: string): string => {
  if (str.length <= maxCharacterLength) return str;
  return `${str.slice(0, maxCharacterLength)}...`;
};

export const userHasRequiredScope = (session: Session, requiredScope: Scope): boolean => {
  const expiresAt = get(session, 'expiresAt');
  if (session && expiresAt && isFuture(expiresAt)) {
    const userScopes = get(session, 'user', 'scopes');
    return userScopes !== null && userScopes !== undefined && userScopes.includes(requiredScope);
  }
  return false;
};

// helper to handle exceptions with async await without wrapping in try/catch
/* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
export const handleAwait = <DataType>(promise: Promise<DataType>): any => {
  return promise
    .then((data) => [data, undefined])
    .catch((error) => Promise.resolve([undefined, error]));
};
export const isEmailValid = (emailAddress: string): boolean => {
  if (
    /^[a-zA-Z\d.'\\/&_%+-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/.exec(emailAddress) === null ||
    emailAddress.includes('..')
  ) {
    return false;
  }
  return true;
};

export const validatePostcode = (postcode: string): Record<string, string[]> => {
  let invalidPostcode = {};
  const postcodeChars = new RegExp(/^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i);
  const space = new RegExp(/^.{2,3}[ ]|^.{4,5}[ ]/);
  if (!postcodeChars.test(postcode) || !space.test(postcode)) {
    invalidPostcode = { postcode: ['Please enter a valid postcode using the "AB12 3CD" fromat'] };
  }
  if (!postcode) {
    invalidPostcode = { postcode: ['Should not be blank'] };
  }
  return { ...invalidPostcode };
};

interface PanSendingInformation {
  sendByEmail?: boolean;
  sendByPost?: boolean;
  email?: string;
}

export const validateSendingInformation = <T extends PanSendingInformation>(
  values: T,
): Record<string, string[]> => {
  const { sendByEmail, email, sendByPost } = values;
  let invalidSendingInformation = null;
  if (!sendByEmail && !sendByPost) {
    invalidSendingInformation = { sendByEmail: ['Choose at least one way to send PAN numbers'] };
  }
  if (sendByEmail && !email) {
    invalidSendingInformation = { email: ['Please enter an email address'] };
  }
  if (sendByEmail && email && !isEmailValid(email)) {
    invalidSendingInformation = { email: ['Please enter a valid email address'] };
  }
  return { ...invalidSendingInformation };
};

declare global {
  interface Window {
    usabilla_live: (title: string, action: string) => void;
  }
}

export const usabillaPopup = (title: string, action: string): void => {
  window.usabilla_live(title, action);
};
