import { addMonths, eachDay, format } from 'date-fns';
import { MutableState, Tools } from 'final-form';
import { debounce } from 'lodash';
import * as React from 'react';
import { useLazyQuery, useMutation, useQuery } from 'react-apollo';
import * as Final from 'react-final-form';
import { Form, Header, Icon, Message } from 'semantic-ui-react';
import { validate } from 'validate.js';
import { BalanceType, Currency, Scope } from '../../../../__generated__/globalTypes';
import { FieldComponent } from '../../../../components';
import { PortalModal } from '../../../../components/portal-modal';
import { usabillaPopup } from '../../../../helpers';
import {
  penceToPound,
  toMoneyObject,
  currencyConstraintLessThanOrEqual,
  currencyAsIntegerConstraintLessThanOrEqual,
  formatDateWithDays,
} from '../../../../helpers';
import '../adjust-balance/adjust-balance.form.less';
import {
  addDiscretionaryCreditMutation as Results,
  addDiscretionaryCreditMutationVariables as Variables,
} from './__generated__/addDiscretionaryCreditMutation';
import {
  eligibleForDiscretionaryCreditQuery as AllowedResults,
  eligibleForDiscretionaryCreditQueryVariables as AllowedVariables,
} from './__generated__/eligibleForDiscretionaryCreditQuery';
import {
  recommendedDiscretionaryCreditQuery as recommendedDCQueryResults,
  recommendedDiscretionaryCreditQueryVariables as recommendedDCQueryVariables,
} from './__generated__/recommendedDiscretionaryCreditQuery';
import { addDiscretionaryCreditMutation } from './add-discretionary-credit.form.gql';
import './add-discretionary-credit.form.less';
import { eligibleForDiscretionaryCreditQuery } from './eligible-discretionary-credit-warning.gql';
import { recommendedDiscretionaryCreditQuery } from './recommended-discretionary-credit.gql';
import {
  trackAddDiscretionaryCredit,
  trackRecommendedDiscretionaryCredit,
} from '../../../../metrics';
import { useContext } from 'react';
import { ClientStateContext } from '../../../../client/client-state-context';
import { DropdownField } from '../../../../components/dropdown-field';
import { RecommendedDiscretionaryCredit } from '../../../../components/recommended-discretionary-credit';
import { formatVulnerabilities } from './helpers';
import * as uuid from 'uuid';

interface Props {
  accountId: string;
  type: BalanceType;
  onClose: () => void;
  msn?: string;
  mpxn?: string;
  customerVulnerabilities: string[] | null;
  repaymentRate?: number;
  usualRepaymentRate?: number;
}

interface AddDiscretionaryCreditForm {
  amount: string;
  repaymentRate: string;
  toDate?: Date;
  usualRepaymentRate: string;
}

const LARGEST_RECOMMENDED_DC_AMOUNT_POUNDS = 50;
const DEFAULT_REPAYMENT_RATE = 75;

export const AddDiscretionaryCreditForm: React.FunctionComponent<Props> = ({
  accountId,
  type,
  onClose,
  msn,
  mpxn,
  repaymentRate,
  usualRepaymentRate,
  customerVulnerabilities,
}) => {
  const formattedVulnerabilities = formatVulnerabilities(customerVulnerabilities);
  const hasVulnerabilities = formattedVulnerabilities !== null;

  const _showCalculatorParam = Scope.ACCOUNT;

  const [addDiscretionaryCredit, discretionaryCreditMutation] = useMutation<Results, Variables>(
    addDiscretionaryCreditMutation,
    {
      refetchQueries: ['accountBalanceQuery'],
      awaitRefetchQueries: true,
    },
  );
  const allowedQuery = useQuery<AllowedResults, AllowedVariables>(
    eligibleForDiscretionaryCreditQuery,
    {
      variables: {
        eligibleForDiscretionaryCreditInput: {
          accountId,
          type,
        },
      },
    },
  );

  const successMessage = `Discretionary Credit is being applied. Please go to the event history tab to view the UTRN.`;

  let allowedDC = true;

  if (allowedQuery.data && allowedQuery.data.eligibleForDiscretionaryCredit) {
    allowedDC = allowedQuery.data.eligibleForDiscretionaryCredit.allowed;
  }

  const previousRepaymentRate = React.useRef('');
  const previousToDate = React.useRef('');
  const currentRecommendationAmount = React.useRef(0);
  const currentRecommendationTotalDaysCovered = React.useRef(0);
  const { session } = useContext(ClientStateContext);

  return (
    <Final.Form
      mutators={{
        setAmount: (
          args: unknown[],
          state: MutableState<Record<string, unknown>>,
          tools: Tools<Record<string, unknown>>,
        ) => {
          tools.changeValue(state, 'amount', () => args[0]);
        },
      }}
      onSubmit={async ({
        amount,
        repaymentRate,
        toDate,
        usualRepaymentRate,
      }: AddDiscretionaryCreditForm) => {
        const amountToAdjustBy = parseFloat(amount);
        const repaymentRateToSet = parseFloat(repaymentRate);
        const usualRepaymentRateToSet = parseFloat(usualRepaymentRate);
        const inputParams = {
          accountId,
          amount: toMoneyObject(amountToAdjustBy),
          type,
          msn,
          mpxn,
          repaymentRate: toMoneyObject(repaymentRateToSet),
          agentName: session?.user.email || '',
          usualRepaymentRate: toMoneyObject(usualRepaymentRateToSet),
          externalReference: uuid.v4(),
        };

        await addDiscretionaryCredit({
          variables: {
            input: inputParams,
          },
        });

        trackAddDiscretionaryCredit({
          amount: amountToAdjustBy,
          type,
          repaymentRate: repaymentRateToSet,
          recommendationAmount: currentRecommendationAmount.current,
          recommendationTotalDaysCovered: currentRecommendationTotalDaysCovered.current,
          hasUsedRecommendationCalculator: !!toDate,
          hasUsedRecommendedAmount: currentRecommendationAmount.current === amountToAdjustBy,
          agentName: session?.user.email || '',
          usualRepaymentRate: usualRepaymentRateToSet,
        });
        usabillaPopup('trigger', 'manual trigger');
      }}
      initialValues={{
        repaymentRate:
          repaymentRate && repaymentRate > 0
            ? penceToPound(repaymentRate)
            : penceToPound(DEFAULT_REPAYMENT_RATE),
        amount: '',
        usualRepaymentRate:
          usualRepaymentRate && usualRepaymentRate > 0
            ? penceToPound(usualRepaymentRate)
            : penceToPound(DEFAULT_REPAYMENT_RATE),
      }}
      validate={(values: AddDiscretionaryCreditForm) =>
        validate(values, {
          amount: currencyAsIntegerConstraintLessThanOrEqual(100),
          repaymentRate: currencyConstraintLessThanOrEqual(4),
          usualRepaymentRate: currencyConstraintLessThanOrEqual(4),
        })
      }
    >
      {({ form, handleSubmit, invalid, values }) => {
        const [getRecommended, recommendedDCQuery] = useLazyQuery<
          recommendedDCQueryResults,
          recommendedDCQueryVariables
        >(recommendedDiscretionaryCreditQuery, {
          onCompleted: (data) => {
            if (data && data.recommendedDiscretionaryCredit) {
              const recommendation = data.recommendedDiscretionaryCredit;
              const newRecommendedAmount = penceToPound(recommendation.total.value);
              form.mutators.setAmount(newRecommendedAmount);
              const newRatePence = recommendation.repaymentRate.value;
              if (values.repaymentRate === '' && newRatePence === DEFAULT_REPAYMENT_RATE) {
                return;
              }
              currentRecommendationAmount.current = parseFloat(newRecommendedAmount);
              currentRecommendationTotalDaysCovered.current = recommendation.totalDaysCovered;
              trackRecommendedDiscretionaryCredit(recommendation);
            }
          },
        });

        const updateRecommendation = debounce((rateDecimal: string, toDate: Date) => {
          if (rateDecimal === '') {
            rateDecimal = penceToPound(DEFAULT_REPAYMENT_RATE);
          }

          getRecommended({
            variables: {
              input: {
                accountId,
                currentRepaymentRate: {
                  currency: Currency.GBP,
                  value: parseFloat((parseFloat(rateDecimal) * 100).toFixed(2)),
                },
                toDate: format(toDate, 'YYYY-MM-DD'),
                mpxn,
                type,
              },
            },
          });
        }, 1000);

        return (
          <PortalModal
            submitButtonTitle="Add Discretionary Credit"
            headerTitle="Add Discretionary Credit"
            onClose={onClose}
            onSubmit={handleSubmit}
            submitDisabled={invalid || !!discretionaryCreditMutation.data}
          >
            {hasVulnerabilities && (
              <>
                <Message
                  size="small"
                  icon
                  info
                  data-test="customer-vulnerabilities"
                  id="modal-message"
                >
                  <Icon name="info circle" />
                  <Message.Content>
                    <Message.Header>
                      Consider the following customer vulnerabilities when adding discretionary
                      credit:
                    </Message.Header>
                    <span data-test="vulnerabilities-list">{formattedVulnerabilities}</span>
                  </Message.Content>
                </Message>
              </>
            )}

            <Form
              onSubmit={handleSubmit}
              loading={discretionaryCreditMutation.loading}
              success={!!discretionaryCreditMutation.data}
              error={!!discretionaryCreditMutation.error}
              data-test="add-discretionary-credit-form"
            >
              <Message success header="Success" content={successMessage} />
              <Message
                error
                header="Error Applying Discretionary Credit"
                content="Discretionary credit not applied."
              />
              {!allowedDC && (
                <Message
                  visible={true}
                  error={true}
                  header={
                    'This customer has not topped up since the last discretionary credit request'
                  }
                />
              )}
              <Message
                visible={true}
                warning={false}
                data-test="discretionary-credit-info-message"
                header={'This request will update both the main balance and debt balance'}
              />
              {_showCalculatorParam && (
                <DropdownField
                  options={eachDay(new Date(), addMonths(new Date(), 1)).map((day) => ({
                    key: day.toISOString(),
                    value: day.toISOString(),
                    text: formatDateWithDays(day),
                  }))}
                  fieldName="toDate"
                  headerText="How long does the customer need discretionary credit for"
                >
                  {recommendedDCQuery.data?.recommendedDiscretionaryCredit?.dailyChargeCover
                    .value === 0 ? (
                    <Message
                      visible={true}
                      warning={true}
                      data-test="not-enough-data-info-message"
                      header="We were not able to retrieve enough data to calculate the recommended amount of discretionary credit. Please make sure this meter is set to daily reads. "
                    />
                  ) : (
                    <RecommendedDiscretionaryCredit
                      loading={recommendedDCQuery.loading}
                      data={recommendedDCQuery.data}
                    />
                  )}
                </DropdownField>
              )}

              <FieldComponent name="repaymentRate">
                <label>Daily Repayment Rate</label>
                <div className="ui left icon input">
                  <Icon name="pound sign" />
                  <Final.Field
                    name="repaymentRate"
                    component="input"
                    placeholder={
                      repaymentRate
                        ? penceToPound(repaymentRate)
                        : penceToPound(DEFAULT_REPAYMENT_RATE)
                    }
                    data-test="add-discretionary-repayment-rate"
                    autoComplete="off"
                  />
                </div>
              </FieldComponent>
              <FieldComponent name="amount">
                <Header as="h4" dividing={true}>
                  Amount of Discretionary Credit to add
                </Header>
                <div className="ui left icon input">
                  <Icon name="pound sign" />
                  <Final.Field
                    name="amount"
                    component="input"
                    placeholder="0"
                    data-test="add-discretionary-credit-amount"
                    autoComplete="off"
                  />
                </div>
              </FieldComponent>

              <FieldComponent name="usualRepaymentRate">
                <label>Usual Daily Repayment Rate</label>
                <div className="ui left icon input">
                  <Icon name="pound sign" />
                  <Final.Field
                    name="usualRepaymentRate"
                    component="input"
                    placeholder={
                      usualRepaymentRate
                        ? penceToPound(usualRepaymentRate)
                        : penceToPound(DEFAULT_REPAYMENT_RATE)
                    }
                    data-test="add-discretionary-usual-repayment-rate"
                    autoComplete="off"
                  />
                </div>
                <span className="bold">
                  The customer&apos;s usual repayment rate if it differs from their current Daily
                  Repayment Rate.
                </span>
              </FieldComponent>

              {parseFloat(values.amount) > LARGEST_RECOMMENDED_DC_AMOUNT_POUNDS && (
                <Message
                  visible={true}
                  error={true}
                  data-test="discretionary-credit-large-amount-warning"
                  header={`Amounts over £${LARGEST_RECOMMENDED_DC_AMOUNT_POUNDS} should be confirmed with financial support`}
                />
              )}

              <Final.FormSpy
                onChange={(props) => {
                  const { repaymentRate, toDate } = props.values;

                  if (!toDate) {
                    updateRecommendation.cancel();
                    return;
                  }

                  if (
                    validate(props.values, {
                      repaymentRate: currencyConstraintLessThanOrEqual(4),
                      usualRepaymentRate: currencyConstraintLessThanOrEqual(4),
                    })
                  ) {
                    updateRecommendation.cancel();
                    return;
                  }

                  const updatedRepaymentRate = repaymentRate !== previousRepaymentRate.current;
                  const updateToDate = toDate !== previousToDate.current;

                  if (updatedRepaymentRate || updateToDate) {
                    updateRecommendation(repaymentRate, toDate);
                  }

                  previousRepaymentRate.current = repaymentRate;
                  previousToDate.current = toDate;
                }}
              />
            </Form>
          </PortalModal>
        );
      }}
    </Final.Form>
  );
};
