import * as React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

export const PathNavLink: React.FC<NavLinkProps> = (props) => (
  <NavLink
    className="item"
    isActive={(_, { pathname }): boolean => pathname === (props.to as string).split('?')[0]}
    {...props}
  />
);
