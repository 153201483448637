import { format } from 'date-fns';
import * as React from 'react';
import { Icon, Label, List, Popup } from 'semantic-ui-react';
import { EventFuelType, EventType } from '../../../__generated__/globalTypes';
import meter from '../../../assets/meter.svg';
import {
  dateFormat,
  formatEventTypeAmount,
  formatKwh,
  formatMoney,
  formatReadingUnits,
  removeDecimalDigitsWithoutRounding,
  toBalanceAdjustmentCauseView,
} from '../../../helpers';
import {
  accountEventsQuery_accountEvents_events,
  accountEventsQuery_accountEvents_events_BalanceAdjustmentEvent,
  accountEventsQuery_accountEvents_events_ChangeModeEvent_errors,
  accountEventsQuery_accountEvents_events_DebtAdjustmentEvent,
  accountEventsQuery_accountEvents_events_DebtReadEvent,
  accountEventsQuery_accountEvents_events_DebtRepaymentEvent,
  accountEventsQuery_accountEvents_events_MeterReadingEvent,
  accountEventsQuery_accountEvents_events_MeterTopUpEvent_errors,
  accountEventsQuery_accountEvents_events_UsageChargeEvent as UsageChargeEvent,
  accountEventsQuery_accountEvents_events_BalanceAdjustmentEvent_balance,
} from '../../account/account-events/__generated__/accountEventsQuery';

type BalanceEvent = (
  | accountEventsQuery_accountEvents_events_BalanceAdjustmentEvent
  | accountEventsQuery_accountEvents_events_DebtReadEvent
  | accountEventsQuery_accountEvents_events_DebtAdjustmentEvent
  | accountEventsQuery_accountEvents_events_DebtRepaymentEvent
) & {
  balance?: accountEventsQuery_accountEvents_events_BalanceAdjustmentEvent_balance;
} & {
  cause?: string;
};

export enum AccountEventType {
  BALANCE_ADJUSTMENT = 'BALANCE_ADJUSTMENT',
  CHANGE_MODE = 'CHANGE_MODE',
  DEBT_ADJUSTMENT = 'DEBT_ADJUSTMENT',
  DEBT_READ = 'DEBT_READ',
  DEBT_REPAYMENT = 'DEBT_REPAYMENT',
  METER_TOP_UP = 'METER_TOP_UP',
  TOP_UP = 'TOP_UP',
}

export interface AccountEventRow {
  timestamp: string;
  eventType: string;
  amount: string;
  balance: string;
  reading: string;
  consumption: string;
  description: JSX.Element;
}

interface StateMessages {
  PENDING: string;
  SUCCESS: string;
  FAILURE: string;
}

const FormatStateMessages: { [key in AccountEventType]: StateMessages } = {
  [AccountEventType.TOP_UP]: {
    PENDING: 'Paypoint transaction confirmed - not loaded onto meter yet',
    FAILURE: 'Paypoint transaction confirmed - meter refused top-up',
    SUCCESS: 'Added to meter',
  },
  [AccountEventType.METER_TOP_UP]: {
    PENDING: 'Paypoint transaction confirmed - not loaded onto meter yet',
    FAILURE: 'Paypoint transaction confirmed - meter refused top-up',
    SUCCESS: 'Added to meter',
  },
  [AccountEventType.DEBT_ADJUSTMENT]: {
    PENDING: 'Adjusting debt',
    FAILURE: 'Debt adjustment has failed',
    SUCCESS: 'Debt adjusted successfully',
  },
  [AccountEventType.BALANCE_ADJUSTMENT]: {
    PENDING: 'Adjusting balance',
    FAILURE: 'Balance adjustment has failed',
    SUCCESS: 'Balance adjusted successfully',
  },
  [AccountEventType.DEBT_READ]: {
    PENDING: 'Reading debt',
    FAILURE: 'Debt read has failed',
    SUCCESS: 'Debt read is successful',
  },
  [AccountEventType.DEBT_REPAYMENT]: {
    PENDING: 'Repaying debt',
    FAILURE: 'Debt repayment has failed',
    SUCCESS: 'Debt repayment is successful',
  },
  [AccountEventType.CHANGE_MODE]: {
    PENDING: 'Changing meter mode',
    FAILURE: 'Mode change has failed',
    SUCCESS: 'Mode change is successful',
  },
};

const FormatE21Message = 'E21 - "No Response Received from Device"';
const formatFuelType = (fuelType: UsageChargeEvent['fuelType']) => {
  switch (fuelType) {
    case EventFuelType.ELECTRICITY:
      return (
        <Label className="description-label">
          <Icon name="lightning" color="yellow" />
          Electricity
        </Label>
      );
    case EventFuelType.GAS:
      return (
        <Label className="description-label">
          <Icon name="fire" color="blue" />
          Gas
        </Label>
      );
    default:
      return <div />;
  }
};

const formatReadingTariffType = (
  event: accountEventsQuery_accountEvents_events_MeterReadingEvent,
) => (
  <>
    {event.tariffType === 'PC2' && (
      <Label className="description-label">{event.isSecondaryRead ? 'Night' : 'Day'}</Label>
    )}
  </>
);

const formatMsn = (event: { msn: string | null }) => (
  <Label title="MSN" className="description-label">
    <img src={meter} alt="MSN" className="ui icon" />
    {event.msn}
  </Label>
);

const convertEventTypeToAccountEventType = (eventType: EventType): AccountEventType =>
  AccountEventType[EventType[eventType] as keyof typeof AccountEventType];

const formatState = (state: string, type: AccountEventType, errorMessage?: string | null) => {
  switch (state) {
    case 'FAILURE':
      return (
        <Popup
          position="top center"
          content={
            <div>
              <p>
                {errorMessage === 'E21' && FormatE21Message}
                {errorMessage !== 'E21' && FormatStateMessages[type].FAILURE}
              </p>
            </div>
          }
          trigger={
            <Label className={`description-label ${errorMessage === 'E21' ? 'orange' : 'error'}`}>
              <Icon name="info circle" style={{ color: 'white' }} />
              {state}
            </Label>
          }
        />
      );
    case 'SUCCESS':
      return (
        <Popup
          position="top center"
          content={
            <div>
              <p>{FormatStateMessages[type].SUCCESS}</p>
            </div>
          }
          trigger={
            <Label className="description-label success">
              <Icon name="info circle" />
              {state}
            </Label>
          }
        />
      );
    case 'PENDING':
      return (
        <Popup
          position="top center"
          content={
            <div>
              <p>{FormatStateMessages[type].PENDING}</p>
            </div>
          }
          trigger={
            <Label className="description-label">
              <Icon name="info circle" />
              {state}
            </Label>
          }
        />
      );
    default:
      return null;
  }
};

const formatEventErrors = (
  errors: Array<
    | accountEventsQuery_accountEvents_events_MeterTopUpEvent_errors
    | accountEventsQuery_accountEvents_events_ChangeModeEvent_errors
  >,
) =>
  errors.map((error) => (
    <Popup
      key={error.code}
      position="top center"
      content={
        <div>
          <p>
            {error.code} {error.description}
          </p>
          {error.points &&
            error.points.map((point) => (
              <List bulleted key={point}>
                <List.Item>{point}</List.Item>
              </List>
            ))}
        </div>
      }
      trigger={
        <Label className="description-label error">
          <Icon name="info circle" />
          {error.name.toLocaleUpperCase()}
        </Label>
      }
    />
  ));

export const eventToAccountRow = (
  event: accountEventsQuery_accountEvents_events,
): AccountEventRow => {
  switch (event.__typename) {
    case 'TopUpEvent':
      return {
        timestamp: format(event.dateTime, dateFormat),
        eventType: formatEventTypeAmount(event),
        amount: formatMoney(event.amount),
        balance: formatMoney(event.balance) || '-',
        reading: '-',
        consumption: '-',
        description: (
          <>
            {event.cause && (
              <Label title="Cause" className="description-label">
                {`Cause: ${
                  event.cause === 'DiscretionaryCredit' ? 'AdditionalSupportCredit' : event.cause
                }`}
              </Label>
            )}
            {event.msn && formatMsn(event)}
            {event.utrn && (
              <Label title="UTRN" className="description-label">
                <Icon name="credit card" />
                {(event.utrn.match(/.{1,4}/g) || []).join('-')}
              </Label>
            )}
            {event.state &&
              formatState(event.state, convertEventTypeToAccountEventType(event.type))}
          </>
        ),
      };

    case 'MeterTopUpEvent':
      return {
        timestamp: format(event.dateTime, dateFormat),
        eventType: formatEventTypeAmount(event),
        amount: formatMoney(event.amount),
        balance: '-',
        reading: '-',
        consumption: '-',
        description: (
          <>
            {event.cause && (
              <Label title="Cause" className="description-label">
                {`Cause: ${
                  event.cause === 'DiscretionaryCredit' ? 'AdditionalSupportCredit' : event.cause
                }`}
              </Label>
            )}
            {event.msn && formatMsn(event)}
            {event.utrn && (
              <Label title="UTRN" className="description-label">
                <Icon name="credit card" />
                {(event.utrn.match(/.{1,4}/g) || []).join('-')}
              </Label>
            )}
            {event.state &&
              formatState(event.state, convertEventTypeToAccountEventType(event.type))}
            {event.errors && formatEventErrors(event.errors)}
          </>
        ),
      };
    case 'BalanceAdjustmentEvent':
    case 'DebtAdjustmentEvent':
    case 'DebtRepaymentEvent':
    case 'DebtReadEvent':
      // eslint-disable-next-line no-case-declarations
      const balanceEvent = event as BalanceEvent;
      // eslint-disable-next-line no-case-declarations
      const balanceAdjustmentEvent = event as accountEventsQuery_accountEvents_events_BalanceAdjustmentEvent;
      return {
        timestamp: format(event.dateTime, dateFormat),
        eventType: formatEventTypeAmount(event),
        amount: formatMoney(event.amount) || '-',
        balance: formatMoney(balanceEvent.balance) || '-',
        reading: '-',
        consumption: '-',
        description: (
          <>
            {event.msn && formatMsn(event)}
            {balanceEvent.cause && (
              <Label title="CAUSE" className="description-label">
                <Icon name="info circle" className="info_icon_color" />
                {toBalanceAdjustmentCauseView(balanceEvent.cause, event.amount).toUpperCase()}
              </Label>
            )}
            {event.state &&
              formatState(
                event.state,
                convertEventTypeToAccountEventType(event.type),
                balanceAdjustmentEvent.errorMessage,
              )}
          </>
        ),
      };
    case 'UsageChargeEvent':
      return {
        timestamp: format(event.dateTime, dateFormat),
        eventType: formatEventTypeAmount(event),
        amount: formatMoney(event.amount),
        balance: formatMoney(event.balance) || '-',
        reading: formatReadingUnits(event.read, event.fuelType),
        consumption: formatKwh(event.consumption),
        description: (
          <>
            {formatFuelType(event.fuelType)}
            {formatMsn(event)}
          </>
        ),
      };
    case 'StandingChargeEvent':
      return {
        timestamp: format(event.dateTime, dateFormat),
        eventType: formatEventTypeAmount(event),
        amount: formatMoney(event.amount),
        balance: formatMoney(event.balance) || '-',
        reading: '-',
        consumption: '-',
        description: formatFuelType(event.fuelType),
      };
    case 'ChangeModeEvent':
      return {
        timestamp: format(event.dateTime, dateFormat),
        eventType: formatEventTypeAmount(event),
        amount: '-',
        balance: '-',
        reading: '-',
        consumption: '-',
        description: (
          <>
            {event.cause && (
              <Label title="Cause" className="description-label">
                {`Cause: ${
                  event.cause === 'DiscretionaryCredit' ? 'AdditionalSupportCredit' : event.cause
                }`}
              </Label>
            )}
            {formatMsn(event)}
            <Label className="description-label">{event.mode}</Label>
            {event.state &&
              formatState(event.state, convertEventTypeToAccountEventType(event.type))}
            {event.errors && formatEventErrors(event.errors)}
          </>
        ),
      };
    case 'MeterReadingEvent':
      return {
        timestamp: format(event.dateTime, dateFormat),
        eventType: formatEventTypeAmount(event),
        amount: '-',
        balance: '-',
        reading: formatReadingUnits(event.read, event.fuelType, removeDecimalDigitsWithoutRounding),
        consumption: '-',
        description: (
          <>
            {formatFuelType(event.fuelType)}
            {formatMsn(event)}
            {formatReadingTariffType(event)}
          </>
        ),
      };
    case 'ReadFilteredBalancesEvent':
      return {
        timestamp: format(event.dateTime, dateFormat),
        eventType: formatEventTypeAmount(event),
        balance: formatMoney(event.balance) || '-',
        amount: '-',
        reading: '-',
        consumption: '-',
        description: <>{formatMsn(event)}</>,
      };
    default:
      return {
        timestamp: format(event.dateTime, dateFormat),
        eventType: formatEventTypeAmount(event),
        amount: '-',
        balance: '-',
        reading: '-',
        consumption: '-',
        description: <div>-</div>,
      };
  }
};
