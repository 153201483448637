import { SecondaryCTAButton } from '@ovotech/nebula';
import * as React from 'react';
import { Modal } from 'semantic-ui-react';
import { PrimaryDisableButton } from '../../../../../components';
interface Props {
  headerTitle: string;
  submitButtonTitle: string;
  submitDisabled: boolean;
  resetDisabled: boolean;
  loadingDC: boolean;
  onClose: () => void;
  onSubmit: () => void;
  reset: () => void;
  hideDisabledAction: boolean;
}

export const PortalModal: React.FunctionComponent<Props> = ({
  submitDisabled,
  headerTitle,
  submitButtonTitle,
  onClose,
  onSubmit,
  children,
  reset,
  resetDisabled,
  loadingDC,
  hideDisabledAction,
}) => (
  <>
    <Modal.Header>{headerTitle}</Modal.Header>
    <Modal.Content>{children}</Modal.Content>
    <Modal.Actions>
      <SecondaryCTAButton className="button" data-test="dc-wizard-close" onClick={() => onClose()}>
        Close
      </SecondaryCTAButton>
      {!hideDisabledAction && (
        <>
          <SecondaryCTAButton
            className="button"
            data-test="dc-wizard-reset"
            onClick={reset}
            disabled={resetDisabled}
          >
            Reset
          </SecondaryCTAButton>
          <PrimaryDisableButton
            buttonText={submitButtonTitle}
            dataTestId="dc-wizard-submit"
            onClick={() => onSubmit()}
            disabled={submitDisabled}
            loading={loadingDC}
          ></PrimaryDisableButton>
        </>
      )}
    </Modal.Actions>
  </>
);
