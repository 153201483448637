import * as React from 'react';
import { useMutation } from 'react-apollo';
import Helmet from 'react-helmet';
import { Container, Menu, Message, Image } from 'semantic-ui-react';
import { BoostPageHeader } from '../page-header/page-header';
import {
  loginMutation as Results,
  loginMutationVariables as Variables,
} from './__generated__/loginMutation';
import { loginMutation } from './login.view.gql';
import GoogleLoginButton from '../../components/google-login';
import { Link } from 'react-router-dom';
import { getURI, home } from '../../routes';
import OVO_LOGO from '../../assets/OVO-logo.svg';
interface Props {
  title: string;
}
const styles = {
  navContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    zIndex: '1',
  },

  nav: {
    backgroundColor: '#158426',
    width: '100%',
    marginTop: '0px',
  },
  logo: {
    width: '95px',
    height: '40px',
  },
};
export const LoginView: React.SFC<Props> = ({ title }) => {
  const [login, { loading, error }] = useMutation<Results, Variables>(loginMutation);
  return (
    <>
      <Helmet titleTemplate="PAYG Portal">
        <title>{title}</title>
      </Helmet>
      <Container fluid style={styles.navContainer}>
        <Link to={getURI(home)}>
          <Image src={OVO_LOGO} alt="OVO Logo" style={styles.logo} />
        </Link>
        <Menu style={styles.nav}></Menu>
      </Container>
      <Container textAlign="center" text>
        <BoostPageHeader>
          Login with your Google account
          <br /> to get started.
        </BoostPageHeader>
        <>
          {error && (
            <Message
              error
              header={error.networkError ? 'Unable to contact the portal' : 'Authentication Error'}
              content={
                error.networkError
                  ? 'Check that you are connected to the OVO VPN.'
                  : 'Selected Google account does not have the correct permissions.'
              }
              data-test="authentication-error-message"
            />
          )}
          <GoogleLoginButton
            text={loading ? 'Logging in ...' : 'Login'}
            disabled={loading}
            onSuccess={(user: any) => {
              return login({ variables: { idToken: user.code } });
            }}
          />
        </>
      </Container>
    </>
  );
};
