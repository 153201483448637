import * as React from 'react';
import { useState } from 'react';
import { Modal, Segment } from 'semantic-ui-react';
import { ReadFuelType } from '../../__generated__/globalTypes';
import {
  accountBalanceQuery_account_balances_MeterBalance,
  accountBalanceQuery_account_debts,
  accountBalanceQuery_account_emergencyCreditBalances,
  accountBalanceQuery_account_balances_MeterBalance_adjustments,
  accountBalanceQuery_account_customerDetails,
} from '../../screens/account/account-balance/__generated__/accountBalanceQuery';
import { AddDiscretionaryCreditForm } from '../../screens/account/forms/add-discretionary-credit';
import { ReadBalanceButton } from '../../screens/account/forms/read-balance';
import { AdjustableAmount } from '../adjustable-amount';
import { ElectricityIcon } from '../electricity-icon';
import { GasIcon } from '../gas-icon';
import { Transactions } from '../transactions';

import './meter-balance.less';

interface Props {
  balance: accountBalanceQuery_account_balances_MeterBalance;
  debt?: accountBalanceQuery_account_debts;
  emergencyCredit: false | accountBalanceQuery_account_emergencyCreditBalances | undefined;
  accountId: string;
  transactionId?: string;
  customerDetails: accountBalanceQuery_account_customerDetails;
}

type BalanceAdjustments = accountBalanceQuery_account_balances_MeterBalance_adjustments[];
export const MeterBalance: React.FunctionComponent<Props> = (props) => {
  const [addingDiscretionaryCredit, setAddingDiscretionaryCredit] = useState<boolean>(false);

  return (
    <Segment className="MeterBalance">
      <div className="MeterBalanceIcon">
        {props.balance.type === 'GAS' ? <GasIcon /> : <ElectricityIcon />}
        <div className="MeterBalanceIconMSN">{props.balance.msn}</div>
      </div>
      <div className="MeterBalanceAmountContainer">
        <AdjustableAmount
          value={props.balance.balance.value}
          currency={props.balance.balance.currency}
          effectiveAt={props.balance.effectiveAt as Date}
          adjustable={false}
          balanceLabel={'Energy Balance'}
          testLabel={'balance'}
          data-test="adjust-balance-amount"
          onAdjust={() => {
            console.log('balance adjust not available');
          }}
        />

        {props.balance.adjustable && (
          <div className="ReadOperationButton">
            <ReadBalanceButton
              accountId={props.accountId}
              msn={props.balance.msn}
              mpxn={props.balance.mpxn || ''}
              type={(props.balance.type as unknown) as ReadFuelType} // FIXME
              pendingRead={props.balance.performingPrepaymentDataRead}
            />
          </div>
        )}
      </div>
      <div className="DebtAmount">
        {props.debt && (
          <div className="DebtValue">
            <AdjustableAmount
              data-test="adjust-debt-amount"
              value={props.debt.debt.value}
              currency={props.debt.debt.currency}
              effectiveAt={props.debt.effectiveAt as Date}
              adjustable={false}
              balanceLabel={'Debt Balance'}
              testLabel={'debt'}
              onAdjust={() => {
                console.log('debt adjust not available');
              }}
            />
          </div>
        )}
      </div>
      <div className="EmergencyCreditAmount">
        {props.emergencyCredit && (
          <AdjustableAmount
            value={props.emergencyCredit.emergencyCreditBalance.value}
            currency={props.emergencyCredit.emergencyCreditBalance.currency}
            effectiveAt={props.emergencyCredit.effectiveAt as Date}
            adjustable={false}
            balanceLabel={'Emergency Credit Balance'}
            testLabel={'emergencyCreditAmount'}
          />
        )}
      </div>
      {addingDiscretionaryCredit ? (
        <Modal
          open={true}
          onClose={() => setAddingDiscretionaryCredit(false)}
          size="small"
          data-test="add-discretionary-credit-modal"
        >
          <AddDiscretionaryCreditForm
            accountId={props.accountId}
            type={props.balance.type}
            onClose={() => setAddingDiscretionaryCredit(false)}
            msn={props.balance.msn}
            mpxn={props.balance.mpxn || ''}
            usualRepaymentRate={props.debt?.usualRepaymentRate}
            customerVulnerabilities={props.customerDetails.vulnerabilities}
            repaymentRate={props.debt?.repaymentRate}
          />
        </Modal>
      ) : null}
      <div className="MeterAdjustments">
        <Transactions
          adjustments={props.balance.adjustments as BalanceAdjustments}
          heading="Balance transactions"
        />
      </div>
    </Segment>
  );
};
