import * as React from 'react';
import { Form, Input } from 'semantic-ui-react';
import { Currency } from '../../__generated__/globalTypes';
import { formatMoney } from '../../helpers';
import { LastReadMessage } from '../last-read-message';
import './adjustable-amount.less';

interface Props {
  value: number;
  currency: Currency;
  balanceLabel: string;
  testLabel: string;
  effectiveAt: Date | null;
  adjustable: boolean;
  onAdjust?: () => void;
  now?: Date;
}

export const AdjustableAmount: React.FunctionComponent<Props> = (props) => (
  <Form.Field className="AdjustableAmount" readOnly data-test={`account-${props.testLabel}-amount`}>
    <label>
      {props.balanceLabel + ' '}
      <LastReadMessage
        effectiveAt={props.effectiveAt?.toString() as string}
        label={`account-${props.testLabel}-amount-date`}
        now={props.now}
      />
    </label>
    <Input
      value={formatMoney({
        value: props.value,
        currency: props.currency,
      })}
      action={{
        content: 'Adjust',
        primary: true,
        'data-test': `account-${props.testLabel}-adjust`,
        onClick: props.onAdjust,
        disabled: !props.adjustable,
      }}
    />
  </Form.Field>
);
