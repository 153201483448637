import { get } from '@ovotech/typesafe-get';
import { addMonths, format } from 'date-fns';
import * as queryString from 'query-string';
import * as React from 'react';
import { useQuery } from 'react-apollo';
import { RouteComponentProps } from 'react-router';
import { Dimmer, Loader, Table } from 'semantic-ui-react';
import { dateFormatIso } from '../../../helpers';
import { EventFilterForm } from '../event-history-filter';
import { QueryStringFilters } from '../types';
import {
  accountEventsQuery as Results,
  accountEventsQuery_accountEvents_events,
  accountEventsQueryVariables as Variables,
} from './__generated__/accountEventsQuery';
import { eventToAccountRow } from './account-events-helpers';
import { accountEventsQuery } from './account-events.view.gql';
import './account-events.view.less';

type Props = RouteComponentProps<{ accountNumber: string }>;

const defaultFilters: QueryStringFilters = {
  date: [format(addMonths(new Date(), -1), dateFormatIso), format(new Date(), dateFormatIso)],
  type: [],
};

export const filterEvents = (
  events: accountEventsQuery_accountEvents_events[],
  filters: QueryStringFilters,
) => events.filter((event) => (filters.type.length ? filters.type.includes(event.type) : true));

export const getCountMessage = (count: number) => `${count} result${count !== 1 ? 's' : ''} found`;

export const AccountEventsView: React.SFC<Props> = ({ match, location, history }) => {
  const filters: QueryStringFilters = {
    ...defaultFilters,
    ...queryString.parse(location.search, { arrayFormat: 'bracket' }),
  };

  const { data, loading, refetch } = useQuery<Results, Variables>(accountEventsQuery, {
    variables: {
      accountEventsInput: {
        accountId: match.params.accountNumber,
        fromDate: filters.date[0],
        toDate: filters.date[1],
      },
    },
  });

  const events = get(data, 'accountEvents', 'events') || [];
  const filteredEvents = filterEvents(events, filters);

  return (
    <Dimmer.Dimmable data-test="account-events-view">
      <EventFilterForm
        initialValues={filters}
        loading={loading}
        onSubmit={(values) => {
          history.push('?' + queryString.stringify(values, { arrayFormat: 'bracket' }));
          refetch({
            accountEventsInput: {
              accountId: match.params.accountNumber,
              fromDate: values.date[0],
              toDate: values.date[1],
            },
          });
        }}
      />
      <Dimmer inverted active={loading}>
        <Loader />
      </Dimmer>
      <Table data-test="results-table">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell content="Date and time" />
            <Table.HeaderCell content="Type" />
            <Table.HeaderCell content="Amount" />
            <Table.HeaderCell content="Balance" />
            <Table.HeaderCell content="Reading" />
            <Table.HeaderCell content="Consumption" />
            <Table.HeaderCell content="Description" />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {filteredEvents.map(eventToAccountRow).map((accountRow, index) => (
            <Table.Row key={index}>
              <Table.Cell content={accountRow.timestamp} />
              <Table.Cell content={accountRow.eventType} />
              <Table.Cell content={accountRow.amount} />
              <Table.Cell content={accountRow.balance} />
              <Table.Cell content={accountRow.reading} />
              <Table.Cell content={accountRow.consumption} />
              <Table.Cell content={accountRow.description} />
            </Table.Row>
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="7" textAlign="left">
              {getCountMessage(filteredEvents.length)}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Dimmer.Dimmable>
  );
};
