import * as React from 'react';

type ElementProps = React.InputHTMLAttributes<HTMLElement>;
type StrippedProps = Omit<ElementProps, 'inlist'>; // remove props with any
type ValueOf<T> = T[keyof T];
type ElementPropValue = ValueOf<StrippedProps>;
type Props = ElementProps & { [key: string]: ElementPropValue };

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const FileInput: React.FC<Props> = ({ value, onChange, inputRef, ...props }) => (
  <input
    onChange={
      onChange
        ? (event) => {
            if (event.target.files && event.target.files.length) {
              event.persist();
              const fileName = event.target?.files[0].name || undefined;

              const reader = new FileReader();
              reader.onload = () => {
                const target = {
                  ...event.target,
                  value: { name: fileName, result: reader.result as string },
                };
                onChange({ ...event, target });
              };
              reader.readAsText(event.target.files[0]);
            } else {
              onChange(event);
            }
          }
        : undefined
    }
    {...props}
    type="file"
    ref={inputRef as React.LegacyRef<HTMLInputElement>}
  />
);
