import { datadogRum } from '@datadog/browser-rum';
import { BalanceType, CommChannel } from '../__generated__/globalTypes';
import { recommendedDiscretionaryCreditQuery_recommendedDiscretionaryCredit } from '../screens/account/forms/add-discretionary-credit/__generated__/recommendedDiscretionaryCreditQuery';
import { penceToPound } from '../helpers';

export const trackAddDiscretionaryCredit = (input: {
  amount: number;
  type: BalanceType;
  repaymentRate: number;
  recommendationAmount: number;
  recommendationTotalDaysCovered: number;
  hasUsedRecommendationCalculator: boolean;
  hasUsedRecommendedAmount: boolean;
  agentName: string;
  usualRepaymentRate: number;
}) =>
  datadogRum.addUserAction('addDiscretionaryCredit', {
    input,
  });
export const trackDcWizardV2 = (input: {
  amount: number;
  type: BalanceType;
  repaymentRate: number;
  recommendationAmount: number;
  recommendationTotalDaysCovered: number;
  hasUsedRecommendationCalculator: boolean;
  hasUsedRecommendedAmount: boolean;
  agentName: string;
  usualRepaymentRate: number;
}) =>
  datadogRum.addUserAction('addDiscretionaryCredit', {
    input,
  });
export const trackRecommendedDiscretionaryCredit = (
  recommendation: recommendedDiscretionaryCreditQuery_recommendedDiscretionaryCredit,
) =>
  datadogRum.addUserAction('recommendedDiscretionaryCredit', {
    result: {
      total: penceToPound(recommendation.total.value),
      repaymentRate: penceToPound(recommendation.repaymentRateTotal.value),
      dailyChargeCover: penceToPound(recommendation.dailyChargeCover.value),
      repaymentRateTotal: penceToPound(recommendation.repaymentRateTotal.value),
      bringBalanceAboveZero: penceToPound(recommendation.bringBalanceAboveZero.value),
      totalDaysCovered: recommendation.totalDaysCovered,
    },
  });

export const trackFinancialSupport = (input: {
  agentName: string;
  discretionaryCreditInfo: {
    amount: string;
    accountNo: string;
    repaymentRate: string;
    clientId: string;
    supplyType: string;
    channel: CommChannel;
  };
}) => {
  return datadogRum.addUserAction('financialSupportWarning', {
    input,
  });
};
