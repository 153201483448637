import { get } from '@ovotech/typesafe-get';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link, NavLink } from 'react-router-dom';
import { Container, Dropdown, Image, Menu } from 'semantic-ui-react';
import { ClientErrorComponent } from '..';
import { Scope } from '../../__generated__/globalTypes';
import { ClientStateContext } from '../../client/client-state-context';
import { accountSearch, getURI, home } from '../../routes';
import { loginMutation_createSession as Session } from './__generated__/loginMutation';
import * as BatchAdjustmentScreen from '../../screens/batch-adjustment/batch-adjustment-form.view';
import { Route } from '../../types';
import { useQuery } from 'react-apollo';
import { isServiceAvailableQuery as isServiceAvailableQueryType } from './__generated__/isServiceAvailableQuery';
import { isServiceAvailableQuery } from './screen.view.gql';
import { PortalUnavailableBanner } from '../portal-unavailable-banner/portal-unavailable-banner';
import { SHOW_BATCH_ADJUSTMENT } from '../../feature-flags';
import OVO_LOGO from '../../assets/OVO-logo.svg';

/**
 * Context used to store a users scopes
 * when they have authenticated
 */
export const UserScopesContext = React.createContext<Scope[]>([]);

const styles = {
  navContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    zIndex: '1',
  },

  nav: {
    backgroundColor: '#158426',
    width: '100%',
    marginTop: '0px',
  },

  navText: {
    color: 'white',
  },

  menu: {
    position: 'absolute',
    top: 0,
    left: 0,
    border: 'none',
    paddingTop: '80px',
    zIndex: '0',
    height: '100vh',
    maxWidth: '150px',
  },

  container: {
    paddingLeft: '200px',
    paddingRight: '50px',
  },

  logo: {
    width: '95px',
    height: '40px',
  },
};

interface Props {
  title: string;
  session: Session;
  showGlobalError?: boolean;
}

interface MenuLink {
  intendedScope: Scope;
  route: Route;
  testId: string;
  pageTitle: string;
}

const MenuLinks = ({ items, scopes }: { scopes: Scope[]; items: MenuLink[] }) => {
  return (
    <>
      {items.map((item) =>
        scopes.includes(item.intendedScope) ? (
          <NavLink
            key={item.pageTitle}
            className="item"
            to={getURI(item.route)}
            data-test={item.testId}
          >
            {item.pageTitle}
          </NavLink>
        ) : (
          <></>
        ),
      )}
    </>
  );
};

export const ScreenView: React.SFC<Props> = ({
  title,
  session,
  children,
  showGlobalError = true,
}) => {
  const scopes = get(session, 'user', 'scopes') || [];

  const { data } = useQuery<isServiceAvailableQueryType>(isServiceAvailableQuery);
  const serviceAvailability = data?.isServiceAvailable;

  const menuLinks = [
    {
      intendedScope: Scope.ACCOUNT,
      route: accountSearch,
      testId: 'account-search',
      pageTitle: 'Account Search',
    },
  ];

  return (
    <>
      <Helmet titleTemplate="PAYG Portal">
        <title>{title}</title>
      </Helmet>
      <Container fluid style={styles.navContainer}>
        <Link to={getURI(home)}>
          <Image src={OVO_LOGO} alt="OVO Logo" style={styles.logo} />
        </Link>
        <Menu style={styles.nav}>
          <Menu.Menu position="right" data-test="session-user">
            <Dropdown item text={String(get(session, 'user', 'name'))} style={styles.navText}>
              <Dropdown.Menu>
                <Dropdown.Item>
                  {get(session, 'user', 'name')} <Image src={get(session, 'user', 'picture')} />
                </Dropdown.Item>
                <ClientStateContext.Consumer>
                  {({ clear }) => (
                    <Dropdown.Item data-test="session-logout" onClick={() => clear()}>
                      Logout
                    </Dropdown.Item>
                  )}
                </ClientStateContext.Consumer>
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu>
        </Menu>
      </Container>

      <Menu vertical borderless compact width="thin" style={styles.menu}>
        <MenuLinks scopes={scopes} items={menuLinks} />
        {scopes.includes(BatchAdjustmentScreen.intendedScope) && SHOW_BATCH_ADJUSTMENT ? (
          <>
            <NavLink
              to={getURI(BatchAdjustmentScreen.route)}
              className="item"
              data-test={BatchAdjustmentScreen.testId}
            >
              {BatchAdjustmentScreen.pageTitle}
            </NavLink>
          </>
        ) : (
          <></>
        )}
      </Menu>
      <Container fluid={true} style={styles.container}>
        <PortalUnavailableBanner
          isServiceAvailable={!!serviceAvailability?.isServiceAvailable}
          isBatchServiceAvailable={!!serviceAvailability?.isBatchServiceAvailable}
        />
        {showGlobalError && <ClientErrorComponent />}
        <UserScopesContext.Provider value={scopes}>{children}</UserScopesContext.Provider>
      </Container>
    </>
  );
};

ScreenView.displayName = 'ScreenView';
