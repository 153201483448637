import * as React from 'react';
import * as Final from 'react-final-form';
import { Form, Input } from 'semantic-ui-react';
import * as validate from 'validate.js';
import { FormFieldMessage } from '../../components/form-field-message';
import { SearchQuery } from './types';
import { FormApi } from 'final-form';
import { preventNonNumericalKeys } from '../new-pan-request/helpers';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';

interface Props extends Pick<Final.FormProps, 'onSubmit'> {
  initialValues: SearchQuery;
  loading: boolean;
  required?: boolean;
  autoFocus?: boolean;
}

type Constraints = { [index in keyof SearchQuery]: unknown };

const constraints: Constraints = {
  accountId: {
    format: /(\d{3,})|(DEMO_[^\s]*)/,
  },
};

export const sanitizeSearchQuery = (values: SearchQuery): SearchQuery => ({
  accountId: (values.accountId || '').trim(),
});

// eslint-disable-next-line react/display-name
export const SearchForm = forwardRef(
  ({ initialValues, onSubmit, loading, required = true, autoFocus = true }: Props, ref) => (
    <Final.Form
      initialValues={initialValues}
      onSubmit={(values: SearchQuery) => onSubmit(sanitizeSearchQuery(values), {} as FormApi)}
      validate={(values: SearchQuery) => validate(sanitizeSearchQuery(values), constraints)}
    >
      {({ handleSubmit, hasValidationErrors, form }) => {
        useImperativeHandle(ref, () => ({
          resetForm() {
            setTimeout(form.reset);
          },
        }));

        return (
          <Form onSubmit={handleSubmit}>
            <Final.Field name="accountId">
              {({ input, meta }) => (
                <Form.Field error={meta.touched && !!meta.error} required={required}>
                  <label>Account Number</label>
                  <Input
                    action={{
                      icon: 'search',
                      content: 'Search',
                      disabled: loading || hasValidationErrors,
                      'data-test': 'account-search-submit',
                    }}
                    {...input}
                    data-test="account-search-input"
                    autoFocus={autoFocus}
                    autoComplete="off"
                    required={required}
                    onKeyPress={preventNonNumericalKeys}
                  />
                  <FormFieldMessage meta={meta} />
                </Form.Field>
              )}
            </Final.Field>
          </Form>
        );
      }}
    </Final.Form>
  ),
);
