import { PANType } from '@ovotech/pan-utils';
import * as React from 'react';
import { Modal, Segment, Table } from 'semantic-ui-react';
import {
  CurrentMeterMode,
  CurrentMeterSupplyState,
  MeterAdjustmentType,
  MeterFuelType,
  MeterType,
  ReadFuelType,
} from '../../../__generated__/globalTypes';
import { PendingSpinner } from '../../pending-spinner';
import { SHOW_DISABLE_PIN_BUTTON } from '../../../feature-flags';
import { accountMetersQuery_account_meters as Meter } from '../../../screens/account/account-meters/__generated__/accountMetersQuery';
import { accountScreenQuery_panReconciliationStatus_reconciliations_panDetails as PanDetails } from '../../../screens/account/__generated__/accountScreenQuery';
import { AdjustModeForm } from '../../../screens/account/forms/adjust-mode';
import { DisablePinButton } from '../../../screens/account/forms/disable-pin';
import { ReadMeterModeButton } from '../../../screens/account/forms/read-mode';
import { ReadSupplyStateButton } from '../../../screens/account/forms/read-supply-state';
import { UpdateMeterPANForm } from '../../../screens/account/forms/update-meter-pan';
import { ElectricityIcon } from '../../electricity-icon';
import { GasIcon } from '../../gas-icon';
import { LastReadMessage } from '../../last-read-message';
import { Transactions } from '../../transactions';
import './non-trad-meter.less';
import { LGMeterWarning } from '../../LGMeterWarning';
import { PrimaryCTAButton } from '@ovotech/nebula';
interface ModeMap {
  title: string;
  subtitle: string;
}
interface SupplyStateMap {
  title: string;
  subtitle: string;
}

const mapCurrentSupplyState = (supplyState: CurrentMeterSupplyState): SupplyStateMap => {
  switch (supplyState) {
    case CurrentMeterSupplyState.ENABLED: {
      return { title: 'Enabled', subtitle: '(Supply ON)' };
    }
    case CurrentMeterSupplyState.DISABLED: {
      return { title: 'Disabled', subtitle: '(Supply OFF)' };
    }
    case CurrentMeterSupplyState.ARMED: {
      return { title: 'Armed', subtitle: '(Pending Re-connection)' };
    }
    default: {
      return { title: 'Unknown', subtitle: '' };
    }
  }
};

const mapCurrentMode = (mode: CurrentMeterMode): ModeMap => {
  switch (mode) {
    case CurrentMeterMode.CREDIT: {
      return { title: 'PAYM', subtitle: '(Credit)' };
    }
    case CurrentMeterMode.PREPAYMENT: {
      return { title: 'PAYG Standard', subtitle: '(Prepayment)' };
    }
    default: {
      return { title: 'Unknown', subtitle: '' };
    }
  }
};

const validPrepaymentModePanTypes = [
  PANType.PAYG_STANDARD_S1_GAS,
  PANType.PAYG_STANDARD_S2_GAS,
  PANType.PAYG_STANDARD_S1_ELEC,
  PANType.PAYG_STANDARD_S2_ELEC,
  PANType.PAYG_STANDARD_S1_VIRTUAL_ELEC,
  PANType.PAYG_STANDARD_S2_VIRTUAL_ELEC,
  PANType.PAYG_STANDARD_S1_VIRTUAL_GAS,
  PANType.PAYG_STANDARD_S2_VIRTUAL_GAS,
  PANType.PAYGPLUS_REUSED_FOR_PAYG_STANDARD_S1_ELEC,
];

export const checkIsValidPanForMeterMode = (
  meterMode: CurrentMeterMode,
  panType: PANType,
): boolean => {
  if (panType === PANType.UNKNOWN) {
    return true;
  }

  if (meterMode === CurrentMeterMode.CREDIT) {
    return false;
  }

  if (meterMode === CurrentMeterMode.PREPAYMENT) {
    return validPrepaymentModePanTypes.includes(panType);
  }

  return false;
};

interface Props {
  meter: Meter;
  accountId: string;
  now?: Date;
  onSuccessfulOperation: () => Promise<Record<string, unknown>>;
  panReconciliations: (PanDetails | null)[] | undefined;
}

export const NonTradMeter: React.FunctionComponent<Props> = (props) => {
  const [switchMode, setSwitchMode] = React.useState<boolean>(false);
  const [updatePan, setUpdatePan] = React.useState<boolean>(false);

  const hideSwitchModeModal = () => {
    setSwitchMode(false);
  };
  const showSwitchModeModal = () => {
    setSwitchMode(true);
  };

  const hideUpdatePanModal = () => {
    setUpdatePan(false);
  };

  const { panReconciliations } = props;

  const filterPANS = (type: string) => {
    if (!panReconciliations) return [];
    return panReconciliations.filter((item) => {
      if (type === 'paypoint' && item?.paypoint) return item;
      if (type === 'meter' && item?.meter) return item;
      return false;
    });
  };

  return (
    <Segment key={props.meter.msn} className="NonTradMeter">
      <div className="NonTradMeterTypeIcon">
        {props.meter.fuelType === 'GAS' ? <GasIcon /> : <ElectricityIcon />}
      </div>
      <div>
        <Table className="NonTradMeterDetailsTable" size="small" definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell content="Type" />
              <Table.Cell
                content={props.meter.meterType === MeterType.S2 ? 'SMETS2' : 'SMETS1 Enrolled'}
                colSpan={2}
              />
            </Table.Row>
            <Table.Row>
              <Table.Cell content="MSN" />
              <Table.Cell content={props.meter.msn} colSpan={2} />
            </Table.Row>
            <Table.Row>
              <Table.Cell content={props.meter.fuelType === 'GAS' ? 'MPRN' : 'MPAN'} />
              <Table.Cell content={props.meter.mpxn} colSpan={2} />
            </Table.Row>
            <Table.Row>
              <Table.Cell content="PAN Meter" />
              <Table.Cell
                content={
                  <div>
                    {filterPANS('meter').map((item) => {
                      return <div key={item?.pan}>{item?.pan}</div>;
                    })}
                  </div>
                }
                colSpan={2}
              />
            </Table.Row>
            <Table.Row>
              <Table.Cell content="PAN Paypoint" />
              <Table.Cell
                content={
                  <div>
                    {filterPANS('paypoint').map((item) => {
                      return <div key={item?.pan}>{item?.pan}</div>;
                    })}
                  </div>
                }
                colSpan={2}
              />
            </Table.Row>
            <Table.Row className="PaymentModeRow">
              <Table.Cell content="Payment Mode" />
              <Table.Cell
                content={
                  <div className="NonTradButtonCell">
                    {props.meter.currentMode.value !== CurrentMeterMode.UNKNOWN && (
                      <LastReadMessage
                        effectiveAt={props.meter.currentMode.effectiveAt}
                        label=""
                        now={props.now}
                      />
                    )}
                    <div>
                      <b>{mapCurrentMode(props.meter.currentMode.value).title}</b>
                      <span className="muted Subtitle">
                        {mapCurrentMode(props.meter.currentMode.value).subtitle}
                      </span>
                    </div>
                  </div>
                }
              />
              <Table.Cell
                content={
                  <div>
                    <ReadMeterModeButton
                      accountId={props.accountId || 'none'}
                      msn={props.meter.msn}
                      mpxn={props.meter.mpxn}
                      type={
                        props.meter.fuelType === MeterFuelType.GAS
                          ? ReadFuelType.GAS
                          : ReadFuelType.ELECTRICITY
                      }
                      pendingRead={props.meter.hasPendingReadModeRequest}
                    />

                    <div className="NonTradChangeMode">
                      <PrimaryCTAButton
                        className="button"
                        onClick={showSwitchModeModal}
                        data-test="S2-update-mode-button"
                        disabled={true}
                      >
                        {props.meter.hasPendingChangeModeRequest && (
                          <PendingSpinner contentText="Pending Request" disablePadding={true} />
                        )}
                        Change Meter Mode
                      </PrimaryCTAButton>
                    </div>
                  </div>
                }
              />
            </Table.Row>
            <Table.Row>
              <Table.Cell content="PIN" />
              <Table.Cell />
              <Table.Cell>
                {SHOW_DISABLE_PIN_BUTTON && (
                  <DisablePinButton
                    msn={props.meter.msn}
                    mpxn={props.meter.mpxn}
                    accountId={props.meter.accountId}
                    fuelType={
                      props.meter.fuelType === MeterFuelType.GAS
                        ? ReadFuelType.GAS
                        : ReadFuelType.ELECTRICITY
                    }
                    pendingDisable={props.meter.hasPendingDisablePinRequest}
                    disabled={true}
                  />
                )}
              </Table.Cell>
            </Table.Row>
            <Table.Row className="NonTradFixedSizeRow">
              <Table.Cell content="Supply State" />
              <Table.Cell
                content={
                  <div className="NonTradButtonCell">
                    {props.meter.currentSupplyState.value !== CurrentMeterSupplyState.UNKNOWN && (
                      <LastReadMessage
                        effectiveAt={props.meter.currentSupplyState.effectiveAt}
                        label=""
                        now={props.now}
                      />
                    )}
                    <div>
                      <b>{mapCurrentSupplyState(props.meter.currentSupplyState.value).title}</b>
                      <span className="muted Subtitle">
                        {mapCurrentSupplyState(props.meter.currentSupplyState.value).subtitle}
                      </span>
                    </div>
                  </div>
                }
              />
              <Table.Cell>
                <ReadSupplyStateButton
                  accountId={props.accountId || ''}
                  msn={props.meter.msn}
                  mpxn={props.meter.mpxn}
                  type={
                    props.meter.fuelType === MeterFuelType.GAS
                      ? ReadFuelType.GAS
                      : ReadFuelType.ELECTRICITY
                  }
                  pendingRead={props.meter.hasPendingReadSupplyStateRequest}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
      <div className="NonTradAdjustments">
        <Transactions
          adjustments={props.meter.adjustments.filter(
            (adjustment) => adjustment.type !== MeterAdjustmentType.UNKNOWN,
          )}
          heading="Meter transactions"
        />
      </div>
      {switchMode && (
        <Modal
          open={switchMode}
          onClose={() => hideSwitchModeModal()}
          size="small"
          data-test="change-meter-mode-modal"
        >
          <AdjustModeForm
            accountId={props.accountId}
            currentMeterMode={props.meter.currentMode.value}
            msn={props.meter.msn}
            mpxn={props.meter.mpxn}
            onSuccess={() => {
              hideSwitchModeModal();
              props.onSuccessfulOperation();
            }}
            onClose={hideSwitchModeModal}
          />
        </Modal>
      )}
      {updatePan && (
        <Modal
          open={updatePan}
          onClose={hideUpdatePanModal}
          size="tiny"
          data-test={`update-pan-modal`}
        >
          <UpdateMeterPANForm
            onClose={hideUpdatePanModal}
            meter={props.meter}
            onSuccess={() => {
              hideUpdatePanModal();
              props.onSuccessfulOperation();
            }}
          />
        </Modal>
      )}
      <LGMeterWarning meter={props.meter} />
    </Segment>
  );
};
