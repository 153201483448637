import { AxiosResponse } from 'axios';
import axios from 'axios';
import paypointAccountQuery from './paypoint.gql';
import { print } from 'graphql/language/printer';
import { getAuthorizationHeaders } from '../../../screens/boost-issue-pans-request/helpers';

export const fetchPaypointAccountDetails = (
  username: string,
  jwt: string,
): Promise<AxiosResponse> => {
  const authHeader = getAuthorizationHeaders(jwt);
  return axios.post(
    process.env.REACT_APP_BIT_PORTAL_API || '',
    {
      operationName: 'paypointAccountQuery',
      variables: { username },
      query: print(paypointAccountQuery),
    },
    { headers: authHeader },
  );
};
