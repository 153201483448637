import React, { useRef } from 'react';
import { get } from '@ovotech/typesafe-get';
import { Mutation } from 'react-apollo';
import * as Final from 'react-final-form';
import { Form, Input, Message, Table } from 'semantic-ui-react';
import { Scope } from '../../__generated__/globalTypes';
import { LoadingComponent } from '../../components';
import { FileInput } from '../../components/file-input/file-input.component';
import { batchAdjustmentScreenMutation } from './schema.gql';
import { batchAdjustment } from '../../routes';
import {
  batchAdjustmentScreenMutation as Results,
  batchAdjustmentScreenMutationVariables as Variables,
} from './__generated__/batchAdjustmentScreenMutation';
import { DropdownField } from '../../components/dropdown-field';
import { capitalize } from 'validate.js';
import { FailedBatchRow } from '../../types';
import { usabillaPopup } from '../../helpers';
import { PrimaryDisableButton } from '../../components';

export const intendedScope = Scope.BATCH_OPS;
export const testId = batchAdjustment.path.replace(/^\/([A-z0-9-_]*).*/, '$1');
export const route = batchAdjustment;
export const pageTitle = 'Batch Adjustment';

export interface BatchAdjustmentFormValues {
  csv: {
    name: string;
    result: string;
  };
  batchType: string;
  context: string;
}

export const shapeForSubmission = (values: BatchAdjustmentFormValues): Variables => ({
  input: {
    csvName: values.csv.name,
    csvBody: values.csv.result,
    batchType: values.batchType,
    context: values.context,
  },
});

export const BatchAdjustmentForm: React.FC = () => (
  <Mutation<Results, Variables> mutation={batchAdjustmentScreenMutation}>
    {(upload, { data, loading, error }) => {
      const results = get(data, 'uploadBatchAdjustment');
      const failedRows: FailedBatchRow[] =
        error?.graphQLErrors[0]?.extensions?.exception?.data || [];
      const fileInput = useRef() as { current: { value: string } };

      const batchTypeOptions = [
        {
          text: 'Update PAN',
          value: 'update-pan',
        },
        {
          text: 'Read balance',
          value: 'read-balance',
        },
        {
          text: 'Read meter mode',
          value: 'read-meter-mode',
        },
      ];

      return (
        <>
          <LoadingComponent loading={loading} />

          <Final.Form
            onSubmit={(values: BatchAdjustmentFormValues) =>
              upload({ variables: shapeForSubmission(values) })
            }
          >
            {({ handleSubmit, submitSucceeded, pristine, form }) => (
              <Form
                onSubmit={(event) =>
                  handleSubmit(event)?.then(() => {
                    setTimeout(form.reset);
                    fileInput.current.value = '';
                    usabillaPopup('trigger', 'manual trigger');
                  })
                }
                loading={loading}
                success={Boolean(data)}
              >
                {submitSucceeded && (
                  <Message success data-test="success-message">
                    Success
                  </Message>
                )}

                <DropdownField
                  fieldName="batchType"
                  required
                  headerText="Batch operation type"
                  options={batchTypeOptions.map(({ value, text }) => ({
                    key: value,
                    value,
                    text: capitalize(text),
                  }))}
                />
                <Final.Field name="context">
                  {({ input }) => (
                    <Form.Field required>
                      <label>What is the reason for this upload?</label>
                      <Input
                        {...input}
                        data-test="context-input-field"
                        required
                        autoComplete="off"
                      />
                    </Form.Field>
                  )}
                </Final.Field>
                <Final.Field name="csv">
                  {({ input }) => (
                    <>
                      <label>Select a CSV file to upload</label>
                      <FileInput {...input} data-test="file-input" required inputRef={fileInput} />
                    </>
                  )}
                </Final.Field>
                <div style={{ marginTop: '10px' }}>
                  <PrimaryDisableButton
                    dataTestId="submit-btn"
                    form="batch-adjustment-form"
                    onClick={() => handleSubmit()}
                    disabled={pristine}
                    buttonText="Upload"
                  />
                </div>
              </Form>
            )}
          </Final.Form>
          {error && failedRows.length ? (
            <Table data-test="results-error-table">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell content="Row number" />
                  <Table.HeaderCell content="Error" />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {failedRows.map(
                  (row: FailedBatchRow) =>
                    error && (
                      <Table.Row key={row.rowNumber} error={true}>
                        <Table.Cell content={row.rowNumber} />
                        <Table.Cell content={row.errorDescriptions.join(', ')} />
                      </Table.Row>
                    ),
                )}
              </Table.Body>
            </Table>
          ) : null}

          {results && (
            <div data-test="results" className="mt-1">
              <hr />
              <h2>Results</h2>
              <div>
                <p>Batch ID: {results.batchId}</p>
              </div>
            </div>
          )}
        </>
      );
    }}
  </Mutation>
);
